import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { connect } from "react-redux";
import CountryFlag from "react-country-flag";
import "../../config";
import axios from "axios";
const qs = require("query-string");
const confirmationLogo = "assets/layout/images/confirmation.png";
class AuditTrail extends Component {
  constructor() {
    super();
    document.title = "Audit Trail - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      searchFilter: [{ label: "User Name", value: "user_name" }],
      dialogEmail: "",
      dialoguser_name: "",
      dialogDescription: "",
      auditTrailDetailDialog: false,
      dialogPhone: "",
      dialogTransId: "",
      dateChange: "N",
      dialogAmount: "",
      dialogCountry: "",
      dialogAccountNum: "",
      dialogName: "",
      dialogStatus: "",
      dialogStatusText: "",
      dialogDestinationHouse: "",
      dialogExchangeRate: "",
      dialogReciAccountNumber: "",
      dialogReciCurrency: "",
      dialogTransDesc: "",
      dialogVaId: "",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      transactionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      reversalTransactionButton: "none",
      closeTransactionButton: "none",
      submitted: false,
      dropdownCity: "All",
      dropdownSearchFilter: "",
      filterCountry: "",
      filterDate: "",
      searchValueText: "",
      dropdowncountries: "Filter Filter",
      status: null,
      date2: null,
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      selectedFile: null,
      selectedFiles: null,
      documents: [],
      documentsSelection: null,
      fullCalendarEvents: [],
      layout: "list",
      cities: [],
      rangeValue: [],
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.hideTransactionDialog = this.hideTransactionDialog.bind(this);
    this.descriptionColumnTemplate = this.descriptionColumnTemplate.bind(this);
    this.institutionIDColumnTemplate =
      this.institutionIDColumnTemplate.bind(this);
    this.amountColumnTemplate = this.amountColumnTemplate.bind(this);
    this.phoneColumnTemplate = this.phoneColumnTemplate.bind(this);
    this.countryColumnTemplate = this.countryColumnTemplate.bind(this);
    this.userNameColumnTemplate = this.userNameColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.phoneColumnTemplate = this.phoneColumnTemplate.bind(this);
    this.toastSuccess = this.toastSuccess.bind(this);
    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  phoneColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Phone</span>
        {rowData.phone}
      </React.Fragment>
    );
  }
  descriptionColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Action Performed</span>
        <span className={pTag} style={{ width: "75%" }}>
          {rowData.short_description}
        </span>
      </React.Fragment>
    );
  }

  institutionIDColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Institution ID</span>
        {rowData.va_id}
      </React.Fragment>
    );
  }
  userNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Institution ID</span>
        {rowData.user_name}
      </React.Fragment>
    );
  }

  amountColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Amount</span>
        {rowData.amount}
      </React.Fragment>
    );
  }

  phoneColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Phone</span>
        {rowData.phone}
      </React.Fragment>
    );
  }

  countryColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Country</span>
        <span>
          <CountryFlag
            countryCode={rowData.country}
            svg
            style={{ width: "25px", height: "15px" }}
          />
          &nbsp; {rowData.country}
        </span>
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        {rowData.created_at}
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let ptag = rowData.status;
    let pTag = "";
    let status = "";
    if (ptag == "0") {
      status = "PENDING";
      pTag = "p-tag p-tag-warning";
    } else if (ptag == "1") {
      status = "SUCCESS";
      pTag = "p-tag p-tag-success";
    } else if (ptag == "2") {
      status = "FAILED";
      pTag = "p-tag p-tag-danger";
    } else {
      status = "CANCELLED";
      pTag = "p-tag";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  viewAuditTrailDetails = (rowData) => {
    this.setState({
      dialogEmail: rowData.email,
      dialoguser_name: rowData.user_name,
      dialogDescription: rowData.long_description,
      auditTrailDetailDialog: true,
    });
  };
  hideAuditTrailDetails = () => {
    this.setState({
      auditTrailDetailDialog: false,
    });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  openConfirmationDialog = () => {
    this.setState({
      confirmationDialog: true,
      transactionDialog: false,
    });
  };
  hideConfirmationDialog = () => {
    this.setState({ confirmationDialog: false });
  };

  hideTransactionDialog() {
    this.setState({ transactionDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      cities: this.props.userDetails.allCountries,
    });
    this.updateDimensions();
    this.getAuditData();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      this.filterDataByDate();
    }
  };

  filterDataByDate = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "audit-report",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          {
            /*this.createDataTable(res.data.data);*/
          }
          this.createDataTable(JSON.parse(res.data.data));
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getSearchedData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    this.state.dialogVisible = false;
    this.getAuditTrailSearchedValue();
  };

  getAuditTrailSearchedValue = async () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "audit-report",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.createDataTable(JSON.parse(res.data.data));
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getAuditData = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "audit-report",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          {
            /*let jsonData = res.data.data;
					console.log(JSON.parse((jsonData)));*/
          }
          this.createDataTable(JSON.parse(res.data.data));
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        created_at: d["created_at"],
        short_description: d["action_code"]["description"],
        long_description: d["description"],
        user_id: d["user_id"]["user_id"],
        user_name: d["user_id"]["user_name"],
        user_short_name: d["user_id"]["short_name"],
        email: d["user_id"]["email"],
        user_type: d["user_id"]["user_type"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-eye"
          className="p-button-success p-mr-2"
          onClick={() => this.viewAuditTrailDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="View Details"
          data-for="toolTip1"
          data-place="top"
        />
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }

  render() {
    const dialogFooter = (
      <Button
        label="Apply"
        onClick={() => this.getSearchedData()}
        icon="pi pi-search-plus"
      />
    );
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const transactionDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.openConfirmationDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.retryTransactionButton,
          }}
        />
        <Button
          label="Reverse Transaction"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.reversalTransactionButton,
          }}
        />
        <Button
          label="Close"
          className="p-button-success"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.closeTransactionButton,
          }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "left",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };
    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };

    return (
      <div className="layout-content">
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  {/*<div className="card card-w-title">*/}
                  <div>
                    <span className="p-tag custom-heading">Audit Trail</span>
                  </div>
                </div>
              </div>

              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                  </div>
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "24px",
                      display: this.state.showFiltersWeb,
                    }}
                  >
                    <div className="p-field p-col-6 p-sm-6 p-md-3 p-lg-3 p-xl-3">
                      <span className="p-float-label">
                        <Calendar
                          id="range"
                          value={this.state.rangeValue}
                          onChange={(e) =>
                            this.setState(
                              { rangeValue: e.value, dateChange: "Y" },
                              () => this.getFilteredDataDate()
                            )
                          }
                          selectionMode="range"
                          readOnlyInput
                          showIcon={true}
                        />
                        <label style={floatLabel}>DATE RANGE</label>
                      </span>
                    </div>
                    <div className="p-field p-col-6 p-sm-6 p-md-4 p-lg-4 p-xl-4 p-md-offset-5 p-lg-offset-5 p-xl-offset-5">
                      <div className="p-grid">
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingRight: "2px" }}
                        >
                          <div className="p-inputgroup">
                            <span className="p-float-label">
                              <Dropdown
                                style={{ width: "20%" }}
                                options={this.state.searchFilter}
                                value={this.state.dropdownSearchFilter}
                                onChange={(event) =>
                                  this.setState({
                                    dropdownSearchFilter: event.value,
                                  })
                                }
                                autoWidth={false}
                              />
                              <label style={floatLabel}>Select Filter</label>
                            </span>
                          </div>
                        </div>
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingLeft: "0.4px" }}
                        >
                          <div className="p-inputgroup">
                            <InputText
                              value={this.state.searchValue}
                              onChange={this.handleSearchValueChange}
                              placeholder="Search Value"
                            />
                            <Button
                              onClick={() => this.getSearchedData()}
                              icon="pi pi-search"
                              className="p-button-info"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        value={this.state.dataTableValue}
                        paginator={true}
                        rows={10}
                        ref={(el) => {
                          this.dt = el;
                        }}
                      >
                        <Column field="email" style={{ display: "none" }} />
                        <Column
                          field="short_name"
                          style={{ display: "none" }}
                        />
                        <Column field="user_type" style={{ display: "none" }} />
                        <Column field="user_id" style={{ display: "none" }} />
                        <Column
                          field="long_description"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="user_name"
                          header="User Name"
                          body={this.userNameColumnTemplate}
                        />
                        <Column
                          field="short_description"
                          header="Action Performed"
                          body={this.descriptionColumnTemplate}
                        />
                        <Column
                          field="created_at"
                          header="Date / Time"
                          body={this.dateColumnTemplate}
                        />
                        <Column
                          header="Action"
                          body={this.actionColumnTemplate}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          footer={dialogFooter}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.getFilteredDataDate()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                />
                <label style={floatLabel}>DATE RANGE</label>
              </span>
            </div>
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1em" }}
            >
              <div className="p-inputgroup">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: "20%" }}
                    options={this.state.searchFilter}
                    value={this.state.dropdownSearchFilter}
                    onChange={(event) =>
                      this.setState({ dropdownSearchFilter: event.value })
                    }
                    autoWidth={false}
                  />
                  <label style={floatLabel}>Select Filter</label>
                </span>
              </div>
            </div>
            <div className="p-field p-col-12 p-md-8 p-md-offset-2">
              <div className="p-inputgroup">
                <InputText
                  value={this.state.searchValue}
                  onChange={this.handleSearchValueChange}
                  placeholder="Search Value"
                />
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.auditTrailDetailDialog}
          style={{ width: "450px", overflowY: "scroll" }}
          header="Audit Trail Detail"
          modal
          onHide={this.hideAuditTrailDetails}
        >
          <div className="p-grid p-fluid">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <div style={cardStyle}>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>User name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialoguser_name}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Description</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogDescription}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        {/*<Dialog visible={this.state.transactionDialog} style={{ width: '450px', overflowY: 'scroll' }} header="Transaction Detail" modal footer={transactionDialogFooter} onHide={this.hideTransactionDialog}>*/}
        <Dialog
          visible={this.state.transactionDialog}
          style={{ width: "450px", overflowY: "scroll" }}
          header="Transaction Detail"
          modal
          onHide={this.hideTransactionDialog}
        >
          <span className={this.state.dialogStatus}>
            {this.state.dialogStatusText}
          </span>
          <div className="p-grid p-fluid transaction">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <div style={cardStyle}>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Transaction ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogTransId}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Virtual Account ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogVaId}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Full Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogName}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Account Number</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogAccountNum}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Phone Number</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogPhone}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Amount</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogAmount}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Country</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogCountry}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Destination House</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogDestinationHouse}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Exchange Rate</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogExchangeRate}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Recipient A/c Number</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogReciAccountNumber}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Currency</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogReciCurrency}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Description</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogTransDesc}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.confirmationDialog}
          style={{ width: "450px", overflowY: "scroll" }}
          header=""
          modal
          onHide={this.hideConfirmationDialog}
        >
          <form onSubmit={this.requestRetryTransaction}>
            <div className="p-grid p-fluid" style={{ textAlign: "center" }}>
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid">
                  <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <img src={confirmationLogo} />
                  </div>
                  <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <span
                      style={{
                        color: "rgb(92, 74, 181)",
                        fontWeight: "700",
                        fontSize: "40px",
                      }}
                    >
                      Are you sure?
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                    style={{ paddingTop: "0" }}
                  >
                    <span style={{ color: "rgb(92, 74, 181)" }}>
                      Action will send propmt to Admin
                    </span>
                  </div>
                  <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
                    <div className="p-grid">
                      <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          type="button"
                          label="Cancel"
                          className="p-button-outlined p-button-info"
                          onClick={this.hideConfirmationDialog}
                          style={{ marginTop: "1.5em" }}
                        />
                      </div>
                      <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          label="Confirm"
                          type="submit"
                          className="p-button-info"
                          style={{ marginTop: "1.5em" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(AuditTrail);
