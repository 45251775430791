import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../config";
import queryString from "query-string";
const qs = require("query-string");

const checkHistory = "assets/layout/images/refresh.png";
const topUp = "assets/layout/images/topup.png";
const deduct = "assets/layout/images/topdown.png";
const cardStyle = { borderRadius: "20px" };
const detailsStyle = {
  fontSize: "10px",
  paddingTop: "0.8em",
  color: "rgb(189,220,254)",
  float: "right",
};
const buttonDetailsStyle = { fontSize: "10px" };
const topupButtonStyle = {
  padding: "5px",
  background: "rgb(92, 74, 181)",
  border: "0",
  color: "#fff",
  paddingRight: "10px",
  float: "right",
};
const cardHeadingStyle = { color: "#5c4ab5", fontWeight: "600" };
const filterInputBoxStyle = { borderRadius: "4px" };
const labelStyle = {
  paddingLeft: "10px",
  fontSize: "12px",
  color: "#999",
  fontWeight: "500",
};
const amountBorder = {
  borderBottomLeftRadius: "10px",
  borderTopLeftRadius: "10px",
};

class HelpDeskVirtualAccounts extends Component {
  constructor() {
    super();
    document.title = "Virtual Accounts - Instant Credit";
    this.state = {
      data: [],
      items: [],
      topUpDialog: false,
      deducationDialog: false,
      institutionName: "",
      amount: "",
      showBox: "none",
      balanceAmountTooltip: "",
    };
  }

  componentDidMount() {
    this.GetVirtualAccountData();
  }

  navigate2VAHistory = (id) => {
    let payload = { vaid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/va-history");
      } else {
        alert("Failed");
      }
    });
  };
  buttonOnclick = (e) => {
    e.preventDefault();
  };

  openTopUpDialog = () => {
    this.state.topUpDialog = true;
  };
  hideTopUpDialog = () => {
    this.state.topUpDialog = false;
  };
  openDeductDialog = () => {
    this.state.deducationDialog = true;
  };
  hideDeductionDialog = () => {
    this.state.deducationDialog = false;
  };

  GetVirtualAccountData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      userId: this.props.userId,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-user-va",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          if ("" == res.data.data) {
            this.toastSuccess("No Virtual Account created");
            this.setState({
              showBox: "none",
            });
          } else {
            this.setState({
              showBox: "flex",
            });
          }
          this.state.institutionName = res.data.name;
          this.state.data = res.data.data;
          this.state.data.forEach((d) => {
            let str = d["balance"];
            this.setState({
              balanceAmountTooltip: d["balance"],
            });

            let tipAmount =
              d["currency"] +
              " " +
              this.state.balanceAmountTooltip.toLocaleString();
            this.state.items.push(
              <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                  <div className="highlight-details ">
                    <span style={cardHeadingStyle}>{d["account_name"]}</span>
                    {/*<span className="count" style={{ paddingTop: '1em' }}>{d['currency']}&nbsp;{d['balance']}</span>*/}
                    <span
                      className="count"
                      data-tip={tipAmount}
                      data-for="toolTip1"
                      data-place="top"
                      style={{ paddingTop: "1em" }}
                    >
                      {d["currency"]}&nbsp;{str.toLocaleString()}
                    </span>
                    <ReactTooltip id="toolTip1" />
                    <div className="p-grid" style={{ marginTop: "1.4em" }}>
                      <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <img
                            src={checkHistory}
                            style={{ height: "15px", width: "15px" }}
                          />
                          <Link
                            to="/va-history"
                            onClick={() => this.navigate2VAHistory(d["va_id"])}
                            style={{ color: "rgb(189, 220, 254)" }}
                          >
                            Check History
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          this.setState({ items2: this.state.item });
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  render() {
    return (
      <div>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">
                  {this.state.institutionName}
                </span>
              </div>
            </div>
            <div className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
              <div
                className="p-grid p-fluid virtualAccount card"
                style={{ borderRadius: "20px", display: this.state.showBox }}
              >
                {this.state.items}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          visible={this.state.topUpDialog}
          style={{ width: "450px" }}
          header="Top Up Account"
          modal
          onHide={this.hideTopUpDialog}
        >
          <form>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        name="dialogName"
                        onChange={this.handleChange}
                        value="StarPlus Account"
                        readonly
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Account Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <div className="p-inputgroup">
                        <InputText
                          type="number"
                          value={this.state.amount}
                          onChange={(e) =>
                            this.setState({ amount: e.target.value })
                          }
                          style={amountBorder}
                        />
                        <Button
                          onClick={this.buttonOnclick}
                          icon="pi pi-dollar"
                        />
                      </div>
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Amount
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputTextarea
                        id="purpose"
                        rows={3}
                        cols={30}
                        autoResize={true}
                        value={this.state.purpose}
                        onChange={(e) =>
                          this.setState({ purpose: e.target.value })
                        }
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Description
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-7 p-md-6 p-lg-6 p-xl-6 p-offset-5 p-md-offset-5 p-lg-offset-5 p-xl-offset-5">
                <Button
                  label="Request Top Up"
                  className="p-button-info"
                  type="submit"
                  style={{ marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.deducationDialog}
          style={{ width: "450px" }}
          header="Deduct Account"
          modal
          onHide={this.hideDeductionDialog}
        >
          <form>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        name="dialogName"
                        onChange={this.handleChange}
                        value="StarPlus Account"
                        readonly
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Account Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <div className="p-inputgroup">
                        <InputText
                          type="number"
                          value={this.state.amount}
                          onChange={(e) =>
                            this.setState({ amount: e.target.value })
                          }
                          style={amountBorder}
                        />
                        <Button
                          onClick={this.buttonOnclick}
                          icon="pi pi-dollar"
                        />
                      </div>
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Amount
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <InputTextarea
                      id="purpose"
                      rows={3}
                      cols={30}
                      autoResize={true}
                      value={this.state.purpose}
                      onChange={(e) => this.setState({ purpose: e.value })}
                      placeholder="Description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-8 p-md-6 p-lg-6 p-xl-6  p-offset-4 p-md-offset-5 p-lg-offset-5 p-xl-offset-5">
                <Button
                  label="Request Deduction"
                  className="p-button-info"
                  type="submit"
                  style={{ marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    userId: state.login.vaData.userid,
  };
};

const mapDispatchToProps = {
  vaAction: VAAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpDeskVirtualAccounts);
