import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import {Checkbox} from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import '../../config';
//import Cookies from 'universal-cookie';
import axios from "axios";
import queryString from 'query-string';
const qs = require('query-string');
//const cookies = new Cookies();

const checkHistory = 'assets/layout/images/refresh.png';
const edit = 'assets/layout/images/editIcon.png';
const trash = 'assets/layout/images/trash.png';
const filterInputBoxStyle = { borderRadius: '4px' };
const addEmail = 'assets/layout/images/forex_add.png';
const detailsStyle = { fontSize: '12px', color: 'rgb(92, 74, 181)', float: 'right' };
{/*const cardStyle = { borderRadius: '20px', padding: '2em',minHeight:'28.2em',maxHeight:'28.2em'};*/}
const cardStyle = { borderRadius: '20px', padding: '2em',minHeight:'30.1em'};
const submitButtonStyle = { backgroundColor: 'rgb(84,67,163)', fontWeight: '600', fontFamily: 'unset', fontSize: '16px', borderRadius: '10px', height: '40px' };
const pgridCustomStyle = { marginTop: '22px' };
const h1Style = { textAlign: 'center', fontWeight: '600', color: 'rgb(92, 74, 181)', textDecoration: 'underline' };
const labelStyle = { paddingLeft: '10px', fontSize: '12px', color: '#999', fontWeight: '500' };
const labelStyleLeft = { fontSize: '14px' };
const labelStyleLeftFooter = { fontSize: '14px', color: 'blue' };
const labelStyleRight = { fontSize: '14px', float: 'left', fontWeight: '500' };
const labelStyleHeader = { fontSize: '16px', fontWeight: '600', color: 'rgb(180, 215, 254)' };
const labelStyleSubHeader = { fontSize: '14px',color: 'rgb(180, 215, 254)' };
const labelStyleSubHeading = { fontSize: '18px',fontWeight:'700',color: '#fd9519' };
const dialogHeaderTextStyle = { fontSize: '1.4rem',color:'#5c4ab5' };
const badgeStyle = { paddingLeft: '8px', paddingRight: '8px' };
const highlightDetailHeight = {paddingBottom:'4px'};
const confirmationLogo = "assets/layout/images/confirmation.png";

class Settings extends Component {

    constructor() {
        super();
        document.title = "Settings - Instant Credit";
		this.toastSuccess = this.toastSuccess.bind(this);
        this.state = {
			isAddEmailVisible:false,
			isEditEmailVisible:false,
			isAddCountryVisible:false,
			isDeleteEmailVisible:false,
			isDeleteDowntimeVisible:false,
			networkError:'',
			networkCountry:'',
			countryError:'',
			checkboxValue: [],
			selectedCountry:'',
			selectedCountryError:'',
			retryNoOfTimes:[
				{label:'1', value:'1'},
				{label:'2', value:'2'},
				{label:'3', value:'3'},
				{label:'4', value:'4'},
				{label:'5', value:'5'},
			],
			retryNoOfMinutes:[
				{label:'5', value:'5'},
				{label:'10', value:'10'},
				{label:'15', value:'15'},
				{label:'20', value:'20'},
				{label:'25', value:'25'},
			],
			country : [],
			network:[],
			dropdownCountryFirst:'',
			dropdownNetworkFirst:'',
			dropdownCountrySecond:'',
			dropdownNetworkSecond:'',
			dropdownCountryThird:'',
			dropdownNetworkThird:'',
			dropdownRetryNoOfTimes:'1',
			dropdownRetryNoOfMinutes:'5',
			items:'',
			data:'',
			dummyItems:'',
			emailId:'',
			emailIdError:'',
			emailIdEntryId:'',
			editEmailIdeditEmailId:'',
			deleteEmailEntryId:'',
			viewDeleteEmailDialog:'',
			dataTableValueNew:[],
			dataTableValue:[],
			providerSwitchDataTableValue:[],
			selectedCountryError:'',
			selectedNetworkError:'',
			selectedNetwork:'',
			emailDataTableValue:'',
			networkDowntimeId:'',
			editProviderEntryId:'',
			retryEntryId:'',
			editEmailIdOld:'',
			isButtonDisabled: true,
			showLoader:'none',
			contentOpacity:'1',
			sendMailToPartnerVisible:'',
			sendMailBtnId:'',
			selectedCountry1:'',
			selectedNetwork1:'',
        };
		this.onCheckboxChange = this.onCheckboxChange.bind(this);
		this.countryColumnTemplate = this.countryColumnTemplate.bind(this);
		this.networkColumnTemplate = this.networkColumnTemplate.bind(this);

		this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
		this.actionEmailColumnTemplate = this.actionEmailColumnTemplate.bind(this);
		this.emailColumnTemplate = this.emailColumnTemplate.bind(this);
		this.viewEditEmailDialog = this.viewEditEmailDialog.bind(this);
		this.providerCountryColumnTemplate = this.providerCountryColumnTemplate.bind(this);
		this.providerNetworkColumnTemplate = this.providerNetworkColumnTemplate.bind(this);
		this.providerActionColumnTemplate = this.providerActionColumnTemplate.bind(this);
    }
    componentDidMount() {
		this.setState({
            network: this.props.userDetails.network,
        });
		{/*this.getNetworkDownTime();
        this.getEmailList();
		this.getRetryData();
		this.getProviderList();*/}
		this.getSettingData();
    }
	getSettingData = () => {
		this.setState({
				contentOpacity:0.5,
				showLoader:'block'
			});
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
        }
        axios.post(global.config.main.baseUrl + 'setting/list/all', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.setState({
						country:res.data.country
					});
					console.log("ALL DATA");
					console.log(res.data);
					console.log(res.data.retry.retry_time);
					this.createNetworkOperatorDataTable(res.data.downNetwork);
					this.createEmailListDataTable(res.data.mail);	
					{/*this.createProviderDataTable(res.data.switchNetwork);*/}
					this.setState({
						dropdownRetryNoOfTimes:res.data.retry.retry,
						dropdownRetryNoOfMinutes:res.data.retry.retry_time,
						retryEntryId:res.data.retry.id,
					});
					this.setState({
						contentOpacity:1,
						showLoader:'none'
					});
                }
                else {
					this.setState({
						contentOpacity:1,
						showLoader:'none'
					});
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
	}
	getNetworkDownTime = () => {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
        }
        axios.post(global.config.main.baseUrl + 'setting/network/list', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.setState({
						country:res.data.country
					});
					this.createNetworkOperatorDataTable(res.data.data);
                }
                else {
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
	}
	getEmailList = async () => {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'setting/email/list', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					console.log(res.data);
					this.createEmailListDataTable(res.data.data);	

                }
                else {
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
    }
	getRetryData = () => {
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
        }
        axios.post(global.config.main.baseUrl + 'setting/retry/get', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					console.log(res.data);
					this.setState({
						dropdownRetryNoOfTimes:res.data.data.retry,
						dropdownRetryNoOfMinutes:res.data.data.retry_time,
						retryEntryId:res.data.data.id,
					});
                }
                else {
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}
	getProviderList = async () => {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'setting/provider/list', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					console.log(res.data);
					this.createProviderDataTable(res.data.data);

                }
                else {
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
    }
	createNetworkOperatorDataTable(data) {
		this.state.dataTableValueNew = [];
		this.state.dataTableValue = [];
		this.state.dataTableValueNew = data;
		this.state.dataTableValueNew.forEach((d) => {
			this.state.dataTableValue.push(
				{
					"data_table_country":d['country_id']['name'],"data_table_network":d['network']['name'],"entry_id":d['id']
				}
			);
		});
        this.setState({ dataTableDummyValue: this.state.dataTableValue });
    }
	createEmailListDataTable(data) {
		this.state.dataTableValueNew = [];
		this.state.emailDataTableValue = [];
		this.state.dataTableValueNew = data;
		this.state.dataTableValueNew.forEach((d) => {
		this.state.emailDataTableValue.push(
			{
				"data_table_email":d['email'],"data_table_id":d['id']
			}
		);
		});
        this.setState({ dataTableDummyValue: this.state.dataTableValue });
    }
	handleEditEmailIdButton = (e) => {
		console.log(this.state.editEmailIdOld);
		console.log(this.state.editEmailId);
		if(this.state.editEmailIdOld == this.state.editEmailId)
		{
			this.setState({
                isButtonDisabled: true,
            });
		}
		else
		{
			this.setState({
                isButtonDisabled: false,
            });
		}
	}
		
		
	countryColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Country</span>
				{rowData.data_table_country}
            </React.Fragment>
        );
    }
	networkColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Network</span>
				{rowData.data_table_network}
            </React.Fragment>
        );
    }

	actionColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Action</span>
				<img src={trash} style={{height: '15px', width: '15px' }} onClick={(e) => this.viewDeleteDowntimeDialog(rowData)} />
            </React.Fragment>
        );
    }
	actionEmailColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Action</span>
				<img src={edit} style={{height: '15px', width: '15px' }} onClick={(e) => this.viewEditEmailDialog(rowData)} />&nbsp;&nbsp;
				<img src={trash} style={{height: '15px', width: '15px' }} onClick={(e) => this.viewDeleteEmailDialog(rowData)} />
            </React.Fragment>
        );
    }
	providerActionColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Action</span>
				<img src={edit} style={{height: '15px', width: '15px' }} onClick={(e) => this.viewEditProviderDialog(rowData)} />
            </React.Fragment>
        );
    }
	providerCountryColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Country</span>
				{rowData.provider_table_country}
            </React.Fragment>
        );
    }
	providerNetworkColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Network</span>
				{rowData.provider_table_network}
            </React.Fragment>
        );
    }
	emailColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Email</span>
				<span style={{wordBreak:'break-all'}}>{rowData.data_table_email}</span>
            </React.Fragment>
        );
    }
	onCheckboxChange(event){
        let selected = [...this.state.checkboxValue];

        if(event.checked)
            selected.push(event.value);
        else
            selected.splice(selected.indexOf(event.value), 1);

        this.setState({checkboxValue: selected});
    }
	addEmail = (event) => {
		event.preventDefault();
		console.log(this.state.emailId);
		if('' == this.state.emailId)
		{
			this.setState({
				emailIdError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				emailIdError:'',
			});
		}
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
			email: this.state.emailId,
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'setting/email/add', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
                    console.log(res.data.data);
					this.getEmailList();
					this.hideAddEmailDialog();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.hideAddEmailDialog();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}
	editEmail = (event) => {
		event.preventDefault();
		console.log(this.state.editEmailId);
		console.log(this.state.emailIdEntryId);
		if('' == this.state.editEmailId)
		{
			this.setState({
				editEmailIdError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				editEmailIdErrorError:'',
			});
		}
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
			email:this.state.editEmailId,
			entry_id: this.state.emailIdEntryId,
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'setting/email/update', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.getEmailList();
					this.hideEditEmailDialog();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.hideEditEmailDialog();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}
	deleteEmail = (event) => {
		event.preventDefault();
		console.log(this.state.deleteEmailEntryId);
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
			entry_id: this.state.deleteEmailEntryId,
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'setting/email/delete', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.getEmailList();
					this.hideDeleteEmailDialog();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.hideDeleteEmailDialog();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}
	deleteNetworkDowntime = (event) => {
		event.preventDefault();
		console.log(this.state.networkDowntimeId);
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
			entry_id: this.state.networkDowntimeId,
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'setting/network/delete', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.getNetworkDownTime();
					this.hideDeleteDowntimeDialog();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.hideDeleteDowntimeDialog();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}
	addNetworkDownTime = (event) => {
		console.log(this.state.sendMailBtnId);
		let sendMailToPartner = '';
		if(0 == this.state.sendMailBtnId)
		{
			sendMailToPartner = 'N';
		}
		else
		{
			sendMailToPartner = 'Y';
		}
		{/*event.preventDefault();*/}
			{/*if('' == this.state.selectedCountry)
		{
			this.setState({
				selectedCountryError:'1.5px solid red',
			})
			return false;
		}
		else
		{
			this.setState({
				selectedCountryError:'',
			})
		}
		if('' == this.state.selectedNetwork)
        {
            this.setState({
                selectedNetworkError:'1.5px solid red',
            })
            return false;
        }
        else
        {
            this.setState({
                selectedNetworkError:'',
            })
        }*/}

        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
			country:this.state.selectedCountry1,
			network:this.state.selectedNetwork1,
			partners:sendMailToPartner,
			//country:this.state.selectedCountry,
			//network:this.state.selectedNetwork,
        }
        axios.post(global.config.main.baseUrl + 'setting/network/add', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					console.log(res.data);
					this.toastSuccess(res.data.msg);
					this.getNetworkDownTime();
					this.state.selectedCountry='';
					this.state.selectedNetwork='';
					this.hideAddCountryDialog();
                }
                else {
					this.toastError(res.data.msg);
					this.hideAddCountryDialog();
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
	}
	editProviderNetwork = (event) => {
		event.preventDefault();
		console.log(this.state.dropdownProviderNetwork);
		console.log(this.state.editProviderEntryId);
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
			network: this.state.dropdownProviderNetwork,
			entry_id: this.state.editProviderEntryId,
        }
        axios.post(global.config.main.baseUrl + 'setting/provider/update', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.getProviderList();
					this.hideEditProviderDialog();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.hideEditProviderDialog();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}
	editRetryData = (event) => {
		event.preventDefault();
		console.log(this.state.dro);
		console.log(this.state.editProviderEntryId);
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
			entry_id:this.state.retryEntryId,
			retry: this.state.dropdownRetryNoOfTimes,
			retry_time: this.state.dropdownRetryNoOfMinutes,
        }
        axios.post(global.config.main.baseUrl + 'setting/provider/update', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.getRetryData();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}
	updateRetry = (event) => {
		event.preventDefault();
		console.log(this.state.dropdownRetryNoOfTimes);
		console.log(this.state.dropdownRetryNoOfMinutes);
		console.log(this.state.retryEntryId);
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
			entry_id:this.state.retryEntryId,
			retry:this.state.dropdownRetryNoOfTimes,
			retry_time:this.state.dropdownRetryNoOfMinutes,
        }
        axios.post(global.config.main.baseUrl + 'setting/retry/update', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.getRetryData();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.getRetryData();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
	}

	createProviderDataTable(data) {
		console.log("PROVIDER TABLE");
		console.log(data);
		this.state.dataTableValueNew = [];
		this.state.providerSwitchDataTableValue = [];
		this.state.dataTableValueNew = data;
		this.state.dataTableValueNew.forEach((d) => {
		this.state.providerSwitchDataTableValue.push(
			{
				"provider_table_country":d['network']['country_name'],"provider_table_network":d['network']['name'],"provider_table_entry_id":d['id'],"network_id":d['network']['id']
			}
			);
			this.setState({ dummyItems: this.state.items });
		});
		this.setState({ dataTableDummyValue: this.state.dataTableValue });
    }

	toastSuccess(msg) {
        this.toastBR.show({severity:'success', summary: '', detail:msg, life: 5000});
    }
	toastError(msg) {
        this.toastBR.show({severity:'error', summary: '', detail:msg, life: 5000});
    }
	
	viewAddEmailDialog = () => {
		this.setState({
			isAddEmailVisible : true,
		});
	}
	
	hideAddEmailDialog = () => {
		this.setState({
			emailId:'',
			emailIdError:'',
			isAddEmailVisible : false,
		});
	}
	viewEditProviderDialog = (rowData) => {
		console.log(rowData);
		this.setState({
			dropdownProviderNetwork:rowData.network_id,
			editProviderCountry:rowData.provider_table_country,
			networkCountry:rowData.provider_table_country,
			editProviderEntryId:rowData.provider_table_entry_id,
			isEditProviderVisible : true,
		}, () => this.getCountryNetwork());

	}
	getCountryNetwork = async () => {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
            country: this.state.networkCountry
        }
        axios.post(global.config.main.baseUrl + 'network/country/list', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
                    console.log(res.data);
                    if('' != res.data.data)
                    {
                        this.setState({
                            network: res.data.data
                        });
                    }
                }
                else {
                }
            })
.catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
    }
	
	hideEditProviderDialog = () => {
		this.setState({
			isEditProviderVisible : false,
		});
	}
	viewEditEmailDialog = (rowData) => {
		console.log(rowData);
		this.setState({
			emailIdEntryId : rowData.data_table_id,
			editEmailId : rowData.data_table_email,
			editEmailIdOld : rowData.data_table_email,
			isEditEmailVisible : true,
		});
	}
	
	hideEditEmailDialog = () => {
		this.setState({
			isEditEmailVisible : false,
		});
	}
	viewDeleteEmailDialog = (rowData) => {
		this.setState({
			deleteEmailEntryId: rowData.data_table_id,
			isDeleteEmailVisible : true,
		});
	}
	
	hideDeleteEmailDialog = () => {
		this.setState({
			isDeleteEmailVisible : false,
		});
	}
	viewDeleteDowntimeDialog = (rowData) => {
		console.log(rowData);
		this.setState({
			networkDowntimeId : rowData.entry_id,
			isDeleteDowntimeVisible : true,
		});
	}
	
	hideDeleteDowntimeDialog = () => {
		this.setState({
			isDeleteDowntimeVisible : false,
		});
	}
		
	viewAddCountryDialog = () => {
		this.setState({
			isAddCountryVisible : true,
		});
	}
	
	hideAddCountryDialog = () => {
		this.setState({
			selectedCountry:'',
			selectedNetwork:'',
			isAddCountryVisible : false,
		});
	}
	hideAddCountryDialog1 = (country) => {
		this.setState({
			selectedCountry:country,
			isAddCountryVisible : false,
		});
	}
	showsendMailToPartnerVisible = (event) => {
		event.preventDefault();
		if('' == this.state.selectedCountry)
        {
            this.setState({
                selectedCountryError:'1.5px solid red',
            })
            return false;
        }
        else
        {
            this.setState({
                selectedCountryError:'',
            })
        }
        if('' == this.state.selectedNetwork)
        {
            this.setState({
                selectedNetworkError:'1.5px solid red',
            })
            return false;
        }
        else
        {
            this.setState({
                selectedNetworkError:'',
            })
        }
		let selCountry = this.state.selectedCountry;
		let selNetwork = this.state.selectedNetwork;
		this.setState({
			selectedCountry1 : selCountry,
			selectedNetwork1 : selNetwork,
		});
		this.setState({
			isAddCountryVisible : false,
			sendMailToPartnerVisible : true,
		});
	}
	hidesendMailToPartnerVisible = () => {
		this.setState({
			sendMailToPartnerVisible : false,
		});
	}
	sendMailToPartner = (event) => {
		event.preventDefault();
		console.log(this.state.sendMailBtnId);	
		this.setState({
            sendMailToPartnerVisible : false,
        });
		this.addNetworkDownTime();
	}
    render() {
        const borderStyle = { border: this.state.confirmPasswordBorder };

        return (
            <div style={{opacity:this.state.contentOpacity}}>
				<Toast ref={(el) => this.toastBR = el} position="top-right" />
				<ProgressSpinner style={{display:this.state.showLoader,width: '40px', height: '40px',position:'fixed',top:'50%',left:'50%',bottom:'50%',right:'50%',zIndex:'1111111111'}} strokeWidth="3" animationDuration=".5s"/>
                <div className="p-grid p-fluid" style={{ marginTop: '20px' }}>
                    <div className="p-col-12 p-md-12 p-xl-12">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{textAlign:'center'}}>
								<span className="p-tag custom-heading">Settings</span>
                            </div>
                        </div>
                        <div className="p-grid p-fluid profile" style={pgridCustomStyle}>
                            <div className="p-col-12 p-sm-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
                                <div className="card card-w-title" style={cardStyle}>
                                    <div className="p-grid" style={{marginBottom:'1em'}}>
                                        <div className="p-col-12 p-md-8 p-lg-8 p-xl-8">
                                            <label style={labelStyleHeader}>Network Operator Downtime</label>
                                        </div>
                                        <div className="p-col-12 p-md-4 p-lg-4 p-xl-4">
											<span className="detail span-custom-success" style={{fontSize: '12px', color: 'rgb(92, 74, 181)', float: 'right',cursor:'pointer'}} onClick={() => this.viewAddCountryDialog()}>
												Add Network&nbsp;
												<img src={addEmail} style={{ height: '20px', width: '20px' }} onClick={() => this.viewAddCountryDialog()} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="p-grid">
										<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
											<div className="datatable-responsive-demo">
                                    			<div>
                                        			<DataTable className="p-datatable-responsive-demo" resizableColumns columnResizeMode="expand" value={this.state.dataTableValue} paginator={true} rows={4} ref={(el) => { this.dt = el; }} header="">
                                            			<Column field="entry_id" style={{display:'none'}} />
                                            			<Column field="data_table_country" header="Country" body={this.countryColumnTemplate} />
                                            			<Column field="data_table_network" header="Network" body={this.networkColumnTemplate} />
                                            			<Column header="Action" body={this.actionColumnTemplate} />
                                        			</DataTable>
                                    			</div>
                                			</div>
										</div>
									</div>
                                </div>
                            </div>
                            <div className="p-col-12 p-sm-12 p-md-5 p-lg-5 p-xl-5">
                                <div className="card card-w-title" style={cardStyle}>
                                    <div className="p-grid" style={{marginBottom:'1em'}}>
                                        <div className="p-col-7 p-md-8 p-lg-8 p-xl-8">
                                            <label style={labelStyleHeader}>Mailing List</label>
                                        </div>
                                        <div className="p-col-5 p-md-4 p-lg-4 p-xl-4">
											<span className="detail span-custom-success" style={{fontSize: '12px', color: 'rgb(92, 74, 181)', float: 'right',cursor:'pointer'}} onClick={() => this.viewAddEmailDialog()}>
												Add Email&nbsp;
												<img src={addEmail} style={{ height: '20px', width: '20px' }} onClick={() => this.viewAddEmailDialog()} />
                                            </span>
                                        </div>
                                    </div>
									<div className="p-grid">
										<div className="datatable-responsive-demo">
											<div>
												<DataTable className="p-datatable-responsive-demo" resizableColumns columnResizeMode="expand" value={this.state.emailDataTableValue} rows={4} paginator={true} ref={(el) => { this.dt = el; }} header="">
													<Column field="data_table_email" style={{width:'70%'}} header="Email" body={this.emailColumnTemplate} />
													<Column header="Action" body={this.actionEmailColumnTemplate} />
												</DataTable>
											</div>
										</div>
                                	</div>
                                </div>
                            </div>
                            <div className="p-col-12 p-sm-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{display:'none'}}>
								<form onSubmit={this.updateRetry}>
                                <div className="card card-w-title" style={cardStyle}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                                            <label style={labelStyleHeader}>Retry Limit</label>
                                        </div>
                                    </div>
                                    <div className="p-grid" style={{marginTop:'1em'}}>
                                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                                            <label style={labelStyleSubHeader}>Maximun No. of times a retry can be performed</label>
                                        </div>
                                    </div>
                                    <div className="p-grid" style={{marginTop:'1em'}}>
                                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
											{/*<span className="p-float-label">
								  				<Dropdown style={filterInputBoxStyle} options={this.state.retryNoOfTimes} value={this.state.dropdownRetryNoOfTimes} onChange={event => this.setState({ dropdownRetryNoOfTimes: event.value })} autoWidth={false} />
												<label style={labelStyle}>Max no. of Retry</label>
                                            </span>*/}
								  			<Dropdown style={filterInputBoxStyle} options={this.state.retryNoOfTimes} value={this.state.dropdownRetryNoOfTimes} onChange={event => this.setState({ dropdownRetryNoOfTimes: event.value })} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-grid" style={{marginTop:'1em'}}>
                                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                                            <label style={labelStyleSubHeader}>Maximun No. of minutes a retry can be performed</label>
                                        </div>
                                    </div>
                                    <div className="p-grid" style={{marginTop:'1em'}}>
										<div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
											{/*<span className="p-float-label">
								  				<Dropdown style={filterInputBoxStyle} options={this.state.retryNoOfMinutes} value={this.state.dropdownRetryNoOfMinutes} onChange={event => this.setState({ dropdownRetryNoOfMinutes: event.value })} autoWidth={false} />
												<label style={labelStyle}>Max minutes to perform  Retry</label>
                                            </span>*/}
								  			<Dropdown style={filterInputBoxStyle} options={this.state.retryNoOfMinutes} value={this.state.dropdownRetryNoOfMinutes} onChange={event => this.setState({ dropdownRetryNoOfMinutes: event.value })} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-8 p-lg-offset-8 p-xl-offset-8">
											<Button label="Update" type="submit" className="p-button-info" style={{ marginTop: '1.5em' }} />
                                        </div>
                                    </div>
                                </div>
								</form>
                            </div>
							{/*<div className="p-col-12 p-sm-12 p-md-5 p-lg-5 p-xl-5">
                                <div className="card card-w-title" style={cardStyle}>
                                    <div className="p-grid" style={{marginBottom:'1em'}}>
                                        <div className="p-col-12 p-md-8 p-lg-8 p-xl-8">
                                            <label style={labelStyleHeader}>Provider Switch</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
										<div className="datatable-responsive-demo">
                                            <div>
                                                <DataTable className="p-datatable-responsive-demo" resizableColumns columnResizeMode="expand" value={this.state.providerSwitchDataTableValue} rows={4} paginator={true} ref={(el) => { this.dt = el; }} header="">
                                                    <Column field="network_id" style={{display:'none'}} />
                                                    <Column field="provider_table_entry_id" style={{display:'none'}} />
                                                    <Column field="provider_table_country" header="Country"  body={this.providerCountryColumnTemplate}/>
                                                    <Column field="provider_table_network" header="Network" body={this.providerNetworkColumnTemplate}/>
                                                    <Column header="Action" body={this.providerActionColumnTemplate} />
                                                </DataTable>
                                            </div>
                                        </div>
									</div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
				<Dialog visible={this.state.isAddEmailVisible} style={{ width: '450px' }} header="Add Email" modal onHide={this.hideAddEmailDialog}>
                    <form onSubmit={this.addEmail}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText type="email" onChange={(e) => this.setState({ emailId: e.target.value })} value={this.state.emailId} style={{ border: this.state.emailIdError }} />
                                            <label style={labelStyle}>Email</label>
                                        </span>
                                    </div>
								</div>
								<div className="p-grid p-fluid">
                            		<div className="p-col-12 p-md-12 p-lg-12 p-xl-12" style={{textAlign:'center'}}>
                                		<Button label="Request" className="p-button-info" type="submit" style={{width:'50%',marginTop: '1.5em' }} />
                            		</div>
                        		</div>
							</div>
						</div>
					</form>
				</Dialog>
				<Dialog visible={this.state.isEditEmailVisible} style={{ width: '450px' }} header="Edit Email" modal onHide={this.hideEditEmailDialog}>
                    <form onSubmit={this.editEmail}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                <div className="p-grid p-fluid" style={{marginTop:'1em'}}>
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
											{/*<InputText onChange={(e) => this.setState({ editEmailId: e.target.value },()=> this.handleEditEmailIdButton())} value={this.state.editEmailId} style={{ border: this.state.editEmailIdError }} />*/}
                                            <InputText type="email" onChange={(e) => this.setState({ editEmailId: e.target.value })} value={this.state.editEmailId} style={{ border: this.state.editEmailIdError }} />
                                            <label style={labelStyle}>Email</label>
                                        </span>
                                    </div>
								</div>
								<div className="p-grid p-fluid" style={{marginTop:'0.5em'}}>
                            		<div className="p-col-12 p-md-12 p-lg-12 p-xl-12" style={{textAlign:'center'}}>
										{/*<Button label="Update" className="p-button-info" type="submit" style={{width:'50%',marginTop: '1.5em' }} disabled={this.state.isButtonDisabled}/>*/}
                                		<Button label="Update" className="p-button-info" type="submit" style={{width:'50%',marginTop: '1.5em' }}/>
                            		</div>
                        		</div>
							</div>
						</div>
					</form>
				</Dialog>
				<Dialog visible={this.state.isAddCountryVisible} style={{ width: '450px' }} header="Add Network" modal onHide={this.hideAddCountryDialog}>
				{/*<form onSubmit={this.addNetworkDownTime}>*/}
					<form onSubmit={this.showsendMailToPartnerVisible}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
										<span className="p-float-label">
											<Dropdown style={filterInputBoxStyle} options={this.state.country} value={this.state.selectedCountry} onChange={event => this.setState({ selectedCountry: event.value,networkCountry:event.value }, () => this.getCountryNetwork())} autoWidth={false} style={{ border: this.state.selectedCountryError }}/>
											<label style={labelStyle}>Select County</label>
										</span>
                                    </div>
								</div>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
										<span className="p-float-label">
											<Dropdown style={filterInputBoxStyle} options={this.state.network} value={this.state.selectedNetwork} onChange={event => this.setState({ selectedNetwork: event.value })} autoWidth={false} style={{ border: this.state.selectedNetworkError }}/>
											<label style={labelStyle}>Select Network</label>
										</span>
                                    </div>
								</div>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-7 p-lg-offset-7 p-xl-offset-7" style={{ marginTop: '20px' }}>
										<Button type="submit" label="Request" className=" p-button-info"/>
                                    </div>
								</div>
							</div>
						</div>
					</form>
				</Dialog>
				<Dialog visible={this.state.isDeleteEmailVisible} style={{ width: '400px',overflowY:'scroll' }} header="" modal onHide={this.hideDeleteEmailDialog}>
                    <form onSubmit={this.deleteEmail}>
                    <div className="p-grid p-fluid" style={{ textAlign: 'center' }}>
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                                    <img src={confirmationLogo} />
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                                    <span style={{ color: 'rgb(92, 74, 181)', fontWeight: '700', fontSize: '30px' }}>Are you sure?</span>
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-12 p-xl-12" style={{ paddingTop: '0' }}>
                                    <span style={{fontSize:'15px',color: 'rgb(92, 74, 181)' }}>This will permanently delete the email</span>
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
                                    <div className="p-grid">
                                        <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                                            <Button type="button" label="Cancel" className="p-button-outlined p-button-info" onClick={this.hideDeleteEmailDialog} style={{ marginTop: '1.5em' }} />
                                        </div>
                                        <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                                            <Button label="Yes" type="submit" className="p-button-info" style={{ marginTop: '1.5em',border:'1px solid #5443a3'}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                </Dialog>
				<Dialog visible={this.state.isDeleteDowntimeVisible} style={{ width: '400px',overflowY:'scroll' }} header="" modal onHide={this.hideDeleteDowntimeDialog}>
                    <form onSubmit={this.deleteNetworkDowntime}>
                    	<div className="p-grid p-fluid" style={{ textAlign: 'center' }}>
                    	    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    	        <div className="p-grid">
                    	            <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    	                <img src={confirmationLogo} />
                    	            </div>
                    	            <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    	                <span style={{ color: 'rgb(92, 74, 181)', fontWeight: '700', fontSize: '30px' }}>Are you sure?</span>
                    	            </div>
                    	            <div className="p-col-12 p-md-12 p-lg-12 p-xl-12" style={{ paddingTop: '0' }}>
                    	                <span style={{fontSize:'15px',color: 'rgb(92, 74, 181)' }}>This will delete the downtime of network</span>
                    	            </div>
                    	            <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
                    	                <div className="p-grid">
                    	                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                    	                        <Button type="button" label="Cancel" className="p-button-outlined p-button-info" onClick={this.hideDeleteDowntimeDialog} style={{ marginTop: '1.5em' }} />
                    	                    </div>
                    	                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                    	                        <Button label="Yes" type="submit" className="p-button-info" style={{ marginTop: '1.5em',border:'1px solid #5443a3'}} />
                    	                    </div>
                    	                </div>
                    	            </div>
                    	        </div>
                    	    </div>
                    	</div>
                    </form>
                </Dialog>
				<Dialog visible={this.state.isEditProviderVisible} style={{ width: '450px' }} header="Edit Provider Switch" modal onHide={this.hideEditProviderDialog}>
                    <form onSubmit={this.editProviderNetwork}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                <div className="p-grid p-fluid" style={{marginTop:'1em'}}>
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editProviderCountry} style={{color:'rgb(140, 140, 140)',backgroundColor:'rgb(212, 212, 212)'}} readOnly/>
                                            <label style={labelStyle}>Country</label>
                                        </span>
                                    </div>
								</div>
                                <div className="p-grid p-fluid" style={{marginTop:'1em'}}>
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
										<span className="p-float-label">
								  			<Dropdown style={filterInputBoxStyle} options={this.state.network} value={this.state.dropdownProviderNetwork} onChange={event => this.setState({ dropdownProviderNetwork: event.value })} autoWidth={false} />
											<label style={labelStyle}>Network</label>
										</span>
									</div>
								</div>
								<div className="p-grid p-fluid" style={{marginTop:'1em'}}>
                            		<div className="p-col-12 p-md-12 p-lg-12 p-xl-12" style={{textAlign:'center'}}>
                                		<Button label="Update" className="p-button-info" type="submit" style={{width:'50%',marginTop: '1.5em' }} />
                            		</div>
                        		</div>
							</div>
						</div>
					</form>
				</Dialog>
				<Dialog visible={this.state.sendMailToPartnerVisible} style={{ width: '400px',overflowY:'scroll' }} header="" modal onHide={this.hidesendMailToPartnerVisible}>
                    <form onSubmit={this.sendMailToPartner}>
                    	<div className="p-grid p-fluid" style={{ textAlign: 'center' }}>
                    	    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    	        <div className="p-grid">
                    	            <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    	                <img src={confirmationLogo} />
                    	            </div>
                    	            <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    	                <span style={{ color: 'rgb(92, 74, 181)', fontWeight: '700', fontSize: '30px' }}>Send mail to Partners linked</span>
                    	            </div>
                    	            <div className="p-col-12 p-md-12 p-lg-12 p-xl-12" style={{ paddingTop: '0' }}>
                    	                <span style={{fontSize:'15px',color: 'rgb(92, 74, 181)' }}>This will send mail to linked Patners</span>
                    	            </div>
                    	            <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
                    	                <div className="p-grid">
                    	                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                    	                        <Button type="submit" label="No" className="p-button-outlined p-button-info" style={{ marginTop: '1.5em' }} onClick={(e) => this.setState({ sendMailBtnId: '0' })}/>
                    	                    </div>
                    	                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                    	                        <Button label="Yes" type="submit" className="p-button-info" style={{ marginTop: '1.5em',border:'1px solid #5443a3'}} onClick={(e) => this.setState({ sendMailBtnId: '1' })}/>
                    	                    </div>
                    	                </div>
                    	            </div>
                    	        </div>
                    	    </div>
                    	</div>
                    </form>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.login.userDetails,
    }
}

export default connect(mapStateToProps, null)(Settings);
