import React, { Component } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactTooltip from "react-tooltip";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import "../../config";
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");

class Forex extends Component {
  constructor() {
    super();
    document.title = "Forex - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      addForexButtonText: "Send Request",
      dropdownFromCurrency: "",
      fromCurrencyError: "",
      dropdownToCurrency: "",
      toCurrencyError: "",
      rateError: "",
      addForexDialog: false,
      currency: "",
      rate: "",
      dialogPhone: "",
      dialogTransId: "",
      dateChange: "N",
      dialogAmount: "",
      dialogCountry: "",
      dialogAccountNum: "",
      dialogName: "",
      dialogStatus: "",
      dialogStatusText: "",
      dialogDestinationHouse: "",
      dialogExchangeRate: "",
      dialogReciAccountNumber: "",
      dialogReciCurrency: "",
      dialogTransDesc: "",
      dialogVaId: "",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      transactionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      reversalTransactionButton: "none",
      closeTransactionButton: "none",
      submitted: false,
      dropdownCity: "All",
      dropdownSearchFilter: "",
      filterCountry: "",
      filterDate: "",
      searchValueText: "",
      status: null,
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      cities: [],
      showLoader: "none",
      contentOpacity: "1",
      rangeValue: [],
      allInstitution: [],
      dropdownInstitutionSelected: "",
      dropdownInstitutionSelectedError: "",
      effectiveDate: null,
      validDate: null,
      selectedCountry: null,
      selectedCountryCode: null, // Store just the country code
      countries: [
        { name: "Algeria", code: "DZ" },
        { name: "Angola", code: "AO" },
        { name: "Benin", code: "BJ" },
        { name: "Botswana", code: "BW" },
        { name: "Burkina Faso", code: "BF" },
        { name: "Burundi", code: "BI" },
        { name: "Cabo Verde", code: "CV" },
        { name: "Cameroon", code: "CM" },
        { name: "Central African Republic", code: "CF" },
        { name: "Chad", code: "TD" },
        { name: "Comoros", code: "KM" },
        { name: "Congo, Democratic Republic of the", code: "CD" },
        { name: "Congo, Republic of the", code: "CG" },
        { name: "Djibouti", code: "DJ" },
        { name: "Egypt", code: "EG" },
        { name: "Equatorial Guinea", code: "GQ" },
        { name: "Eritrea", code: "ER" },
        { name: "Eswatini", code: "SZ" },
        { name: "Ethiopia", code: "ET" },
        { name: "Gabon", code: "GA" },
        { name: "Gambia", code: "GM" },
        { name: "Ghana", code: "GH" },
        { name: "Guinea", code: "GN" },
        { name: "Guinea-Bissau", code: "GW" },
        { name: "Ivory Coast", code: "CI" },
        { name: "Kenya", code: "KE" },
        { name: "Lesotho", code: "LS" },
        { name: "Liberia", code: "LR" },
        { name: "Libya", code: "LY" },
        { name: "Madagascar", code: "MG" },
        { name: "Malawi", code: "MW" },
        { name: "Mali", code: "ML" },
        { name: "Mauritania", code: "MR" },
        { name: "Mauritius", code: "MU" },
        { name: "Morocco", code: "MA" },
        { name: "Mozambique", code: "MZ" },
        { name: "Namibia", code: "NA" },
        { name: "Niger", code: "NE" },
        { name: "Nigeria", code: "NG" },
        { name: "Rwanda", code: "RW" },
        { name: "Sao Tome and Principe", code: "ST" },
        { name: "Senegal", code: "SN" },
        { name: "Seychelles", code: "SC" },
        { name: "Sierra Leone", code: "SL" },
        { name: "Somalia", code: "SO" },
        { name: "South Africa", code: "ZA" },
        { name: "South Sudan", code: "SS" },
        { name: "Sudan", code: "SD" },
        { name: "Tanzania", code: "TZ" },
        { name: "Togo", code: "TG" },
        { name: "Tunisia", code: "TN" },
        { name: "Uganda", code: "UG" },
        { name: "Zambia", code: "ZM" },
        { name: "Zimbabwe", code: "ZW" },
      ], //mapping between afring countries and their code
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.viewTransactionDetails = this.viewTransactionDetails.bind(this);
    this.hideTransactionDialog = this.hideTransactionDialog.bind(this);
    this.fromColumnTemplate = this.fromColumnTemplate.bind(this);
    this.institutionColumnTemplate = this.institutionColumnTemplate.bind(this);
    this.toColumnTemplate = this.toColumnTemplate.bind(this);
    this.rateColumnTemplate = this.rateColumnTemplate.bind(this);
    this.inititatedByColumnTemplate =
      this.inititatedByColumnTemplate.bind(this);
    this.approvedByColumnTemplate = this.approvedByColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  institutionColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Institution</span>
        <span style={{ width: "50%" }}>{rowData.institution}</span>
      </React.Fragment>
    );
  }
  fromColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Source Currency</span>
        <span style={{ width: "50%" }} className={pTag}>
          {rowData.from_currency}
        </span>
      </React.Fragment>
    );
  }
  toColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Destination Currency</span>
        <span style={{ width: "50%" }} className={pTag}>
          {rowData.to_currency}
        </span>
      </React.Fragment>
    );
  }

  rateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Rate</span>
        {rowData.rate}
      </React.Fragment>
    );
  }

  inititatedByColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Initiated By</span>
        {rowData.initiated_by}
      </React.Fragment>
    );
  }

  approvedByColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Approved By</span>
        {rowData.approved_by}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        {rowData.modified_at}
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let ptag = rowData.status;
    let pTag = "";
    let status = "FAILED";
    if (ptag == "1") {
      console.log("ACTIVE");
      status = "SUCCESS";
      pTag = "p-tag p-tag-success";
      console.log(pTag);
    } else {
      console.log("BLOCKED");
      pTag = "p-tag p-tag-danger";
      console.log(pTag);
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-eye"
          className="p-button-success p-mr-2"
          onClick={() => this.viewTransactionDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
        />
      </React.Fragment>
    );
  }

  viewTransactionDetails(rowData) {
    if (0 == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-pending",
        dialogStatusText: "In-Progress",
        retryTransactionButton: "none",
        reversalTransactionButton: "none",
        closeTransactionButton: "initial",
      });
    } else if (1 == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Success",
        retryTransactionButton: "none",
        reversalTransactionButton: "initial",
        closeTransactionButton: "none",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "Failed",
        retryTransactionButton: "initial",
        reversalTransactionButton: "none",
        closeTransactionButton: "none",
      });
    }

    this.setState({
      dialogTransId: rowData.transId,
      dialogAmount: rowData.amount,
      dialogCountry: rowData.country,
      dialogAccountNum: rowData.account_number,
      dialogName: rowData.name,
      dialogPhone: rowData.phone,

      dialogDestinationHouse: rowData.destination_house,
      dialogExchangeRate: rowData.exchange_rate,
      dialogReciAccountNumber: rowData.recipient_account_no,
      dialogReciCurrency: rowData.recipient_currency,
      dialogTransDesc: rowData.trans_desc,
      dialogVaId: rowData.va_id,

      transactionDialog: true,
    });
  }

  hideTransactionDialog() {
    this.setState({ transactionDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  componentDidMount() {
    if ("0" == this.props.userDetails.type) {
      this.setState({
        addForexButtonText: "Add",
      });
    } else {
      this.setState({
        addForexButtonText: "Send Request",
      });
    }
    this.setState({
      currency: this.props.userDetails.currency,
    });
    this.updateDimensions();
    this.getForexData();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      this.filterDataByDate();
    }
  };

  filterDataByDate = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-forex-rate",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.setState({
            allInstitution: res.data.inst_list,
          });
          this.createDataTable(res.data.data);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getSearchedData = async () => {
    this.state.dialogVisible = false;
    this.getTransactionDataSearchValue();
  };

  formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  openAddForexDialog = () => {
    this.state.addForexDialog = true;
  };
  hideAddForexDialog = () => {
    this.state.addForexDialog = false;
  };
  addForexSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.dropdownFromCurrency);
    if ("" == this.state.dropdownInstitutionSelected) {
      this.setState({
        dropdownInstitutionSelectedError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownInstitutionSelectedError: "",
      });
    }
    if ("" == this.state.dropdownFromCurrency) {
      this.setState({
        fromCurrencyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        fromCurrencyError: "",
      });
    }
    if ("" == this.state.dropdownToCurrency) {
      this.setState({
        toCurrencyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        toCurrencyError: "",
      });
    }
    if ("" == this.state.rate) {
      this.setState({
        rateError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        rateError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      from_currency: this.state.dropdownFromCurrency,
      to_currency: this.state.dropdownToCurrency,
      rate: this.state.rate,
      token: this.props.userDetails._id,
      inst_id: this.state.dropdownInstitutionSelected,
      effective_date: this.convertDate(this.state.effectiveDate),
      valid_date: this.convertDate(this.state.validDate),
      country: this.state.selectedCountry
        ? this.state.selectedCountry.code
        : null, // Use the country code from the selected country object
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "add-forex-rate",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getForexData();
          this.hideAddForexDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            dropdownInstitutionSelected: "",
            dropdownFromCurrency: "",
            dropdownToCurrency: "",
            rate: "",
          });
        } else {
          this.hideAddForexDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  getForexData = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      // start_date: startDate,
      // end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-forex-rate",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DATA");
          console.log(res.data);
          this.setState({
            allInstitution: res.data.inst_list,
          });
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    console.log("DATATABLE VALUE");
    console.log(data);
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        from_currency: d["from_currency"],
        to_currency: d["to_currency"],
        rate: d["rate"],
        modified_at: d["modified_at"],
        initiated_by: d["initiated_by_name"],
        approved_by: d["approved_by_name"],
        institution: d["institution_id"]["short_name"],
        country: d["country"],
        effective_date: this.formatDate(d["effective_date"]),
        valid_date: this.formatDate(d["valid_date"]),
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  render() {
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const transactionDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.retryTransactionButton,
          }}
        />
        <Button
          label="Reverse Transaction"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.reversalTransactionButton,
          }}
        />
        <Button
          label="Close"
          className="p-button-success"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.closeTransactionButton,
          }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const addForex = "assets/layout/images/forex_add.png";
    const detailsStyle = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "right",
    };
    const checkHistory = "assets/layout/images/refresh.png";
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };

    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  {/*<div className="card card-w-title">*/}
                  <div>
                    <span className="p-tag custom-heading">Forex</span>
                  </div>
                </div>
              </div>

              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      {/*<span className="detail span-custom-success" style={detailsStyle}>
												add Forex&nbsp;
                                                <img src={addForex} style={{ height: '25px', width: '25px' }} onClick={() => this.openAddForexDialog()} />
                                            </span>*/}
                      <Button
                        icon="pi pi-download"
                        onClick={this.exportCSV}
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          color: "#999",
                          float: "right",
                        }}
                        data-tip="Download Forex List"
                        data-for="toolTip1"
                        data-place="top"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <span
                        className="detail span-custom-success"
                        style={detailsStyle}
                      >
                        <Button
                          icon="pi pi-plus"
                          onClick={() => this.openAddForexDialog()}
                          className="p-button-info"
                          style={{ float: "right" }}
                          data-tip="Add Forex"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                      </span>
                      <ReactTooltip id="toolTip1" />
                    </div>
                  </div>
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "24px",
                      display: this.state.showFiltersWeb,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-3 p-xl-3">
                      <span className="p-float-label">
                        <Calendar
                          id="range"
                          value={this.state.rangeValue}
                          onChange={(e) =>
                            this.setState(
                              { rangeValue: e.value, dateChange: "Y" },
                              () => this.getFilteredDataDate()
                            )
                          }
                          selectionMode="range"
                          readOnlyInput
                          showIcon={true}
                        />
                        <label htmlFor="calendar">DATE RANGE</label>
                      </span>
                    </div>

                    <div className="p-field p-col-6 p-md-3 p-xl-3"></div>

                    <div className="p-col-6 p-md-6 p-xl-6">
                      {/*<span className="detail span-custom-success" style={detailsStyle}>
                                                Add Forex&nbsp;
                                                <img src={addForex} style={{ height: '25px', width: '25px' }} onClick={() => this.openAddForexDialog()} />
                                            </span>*/}
                      <Button
                        icon="pi pi-download"
                        onClick={this.exportCSV}
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          color: "#999",
                          float: "right",
                        }}
                        data-tip="Download Forex List"
                        data-for="toolTip1"
                        data-place="top"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <span
                        className="detail span-custom-success"
                        style={detailsStyle}
                      >
                        <Button
                          icon="pi pi-plus"
                          onClick={() => this.openAddForexDialog()}
                          className="p-button-info"
                          style={{ float: "right" }}
                          data-tip="Add Forex"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                      </span>
                      <ReactTooltip id="toolTip1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        value={this.state.dataTableValue}
                        paginator={true}
                        rows={10}
                        ref={(el) => {
                          this.dt = el;
                        }}
                      >
                        <Column
                          field="institution"
                          header="Institution"
                          body={this.institutionColumnTemplate}
                        />
                        <Column
                          field="from_currency"
                          header="Source Currency"
                          body={this.fromColumnTemplate}
                        />
                        <Column
                          field="to_currency"
                          header="Destination Currency"
                          body={this.toColumnTemplate}
                        />
                        <Column
                          field="rate"
                          header="Rate"
                          body={this.rateColumnTemplate}
                        />
                        <Column field="country" header="Country" />
                        <Column
                          field="effective_date"
                          header="Effective Date"
                        />
                        <Column field="valid_date" header="Valid Date" />
                        <Column
                          field="modified_at"
                          header="Date"
                          body={this.dateColumnTemplate}
                        />
                        <Column
                          field="initiated_by"
                          header="Initiated By"
                          body={this.inititatedByColumnTemplate}
                        />
                        <Column
                          field="approved_by"
                          header="Approved By"
                          body={this.approvedByColumnTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-12 p-xl-12"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.getFilteredDataDate()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                />
                <label htmlFor="calendar">DATE RANGE</label>
              </span>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.addForexDialog}
          style={{ width: "450px" }}
          header="Add Forex"
          modal
          onHide={this.hideAddForexDialog}
        >
          <form onSubmit={this.addForexSubmit}>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        options={this.state.allInstitution}
                        value={this.state.dropdownInstitutionSelected}
                        onChange={(event) =>
                          this.setState({
                            dropdownInstitutionSelected: event.value,
                          })
                        }
                        autoWidth={false}
                        style={{
                          border: this.state.dropdownInstitutionSelectedError,
                        }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Institution
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        options={this.state.currency}
                        value={this.state.dropdownFromCurrency}
                        onChange={(event) =>
                          this.setState({
                            dropdownFromCurrency: event.target.value,
                          })
                        }
                        autoWidth={false}
                        style={{ border: this.state.fromCurrencyError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Source Currency
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        options={this.state.currency}
                        value={this.state.dropdownToCurrency}
                        onChange={(event) =>
                          this.setState({
                            dropdownToCurrency: event.target.value,
                          })
                        }
                        autoWidth={false}
                        style={{ border: this.state.toCurrencyError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Destination Currency
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Calendar
                        id="effectiveDate"
                        value={this.state.effectiveDate}
                        onChange={(e) =>
                          this.setState({ effectiveDate: e.value })
                        }
                        showIcon={true}
                      />
                      <label htmlFor="effectiveDate" style={labelStyle}>
                        Effective Date
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Calendar
                        id="validDate"
                        value={this.state.validDate}
                        onChange={(e) => this.setState({ validDate: e.value })}
                        showIcon={true}
                      />
                      <label htmlFor="validDate" style={labelStyle}>
                        Valid Date
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        options={this.state.countries}
                        optionLabel="name"
                        value={this.state.selectedCountry}
                        onChange={(e) =>
                          this.setState({ selectedCountry: e.value })
                        }
                        autoWidth={false}
                      />
                      <label htmlFor="country" style={labelStyle}>
                        Country
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        type="number"
                        onChange={(e) =>
                          this.setState({ rate: e.target.value })
                        }
                        value={this.state.rate}
                        style={{ border: this.state.rateError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Rate
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label={this.state.addForexButtonText}
                  className="p-button-info"
                  type="submit"
                  style={{ width: "50%", marginTop: "1.5em" }}
                />
                {/*<Button label="Update" icon="pi pi-check" autoFocus />*/}
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(Forex);
