import React, { Component, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactTooltip from "react-tooltip";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import "../../config";
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
const floatLabel = {
  fontWeight: "500",
  color: "rgb(153, 153, 153)",
  fontSize: "12px",
};
class Countries extends Component {
  constructor() {
    super();
    document.title = "Countries - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      country: [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Antigua & Deps", value: "Antigua" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia Herzegovina", value: "Bosnia Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina", value: "Burkina" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Cape Verde", value: "Cape Verde" },
        { label: "Central African Rep", value: "Central Africa Rep" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Congo", value: "Congo" },
        { label: "Congo {Democratic Rep}", value: "Congo {Democratic Rep}" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "East Timor", value: "East Timor" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Great Britain", value: "Great Britain" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland {Republic}", value: "Ireland {Republic}" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Ivory Coast", value: "Ivory Coast" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Korea North", value: "Korea North" },
        { label: "Korea South", value: "Kore South" },
        { label: "Kosovo", value: "Kosovo" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Macedonia", value: "Macedonia" },
        { label: "Madagascar", value: "Madagascar" },
        { label: "Malawi", value: "Malawi" },
        { label: "Malaysia", value: "Malaysia" },
        { label: "Maldives", value: "Maldives" },
        { label: "Mali", value: "Mali" },
        { label: "Malta", value: "Malta" },
        { label: "Marshall Islands", value: "Marshall Islands" },
        { label: "Mauritania", value: "Mauritania" },
        { label: "Mauritius", value: "Mauritius" },
        { label: "Mexico", value: "Mexico" },
        { label: "Micronesia", value: "Micronesia" },
        { label: "Moldova", value: "Moldova" },
        { label: "Monaco", value: "Monaco" },
        { label: "Mongolia", value: "Mongolia" },
        { label: "Montenegro", value: "Montenegro" },
        { label: "Morocco", value: "Morocco" },
        { label: "Mozambique", value: "Mozambique" },
        { label: "Myanmar, {Burma}", value: "Myanmar, {Burma}" },
        { label: "Namibia", value: "Namibia" },
        { label: "Nauru", value: "Nauru" },
        { label: "Nepal", value: "Nepal" },
        { label: "Netherlands", value: "Netherlands" },
        { label: "New Zealand", value: "New Zealand" },
        { label: "Nicaragua", value: "Nicaragua" },
        { label: "Niger", value: "Niger" },
        { label: "Nigeria", value: "Nigeria" },
        { label: "Norway", value: "Norway" },
        { label: "Oman", value: "Oman" },
        { label: "Pakistan", value: "Pakistan" },
        { label: "Palau", value: "Palau" },
        { label: "Panama", value: "Panama" },
        { label: "Papua New Guinea", value: "Papua New Guinea" },
        { label: "Paraguay", value: "Paraguay" },
        { label: "Peru", value: "Peru" },
        { label: "Philippines", value: "Philippines" },
        { label: "Poland", value: "Poland" },
        { label: "Portugal", value: "Portugal" },
        { label: "Qatar", value: "Qatar" },
        { label: "Romania", value: "Romania" },
        { label: "Russian Federation", value: "Russian Federation" },
        { label: "Rwanda", value: "Rwanda" },
        { label: "St Kitts & Nevis", value: "St Kitts & Nevis" },
        { label: "St Lucia", value: "St Lucia" },
        {
          label: "Saint Vincent & the Grenadines",
          value: "Saint Vincent & the Grenadines",
        },
        { label: "Samoa", value: "Samoa" },
        { label: "San Marino", value: "San Marino" },
        { label: "Sao Tome & Principe", value: "Sao Tome" },
        { label: "Saudi Arabia", value: "Saudi Arabia" },
        { label: "Senegal", value: "Senegal" },
        { label: "Serbia", value: "Serbia" },
        { label: "Seychelles", value: "Seychelles" },
        { label: "Sierra Leone", value: "Sierra Leone" },
        { label: "Singapore", value: "Singapore" },
        { label: "Slovakia", value: "Slovakia" },
        { label: "Slovenia", value: "Slovenia" },
        { label: "Solomon Islands", value: "Solomon Islands" },
        { label: "Somalia", value: "Somalia" },
        { label: "South Africa", value: "South Africa" },
        { label: "South Sudan", value: "South Sudan" },
        { label: "Spain", value: "Spain" },
        { label: "Sri Lanka", value: "Sri Lanka" },
        { label: "Sudan", value: "Sudan" },
        { label: "Suriname", value: "Suriname" },
        { label: "Swaziland", value: "Swaziland" },
        { label: "Sweden", value: "Sweden" },
        { label: "Switzerland", value: "Switzerland" },
        { label: "Syria", value: "Syria" },
        { label: "Taiwan", value: "Taiwan" },
        { label: "Tajikistan", value: "Tajikistan" },
        { label: "Tanzania", value: "Tanzania" },
        { label: "Thailand", value: "Thailand" },
        { label: "Togo", value: "Togo" },
        { label: "Tonga", value: "Tonga" },
        { label: "Trinidad & Tobago", value: "Trinidad & Tobago" },
        { label: "Tunisia", value: "Tunisia" },
        { label: "Turkey", value: "Turkey" },
        { label: "Turkmenistan", value: "Turkmenistan" },
        { label: "Tuvalu", value: "Tuvalu" },
        { label: "Uganda", value: "Uganda" },
        { label: "Ukraine", value: "Ukraine" },
        { label: "United Arab Emirates", value: "United Arab Emirates" },
        { label: "United Kingdom", value: "United Kingdom" },
        { label: "United States", value: "United States" },
        { label: "Uruguay", value: "Uruguay" },
        { label: "Uzbekistan", value: "Uzbekistan" },
        { label: "Vanuatu", value: "Vanuatu" },
        { label: "Vatican City", value: "Vatican City" },
        { label: "Venezuela", value: "Venezuela" },
        { label: "Vietnam", value: "Vietnam" },
        { label: "Yemen", value: "Yemen" },
        { label: "Zambia", value: "Zambia" },
        { label: "Zimbabwe", value: "Zimbabwe" },
      ],
      status: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ],
      selectedCities1: [],
      cities: [],
      network: "",
      networkError: "",
      dropdownNetwork: "",
      dialogShortNameError: "",
      dialogRegionError: "",
      dialogSubRegionError: "",
      dialogSubRegionError: "",
      dropdownStatus: "",
      shortName: "",
      dropdownCountry: "",
      countryError: "",
      currency: "",
      currencyError: "",
      currencyError: "",
      shortNameError: "",
      region: "",
      regionError: "",
      subRegion: "",
      subRegionError: "",
      dialogCountryName: "",
      dialogCurrency: "",
      dialogSubRegion: "",
      dialogRegion: "",

      dialogShortName: "",

      countryId: "",

      showFiltersWeb: "none",
      showFiltersMob: "none",
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      showLoader: "none",
      contentOpacity: "1",
      rangeValue: [],
      countryID: "",
      providerError: "",
      searchFilter: [
        { label: "Country", value: "name" },
        { label: "Region", value: "region" },
        { label: "Currency", value: "currency" },
      ],
      dropdownSearchFilter: "",
      searchValue: "",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.editCountryDialog = this.editCountryDialog.bind(this);
    this.countryColumnTemplate = this.countryColumnTemplate.bind(this);
    this.shortNameColumnTemplate = this.shortNameColumnTemplate.bind(this);
    this.subRegionColumnTemplate = this.subRegionColumnTemplate.bind(this);
    this.currencyColumnTemplate = this.currencyColumnTemplate.bind(this);
    this.currencySymbolColumnTemplate =
      this.currencySymbolColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.networkColumnTemplate = this.networkColumnTemplate.bind(this);
    this.regionColumnTemplate = this.regionColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }

  componentDidMount() {
    this.updateDimensions();
    this.getCountryList();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  updateCountry = (event) => {
    event.preventDefault();
    console.log(this.state.countryId);
    {
      /*if('' == this.state.dialogShortName)
		{
			this.setState({
				dialogShortNameError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				dialogShortNameError:'',
			});
		}*/
    }
    console.log(this.state.dialogRegion);
    if ("" == this.state.dialogRegion) {
      this.setState({
        dialogRegionError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogRegionError: "",
      });
    }
    console.log(this.state.dialogSubRegion);
    if ("" == this.state.dialogSubRegion) {
      this.setState({
        dialogSubRegionError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogSubRegionError: "",
      });
    }
    console.log(this.state.dialogSubRegion);
    if ("" == this.state.dialogSubRegion) {
      this.setState({
        dialogSubRegionError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogSubRegionError: "",
      });
    }
    if ("" == this.state.dialogCurrency) {
      this.setState({
        dialogCurrencyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogCurrencyError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      country_id: this.state.countryId,
      short_name: this.state.dialogShortName,
      region: this.state.dialogRegion,
      sub_region: this.state.dialogSubRegion,
      currency: this.state.dialogCurrency,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "country-update",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideEditCountryDialog();
          this.toastSuccess(res.data.msg);
          console.log(res.data);
          this.getCountryList();
        } else {
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };
  getCountryNetwork = async () => {
    console.log(this.state.dropdownCountry);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      country: this.state.dropdownCountry,
    };
    axios
      .post(
        global.config.main.baseUrl + "network/country/list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          if ("" != res.data.data) {
            this.setState({
              network: res.data.data,
              cities: res.data.data,
              selectedCities1: res.data.data_sel,
            });
          }
          {
            /*else
					{
						this.setState({
							network: [{label:"No Network",value:null}]
						});
					}*/
          }
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    else this.state.dialogVisible = false;
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "country-list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.createDataTable(res.data.data);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getCountryList = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      //start_date: startDate,
      //end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "country-list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          console.log("ALL");
          console.log(res.data);
          {
            /*this.setState({
						country : res.data.country
					});*/
          }
          this.createDataTable(res.data.data);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    console.log("DATATABLE VALUE");
    console.log(data);
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        currency: d["currency"],
        id: d["id"],
        name: d["name"],
        region: d["region"],
        short_name: d["short_name"],
        status: d["status"],
        sub_region: d["sub_region"],
        modified_at: d["modified_at"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  setSelectedCities1(data) {
    this.setState({
      selectedCities1: data,
    });
    console.log(this.state.selectedCities1);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  countryColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Country</span>
        <span className={pTag}>{rowData.name}</span>
      </React.Fragment>
    );
  }
  networkColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Provider</span>
        <span>{rowData.network}</span>
      </React.Fragment>
    );
  }

  shortNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Short Name</span>
        {rowData.short_name}
      </React.Fragment>
    );
  }

  regionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Region</span>
        {rowData.region}
      </React.Fragment>
    );
  }

  subRegionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Sub Region</span>
        {rowData.sub_region}
      </React.Fragment>
    );
  }

  currencyColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Currency</span>
        {rowData.currency}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        {rowData.modified_at}
      </React.Fragment>
    );
  }

  currencySymbolColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    let currencySymbol = "";
    if ("INR" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else if ("GHC" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else if ("NI" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else {
      currencySymbol = "&#x20B9;";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Currency Symbol</span>
        <span style={{ width: "40%" }} className={pTag}>
          {rowData.currency}
        </span>
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let ptag = rowData.status;
    let pTag = "";
    let status = "";
    if (ptag == "1") {
      status = "ACTIVE";
      pTag = "p-tag p-tag-success";
    } else {
      status = "IN-ACTIVE";
      pTag = "p-tag p-tag-danger";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-pencil"
          className="p-button-info p-mr-2"
          onClick={() => this.editCountryDialog(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="Edit Country"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <Button
          label=""
          icon="pi pi-plus"
          className="p-button-info p-mr-2"
          onClick={() => this.addProviderDialog(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="Assign Provider"
          data-for="toolTip1"
          data-place="top"
        />
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }
  addProvider = (event) => {
    event.preventDefault();
    if ("" == this.state.selectedCities1) {
      this.setState({
        providerError: "solid 1.5px red",
      });
      return false;
    } else {
      this.setState({
        providerError: "",
      });
    }
    console.log(this.state.selectedCities1);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      country_id: this.state.countryID,
      provider: this.state.selectedCities1,
    };
    axios
      .post(
        global.config.main.baseUrl + "country/assign-provider",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.toastSuccess(res.data.msg);
          this.hideAddProviderDialog();
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.toastError(res.data.msg);
          this.hideAddProviderDialog();
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };
  addProviderDialog(rowData) {
    console.log(rowData);
    this.setState(
      {
        countryID: rowData.id,
        dropdownCountry: rowData.name,
      },
      () => this.getCountryNetwork()
    );
    this.setState({
      addProviderDialog: true,
    });
  }
  hideAddProviderDialog = () => {
    this.setState({
      addProviderDialog: false,
    });
  };
  editCountryDialog(rowData) {
    console.log(rowData);
    if (0 == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-pending",
        dialogStatusText: "Inactive",
        dropdownStatus: "0",
      });
    } else if (1 == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
        dropdownStatus: "1",
      });
    }
    this.setState({
      dialogCountryName: rowData.name,
      dialogCurrency: rowData.currency,
      dialogShortName: rowData.short_name,
      dialogRegion: rowData.region,
      dialogSubRegion: rowData.sub_region,
      countryId: rowData.id,
      editCountryDialog: true,
    });
  }

  hideEditCountryDialog = () => {
    this.setState({ editCountryDialog: false });
  };

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getCountryListByDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        start_date: startDate,
        end_date: endDate,
        token: this.props.userDetails._id,
      };
      axios
        .post(
          global.config.main.baseUrl + "country-list",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
            this.createDataTable(res.data.data);
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };

  getSearchedData = async () => {
    this.state.dialogVisible = false;
    this.getTransactionDataSearchValue();
  };

  openAddForexDialog = () => {
    this.state.addCountryDialog = true;
  };

  hideAddForexDialog = () => {
    this.setState({
      dropdownCountry: "",
      currency: "",
      shortName: "",
      region: "",
      subRegion: "",
    });
    this.state.addCountryDialog = false;
  };

  addCountry = (event) => {
    event.preventDefault();
    if ("" == this.state.dropdownCountry) {
      this.setState({
        countryError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        countryError: "",
      });
    }
    {
      /*if ('' == this.state.dropdownNetwork) {
            this.setState({
                networkError: "1.5px solid red",
            });
            return false;
        }
        else {
            this.setState({
                networkError: "",
            });
        }*/
    }
    if ("" == this.state.currency) {
      this.setState({
        currencyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        currencyError: "",
      });
    }
    if ("" == this.state.shortName) {
      this.setState({
        shortNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        shortNameError: "",
      });
    }
    if ("" == this.state.region) {
      this.setState({
        regionError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        regionError: "",
      });
    }
    if ("" == this.state.subRegion) {
      this.setState({
        subRegionError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        subRegionError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      name: this.state.dropdownCountry,
      short_name: this.state.shortName,
      region: this.state.region,
      sub_region: this.state.subRegion,
      currency: this.state.currency,
      network: 1,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "add-country",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getCountryList();
          this.hideAddForexDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            dropdownCountry: "",
            shortName: "",
            region: "",
            subRegion: "",
            currency: "",
          });
        } else {
          this.hideAddForexDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  render() {
    const addForex = "assets/layout/images/forex_add.png";
    const detailsStyle = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "right",
    };
    const detailsStyle1 = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "left",
    };
    const checkHistory = "assets/layout/images/refresh.png";
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };

    return (
      <div>
        <div style={{ opacity: this.state.contentOpacity }}></div>
        <div
          className="layout-content"
          style={{ opacity: this.state.contentOpacity }}
        >
          <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
          <ProgressSpinner
            style={{
              display: this.state.showLoader,
              width: "40px",
              height: "40px",
              position: "fixed",
              top: "50%",
              left: "50%",
              bottom: "50%",
              right: "50%",
              zIndex: "1111111111",
            }}
            strokeWidth="3"
            animationDuration=".5s"
          />
          <div>
            <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
              <div className="p-col-12 p-md-12 p-xl-12">
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    {/*<div className="card card-w-title">*/}
                    <div>
                      <span className="p-tag custom-heading">Country</span>
                    </div>
                  </div>
                </div>

                <div className="p-grid transaction">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "2px",
                        display: this.state.showFiltersMob,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          icon="pi pi-sliders-h"
                          label="Filters"
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            border: "1px solid #f0f0f0",
                            color: "#999",
                            float: "left",
                            width: "75%",
                          }}
                          onClick={() => this.setState({ dialogVisible: true })}
                        />
                      </div>
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        {/*<span className="detail span-custom-success" style={detailsStyle}>
                                                Add Country
                                          &nbsp;
                                                <img src={addForex} style={{ height: '25px', width: '25px' }} onClick={() => this.openAddForexDialog()} />
                                            </span>*/}
                        <Button
                          icon="pi pi-download"
                          onClick={this.exportCSV}
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#999",
                            float: "right",
                          }}
                          data-tip="Download Country List"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.openAddForexDialog()}
                            className="p-button-info"
                            style={{ float: "right" }}
                            data-tip="Add Country"
                            data-for="toolTip1"
                            data-place="top"
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "24px",
                        display: this.state.showFiltersWeb,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-3 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            id="range"
                            value={this.state.rangeValue}
                            onChange={(e) =>
                              this.setState(
                                { rangeValue: e.value, dateChange: "Y" },
                                () => this.getCountryListByDate()
                              )
                            }
                            selectionMode="range"
                            readOnlyInput
                            showIcon={true}
                          />
                          <label htmlFor="calendar">DATE RANGE</label>
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4 p-xl-4 p-xl-offset-3">
                        <div className="p-grid">
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingRight: "2px" }}
                          >
                            <div className="p-inputgroup">
                              <span className="p-float-label">
                                <Dropdown
                                  style={{ width: "20%" }}
                                  options={this.state.searchFilter}
                                  value={this.state.dropdownSearchFilter}
                                  onChange={(event) =>
                                    this.setState({
                                      dropdownSearchFilter: event.value,
                                    })
                                  }
                                  autoWidth={false}
                                />
                                <label style={floatLabel}>Select Filter</label>
                              </span>
                            </div>
                          </div>
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingLeft: "0.4px" }}
                          >
                            <div className="p-inputgroup">
                              <InputText
                                value={this.state.searchValue}
                                onChange={this.handleSearchValueChange}
                                placeholder="Search Value"
                              />
                              <Button
                                onClick={() => this.getData()}
                                icon="pi pi-search"
                                className="p-button-info"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="p-col-4 p-md-2 p-xl-2">
                        {/*<span className="detail span-custom-success" style={detailsStyle}>
                                                Add Country
                                          		&nbsp;
                                                <img src={addForex} style={{ height: '25px', width: '25px' }} onClick={() => this.openAddForexDialog()} />
                                            </span>*/}
                        <Button
                          icon="pi pi-download"
                          onClick={this.exportCSV}
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#999",
                            border: "1px solid #f0f0f0",
                          }}
                          data-tip="Download Country List"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle1}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.openAddForexDialog()}
                            className="p-button-info"
                            style={{
                              float: "left",
                              border: "1px solid rgb(92, 74, 181)",
                            }}
                            data-tip="Add Country"
                            data-for="toolTip1"
                            data-place="top"
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <ReactTooltip id="toolTip1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ padding: "0" }}
                  >
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <DataTable
                          className="p-datatable-responsive-demo"
                          resizableColumns
                          columnResizeMode="expand"
                          value={this.state.dataTableValue}
                          paginator={true}
                          rows={10}
                          ref={(el) => {
                            this.dt = el;
                          }}
                        >
                          <Column
                            field="currency"
                            header="Currency Symbol"
                            body={this.currencySymbolColumnTemplate}
                            style={{ display: "none" }}
                          />
                          <Column
                            field="name"
                            header="Country"
                            body={this.countryColumnTemplate}
                          />
                          <Column
                            field="network"
                            header="Provider"
                            body={this.networkColumnTemplate}
                            style={{ display: "none" }}
                          />
                          <Column
                            field="short_name"
                            header="Short Name"
                            body={this.shortNameColumnTemplate}
                          />
                          <Column
                            field="region"
                            header="Region"
                            body={this.regionColumnTemplate}
                          />
                          <Column
                            field="sub_region"
                            header="Sub Region"
                            body={this.subRegionColumnTemplate}
                          />
                          <Column
                            field="currency"
                            header="Currency"
                            body={this.currencyColumnTemplate}
                          />
                          <Column
                            field="modified_at"
                            header="Date"
                            body={this.dateColumnTemplate}
                          />
                          <Column
                            header="Action"
                            body={this.actionColumnTemplate}
                          />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            header="Filters"
            visible={this.state.dialogVisible}
            onHide={() => this.setState({ dialogVisible: false })}
          >
            <div className="p-grid">
              <div
                className="p-field p-col-12 p-md-8 p-md-offset-2"
                style={{ marginTop: "1.5em" }}
              >
                <span className="p-float-label">
                  <Calendar
                    id="range"
                    value={this.state.rangeValue}
                    onChange={(e) =>
                      this.setState(
                        { rangeValue: e.value, dateChange: "Y" },
                        () => this.getCountryListByDate()
                      )
                    }
                    selectionMode="range"
                    readOnlyInput
                    showIcon={true}
                    style={{ width: "100%" }}
                  />
                  <label htmlFor="calendar">DATE RANGE</label>
                </span>
              </div>
              <div
                className="p-field p-col-12 p-md-8 p-md-offset-2"
                style={{ marginTop: "1em" }}
              >
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <Dropdown
                      style={{ width: "20%" }}
                      options={this.state.searchFilter}
                      value={this.state.dropdownSearchFilter}
                      onChange={(event) =>
                        this.setState({ dropdownSearchFilter: event.value })
                      }
                      autoWidth={false}
                    />
                    <label style={floatLabel}>Select Filter</label>
                  </span>
                </div>
              </div>
              <div className="p-field p-col-12 p-md-8 p-md-offset-2">
                <div className="p-inputgroup">
                  <InputText
                    value={this.state.searchValue}
                    onChange={this.handleSearchValueChange}
                    placeholder="Search Value"
                  />
                </div>
              </div>
              <div
                className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Apply"
                  onClick={() => this.getData()}
                  icon="pi pi-search-plus"
                  style={{ width: "50%" }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={this.state.addCountryDialog}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Add Country"
            modal
            onHide={this.hideAddForexDialog}
          >
            <form onSubmit={this.addCountry}>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        {/*<Dropdown options={this.state.country} value={this.state.dropdownCountry} onChange={event => this.setState({ dropdownCountry: event.target.value }, () => this.getCountryNetwork())} autoWidth={false} style={{ border: this.state.countryError }} />*/}
                        <Dropdown
                          options={this.state.country}
                          value={this.state.dropdownCountry}
                          onChange={(event) =>
                            this.setState({
                              dropdownCountry: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{ border: this.state.countryError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Country
                        </label>
                      </span>
                    </div>
                    {/*<div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <Dropdown options={this.state.network} value={this.state.dropdownNetwork} onChange={event => this.setState({ dropdownNetwork: event.target.value })} autoWidth={false} style={{ border: this.state.networkError }} />
                                            <label htmlFor="dropdown1" style={labelStyle}>Provider</label>
                                        </span>
                                    </div>*/}
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ currency: e.target.value })
                          }
                          value={this.state.currency}
                          style={{ border: this.state.currencyError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Currency
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ shortName: e.target.value })
                          }
                          value={this.state.shortName}
                          style={{ border: this.state.shortNameError }}
                        />
                        <label style={labelStyle}>Short Name</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ region: e.target.value })
                          }
                          value={this.state.region}
                          style={{ border: this.state.regionError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Region
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ subRegion: e.target.value })
                          }
                          value={this.state.subRegion}
                          style={{ border: this.state.subRegionError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Sub Region
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Add"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.addProviderDialog}
            style={{ width: "450px", overflowY: "visible" }}
            header="Assign Provider"
            modal
            onHide={this.hideAddProviderDialog}
          >
            <form onSubmit={this.addProvider}>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dropdownCountry}
                          style={{
                            color: "rgb(140, 140, 140)",
                            backgroundColor: "rgb(212, 212, 212)",
                          }}
                          readOnly
                        />
                        <label style={labelStyle}>Country</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        {/*<Dropdown options={this.state.network} value={this.state.dropdownNetwork} onChange={event => this.setState({ dropdownNetwork: event.target.value })} autoWidth={false} style={{ border: this.state.networkError }} />*/}
                        {/*<MultiSelect value={this.state.selectedCities1} options={this.state.cities} onChange={(e) => this.setSelectedCities1(e.value)} optionLabel="name" placeholder="Select a City" />*/}
                        <MultiSelect
                          value={this.state.selectedCities1}
                          options={this.state.cities}
                          onChange={(e) => this.setSelectedCities1(e.value)}
                          optionLabel="label"
                          display="chip"
                          style={{ border: this.state.providerError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Provider
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Add"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>

          <Dialog
            visible={this.state.editCountryDialog}
            style={{ width: "450px" }}
            header="Edit Country"
            modal
            onHide={this.hideEditCountryDialog}
          >
            {/*<span className={this.state.dialogStatus}>{this.state.dialogStatusText}</span>*/}
            <form onSubmit={this.updateCountry}>
              <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dialogCountryName}
                          style={{
                            color: "rgb(140, 140, 140)",
                            backgroundColor: "rgb(212, 212, 212)",
                          }}
                          readOnly
                        />
                        <label style={labelStyle}>Country</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dialogShortName}
                          style={{
                            color: "rgb(140, 140, 140)",
                            backgroundColor: "rgb(212, 212, 212)",
                          }}
                          readOnly
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Short Name
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dialogRegion}
                          onChange={(e) =>
                            this.setState({ dialogRegion: e.target.value })
                          }
                          style={{ border: this.state.dialogRegionError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Region
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dialogSubRegion}
                          onChange={(e) =>
                            this.setState({ dialogSubRegion: e.target.value })
                          }
                          style={{ border: this.state.dialogSubRegionError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Sub Region
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dialogCurrency}
                          onChange={(e) =>
                            this.setState({ dialogCurrency: e.target.value })
                          }
                          style={{ border: this.state.dialogCurrencyError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Currency
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Update"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(Countries);
