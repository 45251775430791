import React, { Component } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactTooltip from "react-tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../config";
//import Cookies from 'universal-cookie';
import axios from "axios";
import { connect } from "react-redux";
import queryString from "query-string";
const qs = require("query-string");
//const cookies = new Cookies();

class Admin extends Component {
  constructor() {
    super();
    document.title = "Users - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      dropdownEditStatus: "",
      user_type: "",
      searchValue: "",
      dialogAdminName: "",
      dialogAdminNumber: "",
      dropdownError: "",
      phone: "",
      phoneError: "",
      nameError: "",
      firstName: "",
      firstNameError: "",
      lastNameError: "",
      userNameError: "",
      shortNameError: "",
      emailError: "",
      dropdownStatus: "All",
      dateChanged: "N",
      statusChanged: "N",
      msgBlockForEditAdmin: "none",
      dialogUserName: "",
      dialogName: "",
      dialogCreatedOn: "",
      dialogEmail: "",
      dialogPhoneNumber: "",
      dialogShortName: "",
      dialogStatus: "",
      dialogUserId: "",
      dialogStatusText: "",
      dateFilterValue: "",

      dialogVisible: false,
      transactionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      submitted: false,
      addAdminDialog: false,
      dropdownCity: "Ghana",
      name: "",
      lastName: "",
      fullName: "",
      userName: "",
      shortName: "",
      email: "",
      dropdowncountries: "Filter Filter",
      date2: null,
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      selectedFile: null,
      selectedFiles: null,
      documents: [],
      documentsSelection: null,
      fullCalendarEvents: [],
      layout: "list",
      dataTableDummyValue: "",
      viewDetailDialog: false,
      status: [
        { label: "All", value: "All" },
        { label: "Active", value: 1 },
        { label: "Inactive", value: 0 },
      ],
      userType: [
        { label: "Select User Type", value: "" },
        { label: "Admin", value: "0" },
        { label: "User", value: "1" },
      ],
      dropdownuserType: "",
      dialogStatusNew: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ],
      searchFilter: [
        { label: "Name", value: "name" },
        { label: "Phone", value: "phone" },
      ],
      rangeValue: [],
      showLoader: "none",
      contentOpacity: "1",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

    this.nameColumnTemplate = this.nameColumnTemplate.bind(this);
    this.userNameColumnTemplate = this.userNameColumnTemplate.bind(this);
    this.phoneColumnTemplate = this.phoneColumnTemplate.bind(this);
    //this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);

    this.openNew = this.openNew.bind(this);
  }

  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  nameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        {rowData.name}
      </React.Fragment>
    );
  }

  userNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Username</span>
        {rowData.user_name}
      </React.Fragment>
    );
  }

  phoneColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Phone</span>
        {rowData.phone_number}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        {rowData.date}
      </React.Fragment>
    );
  }

  statusBodyTemplate(rowData) {
    let ptag = rowData.status;
    let pTag = "";
    if (ptag == "Active") {
      pTag = "p-tag p-tag-success";
    } else {
      pTag = "p-tag p-tag-danger";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{rowData.status}</span>
      </React.Fragment>
    );
    //return <span className={pTag}>{rowData.status}</span>;
  }

  actionBodyTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-eye"
          className="p-button-success p-mr-2"
          onClick={() => this.openViewDialog(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="View Details"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <ReactTooltip id="toolTip1" />
        <Button
          label=""
          icon="pi pi-user-edit"
          className="p-button-warning p-mr-2"
          onClick={() => this.openEditDialog(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
        />
      </React.Fragment>
    );
  }

  openViewDialog = (rowData) => {
    console.log(rowData);
    let user_type = rowData.user_type;
    if ("0" == user_type) {
      user_type = "Admin";
    } else if ("1" == user_type) {
      user_type = "User";
    } else if ("101" == user_type) {
      user_type = "Finance Admin";
    } else if ("102" == user_type) {
      user_type = "Finance User";
    } else if ("201" == user_type) {
      user_type = "Help Desk Admin";
    } else if ("202" == user_type) {
      user_type = "Help Desk User";
    }
    if ("Inactive" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "Inactive",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
      });
    }
    this.setState({
      dialogUserName: rowData.user_name,
      dialogName: rowData.name,
      dialogCreatedOn: rowData.date,
      dialogEmail: rowData.email,
      dialogPhoneNumber: rowData.phone_number,
      dialogShortName: rowData.short_name,
      dialogUserId: rowData.user_id,
      user_type: user_type,
      viewDetailDialog: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  openEditDialog = (rowData) => {
    console.log(rowData);
    if ("Inactive" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "Inactive",
        dropdownEditStatus: "0",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
        dropdownEditStatus: "1",
      });
    }
    this.setState({
      dialogUserName: rowData.user_name,
      dialogName: rowData.name,
      dialogCreatedOn: rowData.date,
      dialogEmail: rowData.email,
      dialogPhoneNumber: rowData.phone_number,
      dialogShortName: rowData.short_name,
      dialogUserId: rowData.user_id,
      editDialog: true,
    });
  };

  OpenAddAdminDialog = () => {
    this.setState({
      addAdminDialog: true,
    });
  };

  hideAddAdminDialog = () => {
    this.setState({
      msgBlock: "block",
      name: "",
      lastName: "",
      fullName: "",
      userName: "",
      shortName: "",
      email: "",
      addAdminDialog: false,
    });
  };

  editUserDetails = (event) => {
    event.preventDefault();

    if ("" == this.state.dialogName) {
      this.setState({
        dialogAdminName: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogAdminName: "",
      });
    }
    if ("" == this.state.dialogPhoneNumber) {
      this.setState({
        dialogAdminNumber: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogAdminNumber: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      entry_id: this.state.dialogUserId,
      name: this.state.dialogName,
      phone: this.state.dialogPhoneNumber,
      status: this.state.dropdownEditStatus,
      token: this.props.userDetails._id,
    };

    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "edit-users-details",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideEditDialog();
          this.toastSuccess(res.data.msg);
          this.state.dataTableValue = [];
          this.getAllUsers();
        } else {
          this.hideEditDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  HideViewDetailDialog = () => {
    this.setState({ viewDetailDialog: false });
  };

  hideEditDialog = () => {
    this.setState({ editDialog: false });
  };

  componentDidMount() {
    if ("1" == this.props.userDetails.type) {
      this.setState({
        userType: [
          { label: "Finance Admin", value: "101" },
          { label: "Finance User", value: "102" },
          { label: "Help Desk Admin", value: "201" },
          { label: "Help Desk User", value: "202" },
        ],
      });
    } else {
      this.setState({
        userType: [
          { label: "Admin User", value: "1" },
          { label: "Finance Admin", value: "101" },
          { label: "Finance User", value: "102" },
          { label: "Help Desk Admin", value: "201" },
          { label: "Help Desk User", value: "202" },
        ],
      });
    }
    this.UpdateDimensions();
    this.getAllUsers();
    window.addEventListener("resize", this.UpdateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.UpdateDimensions.bind(this));
  }

  UpdateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  HideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        start_date: startDate,
        end_date: endDate,
        token: this.props.userDetails._id,
      };
      axios
        .post(
          global.config.main.baseUrl + "list-mngt-user",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data);
            this.state.dataTableValue = [];
            this.createDataTable(res.data.data);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };
  getSearchedData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    console.log(this.state.dropdownSearchFilter);
    console.log(this.state.searchValue);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-mngt-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.state.dataTableValue = [];
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  GetFilteredStatus = async () => {
    this.setState({
      dialogVisible: false,
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      status: this.state.dropdownStatus,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-mngt-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.state.dataTableValue = [];
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getAllUsers = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-mngt-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.state.dataTableValue = [];
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      let status1 = "Inactive";
      if (d["status"] == true) status1 = "Active";
      this.state.dataTableValue.push({
        name: d["name"],
        user_name: d["short_name"],
        phone_number: d["phone"],
        email: d["email"],
        status: status1,
        date: d["created_at"],
        short_name: d["short_name"],
        user_id: d["user_id"],
        user_type: d["user_type"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  GetFilteredStatus() {
    this.state.dialogVisible = false;
    if ("Y" == this.state.dateChanged) {
      if ("" == this.state.dropdownStatus) {
        this.getAllUsers();
      } else {
        this.GetFilteredDateSD();
      }
    } else {
      if ("" == this.state.dropdownStatus) {
        this.getAllUsers();
      } else {
        this.GetFilteredDateS();
      }
    }
  }

  GetFilteredDateS() {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      status: this.state.dropdownStatus,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-inst-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.state.dataTableValue = [];
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  }

  GetFilteredDate() {
    this.state.dialogVisible = false;
    if ("Y" == this.state.statusChanged) {
      if ("" == this.state.dropdownStatus) {
        this.GetFilteredDateD();
      } else {
        this.GetFilteredDateSD();
      }
    } else {
      if ("" == this.state.dropdownStatus) {
        this.GetFilteredDateD();
      } else {
        this.GetFilteredDateSD();
      }
    }
  }

  GetFilteredDateSD() {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      status: this.state.dropdownStatus,
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-inst-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.state.dataTableValue = [];
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  }

  GetFilteredDateD() {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-inst-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.state.dataTableValue = [];
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  }

  handleAddAdminSubmit = async (event) => {
    event.preventDefault();
    if ("" == this.state.firstName) {
      this.setState({
        firstNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        firstNameError: "",
      });
    }
    if ("" == this.state.lastName) {
      this.setState({
        lastNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        lastNameError: "",
      });
    }
    let full_name = this.state.firstName + " " + this.state.lastName;
    console.log(full_name);
    if ("" == this.state.userName) {
      this.setState({
        userNameError: "1.5px solid red",
      });
      return false;
    } else {
      {
        /*if (/\s/.test(this.state.userName))*/
      }
      if (this.state.userName.indexOf(" ") >= 0) {
        this.toastError("Space in not allowed in Username");
        return false;
      } else {
        this.setState({
          userNameError: "",
        });
      }
    }
    if ("" == this.state.phone) {
      this.setState({
        phoneError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        phoneError: "",
      });
    }
    if ("" == this.state.email) {
      this.setState({
        emailError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        emailError: "",
      });
    }
    if ("" == this.state.dropdownuserType) {
      this.setState({
        dropdownError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      email: this.state.email,
      name: full_name,
      phone: this.state.phone,
      user_name: this.state.userName,
      short_name: this.state.userName,
      user_type: this.state.dropdownuserType,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "register-users",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.state.dataTableValue = [];
          this.getAllUsers();
          this.hideAddAdminDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            email: "",
            fullName: "",
            userName: "",
            shortName: "",
            dropdownuserType: "",
          });
        } else {
          {
            /*this.hideAddAdminDialog();*/
          }
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.errormsg.show([
            {
              severity: "error",
              summary: "",
              detail: "A network error occured",
              sticky: true,
            },
          ]);
          this.setState({
            msgBlock: "block",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  render() {
    const dialogFooter = (
      <Button
        label="Apply"
        icon="pi pi-search-plus"
        onClick={() => this.setState({ dialogValue: false })}
      />
    );
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const viewDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />*/}
        {/*<Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.HideViewDetailDialog} />*/}
        {/*<Button label="Close" className="p-button-help" onClick={this.HideViewDetailDialog} style={{    marginTop:'1.5em'}}/>*/}
      </React.Fragment>
    );
    const editDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Edit"
          icon="pi pi-pencil"
          className="p-button-help"
          type="submit"
          style={{ marginTop: "1.5em" }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.HideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px", width: "100%" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };
    const dialogHeaderTextStyle = { fontSize: "1.4rem", color: "#5c4ab5" };
    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };

    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">Users</span>
              </div>
            </div>
            <div className="p-grid transaction">
              <div
                className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
              >
                <div
                  className="p-grid p-fluid"
                  style={{
                    marginTop: "2px",
                    display: this.state.showFiltersMob,
                  }}
                >
                  <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                    <Button
                      icon="pi pi-sliders-h"
                      label="Filters"
                      className="p-button-info"
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #f0f0f0",
                        color: "#999",
                        float: "left",
                        width: "75%",
                      }}
                      onClick={() => this.setState({ dialogVisible: true })}
                    />
                  </div>
                  <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                    <Button
                      icon="pi pi-plus"
                      className="p-button-info"
                      onClick={() => this.OpenAddAdminDialog()}
                      style={{ fontSize: "15px", float: "right" }}
                      data-tip="Add User"
                      data-for="toolTip1"
                      data-place="top"
                    />
                    <ReactTooltip id="toolTip1" />
                  </div>
                </div>
                <div
                  className="p-grid p-fluid"
                  style={{
                    marginTop: "24px",
                    display: this.state.showFiltersWeb,
                  }}
                >
                  <div className="p-field p-col-6 p-md-3">
                    <span className="p-float-label">
                      <Calendar
                        id="range"
                        value={this.state.rangeValue}
                        onChange={(e) =>
                          this.setState(
                            { rangeValue: e.value, dateChange: "Y" },
                            () => this.getFilteredDataDate()
                          )
                        }
                        selectionMode="range"
                        readOnlyInput
                        showIcon={true}
                      />
                      <label htmlFor="calendar">DATE RANGE</label>
                    </span>
                  </div>
                  <div className="p-field p-col-6 p-md-3">
                    <span className="p-float-label">
                      <Dropdown
                        style={filterInputBoxStyle}
                        id="dropdown1"
                        options={this.state.status}
                        value={this.state.dropdownStatus}
                        onChange={(event) =>
                          this.setState(
                            { dropdownStatus: event.value, statusChanged: "Y" },
                            () => this.GetFilteredStatus()
                          )
                        }
                        autoWidth={false}
                      />
                      <label htmlFor="calendar">STATUS</label>
                    </span>
                  </div>
                  <div className="p-col-4 p-md-3 p-xl-1"></div>
                  <div className="p-col-12 p-md-4 p-xl-4">
                    <div className="p-grid">
                      <div
                        className="p-col-6 p-xl-6"
                        style={{ paddingRight: "0.3px" }}
                      >
                        <div className="p-inputgroup">
                          <span className="p-float-label">
                            <Dropdown
                              style={{ width: "20%" }}
                              options={this.state.searchFilter}
                              value={this.state.dropdownSearchFilter}
                              onChange={(event) =>
                                this.setState({
                                  dropdownSearchFilter: event.value,
                                })
                              }
                              autoWidth={false}
                            />
                            <label style={floatLabel}>Select Filter</label>
                          </span>
                        </div>
                      </div>
                      <div
                        className="p-col-6 p-xl-6"
                        style={{ paddingLeft: "0.4px" }}
                      >
                        <div className="p-inputgroup">
                          <InputText
                            value={this.state.searchValue}
                            onChange={(event) =>
                              this.setState({ searchValue: event.target.value })
                            }
                            placeholder="Search Value"
                          />
                          <Button
                            onClick={() => this.getSearchedData()}
                            icon="pi pi-search"
                            className="p-button-info"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-col-4 p-md-1 p-xl-1">
                    <Button
                      icon="pi pi-plus"
                      className="p-button-info"
                      onClick={() => this.OpenAddAdminDialog()}
                      style={{ fontSize: "15px" }}
                      data-tip="Add User"
                      data-for="toolTip1"
                      data-place="top"
                    />
                    <ReactTooltip id="toolTip1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid">
              <div
                className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                style={{ padding: "0" }}
              >
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <DataTable
                      className="p-datatable-responsive-demo"
                      resizableColumns
                      columnResizeMode="expand"
                      value={this.state.dataTableValue}
                      ref={(el) => {
                        this.dt = el;
                      }}
                      paginator={true}
                      rows={10}
                      responsive={true}
                    >
                      <Column field="short_name" style={{ display: "none" }} />
                      <Column field="user_id" style={{ display: "none" }} />
                      <Column field="user_type" style={{ display: "none" }} />
                      <Column
                        field="name"
                        header="Name"
                        body={this.nameColumnTemplate}
                      />
                      <Column
                        field="user_name"
                        header="Username"
                        body={this.userNameColumnTemplate}
                      />
                      <Column
                        field="phone_number"
                        header="Number"
                        body={this.phoneColumnTemplate}
                      />
                      {/*<Column field="email" header="Email" sortable={false} style={{textAlign:'center'}}/>*/}
                      <Column
                        field="status"
                        header="Status"
                        body={this.statusBodyTemplate}
                      />
                      <Column
                        field="date"
                        header="Date"
                        body={this.dateColumnTemplate}
                      />
                      <Column
                        header="Action"
                        body={this.actionBodyTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<Dialog header="Filters" visible={this.state.dialogVisible} footer={dialogFooter} onHide={() => this.setState({dialogVisible:false})}>*/}
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          style={{ width: "450px" }}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.getFilteredDataDate()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                  style={{ width: "100%" }}
                />
                <label style={floatLabel}>DATE RANGE</label>
              </span>
            </div>
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1em" }}
            >
              <span className="p-float-label">
                <Dropdown
                  style={filterInputBoxStyle}
                  id="dropdown1"
                  options={this.state.status}
                  value={this.state.dropdownStatus}
                  onChange={(event) =>
                    this.setState(
                      { dropdownStatus: event.value, statusChanged: "Y" },
                      () => this.GetFilteredStatus()
                    )
                  }
                  autoWidth={false}
                />
                <label htmlFor="calendar">STATUS</label>
              </span>
            </div>
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1em" }}
            >
              <div className="p-inputgroup">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: "20%" }}
                    options={this.state.searchFilter}
                    value={this.state.dropdownSearchFilter}
                    onChange={(event) =>
                      this.setState({ dropdownSearchFilter: event.value })
                    }
                    autoWidth={false}
                  />
                  <label style={floatLabel}>Select Filter</label>
                </span>
              </div>
            </div>
            <div className="p-field p-col-12 p-md-8 p-md-offset-2">
              <div className="p-inputgroup">
                <InputText
                  value={this.state.searchValue}
                  onChange={this.handleSearchValueChange}
                  placeholder="Search Value"
                />
              </div>
            </div>
            <div
              className="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
              style={{ textAlign: "center" }}
            >
              <Button
                label="Apply"
                onClick={() => this.getSearchedData()}
                icon="pi pi-search-plus"
                style={{ width: "50%" }}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={this.state.viewDetailDialog}
          style={{ width: "450px" }}
          header="User Details"
          modal
          onHide={this.HideViewDetailDialog}
        >
          <span className={this.state.dialogStatus}>
            {this.state.dialogStatusText}
          </span>
          <div className="p-grid p-fluid transaction">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <div style={cardStyle}>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>User Type</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.user_type}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>User ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogUserId}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogName}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Username</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogUserName}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Phone Number</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogPhoneNumber}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Email ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogEmail}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Created On</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogCreatedOn}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.editDialog}
          style={{ width: "450px" }}
          header="Edit User Details"
          modal
          onHide={this.hideEditDialog}
        >
          <form onSubmit={this.editUserDetails}>
            <span className={this.state.dialogStatus}>
              {this.state.dialogStatusText}
            </span>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "10px" }}
                  >
                    <Dropdown
                      style={filterInputBoxStyle}
                      id="dropdown1"
                      options={this.state.dialogStatusNew}
                      value={this.state.dropdownEditStatus}
                      onChange={(event) =>
                        this.setState({ dropdownEditStatus: event.value })
                      }
                      autoWidth={false}
                    />
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        name="dialogName"
                        onChange={this.handleChange}
                        value={this.state.dialogName}
                        style={{ border: this.state.dialogAdminName }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        name="dialogPhoneNumber"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.dialogPhoneNumber}
                        style={{ border: this.state.dialogAdminNumber }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Phone Number
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-7 p-lg-offset-7 p-xl-offset-7">
                <Button
                  label="Update"
                  icon="pi pi-pencil"
                  className="p-button-info"
                  type="submit"
                  style={{ marginTop: "1.5em" }}
                />
                {/*<Button label="Update" icon="pi pi-check" autoFocus />*/}
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.addAdminDialog}
          style={{ width: "600px" }}
          modal
          onHide={this.hideAddAdminDialog}
        >
          <form onSubmit={this.handleAddAdminSubmit} id="addAdminForm">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2"
                style={{ textAlign: "center" }}
              >
                <label style={dialogHeaderTextStyle}>Add User</label>
              </div>
            </div>
            <div className="p-grid p-fluid profile">
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
                <span className="p-float-label">
                  <InputText
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    style={{ border: this.state.firstNameError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    First Name
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
                <span className="p-float-label">
                  <InputText
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    style={{ border: this.state.lastNameError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Last Name
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
                <span className="p-float-label">
                  <InputText
                    value={this.state.userName}
                    onChange={(e) =>
                      this.setState({ userName: e.target.value })
                    }
                    style={{ border: this.state.userNameError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Username
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
                <span className="p-float-label">
                  <InputText
                    type="number"
                    value={this.state.phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    style={{ border: this.state.phoneError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Phone
                  </label>
                </span>
              </div>
              {/*<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
								<span className="p-float-label">
									<InputText value={this.state.shortName} onChange={(e) => this.setState({ shortName: e.target.value })} style={{ border: this.state.shortNameError }} />
									<label htmlFor="inputtext" style={labelStyle}>Short Name</label>
								</span>
							</div>*/}
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
                <span className="p-float-label">
                  <InputText
                    type="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    style={{ border: this.state.emailError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Email
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
                <span className="p-float-label">
                  <Dropdown
                    style={{filterInputBoxStyle, border: this.state.dropdownError}}
                    id="dropdown1"
                    options={this.state.userType}
                    value={this.state.dropdownuserType}
                    onChange={(event) =>
                      this.setState({ dropdownuserType: event.value })
                    }
                    autoWidth={false}
                  />
                  <label htmlFor="dropdown1" style={labelStyle}>
                    Select User Type
                  </label>
                </span>
              </div>
            </div>
            <div className="p-grid p-fluid" style={{ paddingBottom: "3em" }}>
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                <Button
                  type="submit"
                  label="Submit"
                  style={{ height: "36px" , marginTop: "1.5em"}}
                  className="p-button-info"
                />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    vaId: state.login.vaData.vaid,
  };
};

export default connect(mapStateToProps, null)(Admin);
