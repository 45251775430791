import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import "../../config";
import axios from "axios";
const qs = require("query-string");

class CancelledRequest extends Component {
  constructor() {
    super();
    document.title = "Cancelled Request - Instant Credit";
    this.state = {
      searchFilter: [{ label: "Short Name", value: "short_name" }],
      dialogRequestedBy: "",
      confirmationBtn: "",
      requestId: "",
      buttonID: "",
      dialogCreatedAt: "",
      dateChange: "N",
      dialogInstitutionId: "",
      dialogNarration: "",
      dialogRate: "",
      dialogRequestType: "",
      dialogStatus: "",
      dialogStatusText: "",
      dialogDestinationHouse: "",
      dialogExchangeRate: "",
      dialogReciAccountNumber: "",
      dialogReciCurrency: "",
      dialogTransDesc: "",
      dialogVaId: "",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      retrievalsDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      submitted: false,
      dropdownSearchFilter: "",
      status: null,
      dataTableValue: [],
      dataTableValueNew: [],
      layout: "list",
      rangeValue: [],
      showLoader: "none",
      contentOpacity: "1",
      isRequesting: false,
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.viewCancelledRequestDetails =
      this.viewCancelledRequestDetails.bind(this);
    this.hideTransactionDialog = this.hideTransactionDialog.bind(this);
    this.requestedByColumnTemplate = this.requestedByColumnTemplate.bind(this);

    this.institutionNameColumnTemplate =
      this.institutionNameColumnTemplate.bind(this);
    this.transactionIdColumnTemplate =
      this.transactionIdColumnTemplate.bind(this);

    this.cancelledIdColumnTemplate = this.cancelledIdColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  requestedByColumnTemplate(rowData) {
    return <React.Fragment>{rowData.requested_by}</React.Fragment>;
  }

  institutionNameColumnTemplate(rowData) {
    return <React.Fragment>{rowData.institution_name}</React.Fragment>;
  }

  transactionIdColumnTemplate(rowData) {
    return <React.Fragment>{rowData.trans_id}</React.Fragment>;
  }

  cancelledIdColumnTemplate(rowData) {
    return <React.Fragment>{rowData.request_id}</React.Fragment>;
  }

  dateColumnTemplate(rowData) {
    return <React.Fragment>{rowData.modified_at}</React.Fragment>;
  }

  actionColumnTemplate(rowData) {
    const { userDetails } = this.props;

    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-pencil"
          className="p-button-info p-mr-2"
          onClick={() => this.viewCancelledRequestDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="Take Action"
          data-for="toolTip1"
          data-place="top"
          disabled={userDetails.type === "102"}
        />
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }

  canelledRequestResponse = (event) => {
    this.setState({ isRequesting: true });
    event.preventDefault();
    console.log(this.state.buttonID);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      request_id: this.state.requestId,
      status: this.state.buttonID,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "cancellation-request-approval-status",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideTransactionDialog();
          this.toastSuccess(res.data.msg);
          this.getCancelledRequests();
        } else {
          this.hideTransactionDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({ isRequesting: false });
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  viewCancelledRequestDetails(rowData) {
    this.setState({
      requestId: rowData.request_id,
    });

    console.log(rowData);
    this.setState({
      dialogCreatedAt: rowData.modified_at,
      dialogInstitutionId: rowData.institution_id,
      dialogTransactionId: rowData.trans_id,
      dialogNarration: rowData.reason,
      dialogRequestType: rowData.type,
      dialogRequestedBy: rowData.requested_by,
      dialogRetrievalId: rowData.request_id,
      dialogAmount: rowData.amount,
      dialogDescirption: rowData.narration,
      dialogStatus: rowData.status,
      retrievalsDialog: true,
    });
  }

  hideTransactionDialog() {
    this.setState({
      confirmationBtn: "block",
    });
    this.setState({ retrievalsDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  componentDidMount() {
    this.updateDimensions();
    this.getCancelledRequests();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  openNew = () => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      this.filterDataByDate();
    }
  };
  filterDataByDate = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      type: 0,
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-cancellation-request",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getSearchedData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    this.setState({
      dialogVisible: false,
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      type: 0,
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-cancellation-request",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          console.log(res.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getCancelledRequests = async () => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      status: 0,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-cancellation-request",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          console.log(res.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        request_id: d["request_id"],
        type: d["type"],
        requested_by: d["requested_by_name"],
        institution_id: d["institution_name"],
        institution_name: d["institution_name"],
        trans_id: d["trans_id"],
        narration: d["reason"],
        request_id: d["request_id"],
        modified_at: d["modified_at"],
        amount: d["amount"],
        approved_by: d["approved_by"],
        approved_by_name: d["approved_by_name"],
        status: d["status"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  render() {
    const { isRequesting } = this.state;

    const cardStyle = { borderRadius: "40px" };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "left",
      fontWeight: "500",
    };
    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };

    return (
      <div>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div
          className="layout-content"
          style={{ opacity: this.state.contentOpacity }}
        >
          <ProgressSpinner
            style={{
              display: this.state.showLoader,
              width: "40px",
              height: "40px",
              position: "fixed",
              top: "50%",
              left: "50%",
              bottom: "50%",
              right: "50%",
              zIndex: "1111111111",
            }}
            strokeWidth="3"
            animationDuration=".5s"
          />
          <div>
            <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
              <div className="p-col-12 p-md-12 p-xl-12">
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    <div>
                      <span className="p-tag custom-heading">
                        Cancelled Request
                      </span>
                    </div>
                  </div>
                </div>

                <div className="p-grid transaction">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "2px",
                        display: this.state.showFiltersMob,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          icon="pi pi-sliders-h"
                          label="Filters"
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            border: "1px solid #f0f0f0",
                            color: "#999",
                            float: "left",
                            width: "75%",
                          }}
                          onClick={() => this.setState({ dialogVisible: true })}
                        />
                      </div>
                    </div>
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "24px",
                        display: this.state.showFiltersWeb,
                      }}
                    >
                      <div className="p-col-6 p-md-3 p-lg-3 p-xl-4">
                        <span className="p-float-label">
                          <Calendar
                            id="range"
                            value={this.state.rangeValue}
                            onChange={(e) =>
                              this.setState(
                                { rangeValue: e.value, dateChange: "Y" },
                                () => this.getFilteredDataDate()
                              )
                            }
                            selectionMode="range"
                            readOnlyInput
                            showIcon={true}
                          />
                          <label htmlFor="calendar">DATE RANGE</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-grid ">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ padding: "0" }}
                  >
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <DataTable
                          className="p-datatable-responsive-demo"
                          resizableColumns
                          columnResizeMode="expand"
                          value={this.state.dataTableValue}
                          paginator={true}
                          rows={10}
                          ref={(el) => {
                            this.dt = el;
                          }}
                        >
                          <Column
                            field="modified_at"
                            header="Date / Time"
                            body={this.dateColumnTemplate}
                          />
                          <Column
                            field="request_id"
                            style={{ display: "none" }}
                          />
                          <Column field="amount" style={{ display: "none" }} />
                          <Column
                            field="approved_by"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="approved_by_name"
                            style={{ display: "none" }}
                          />

                          <Column
                            field="requested_by"
                            header="Requested By"
                            body={this.requestedByColumnTemplate}
                          />
                          <Column
                            field="institution_name"
                            header="Institution Name"
                            body={this.institutionNameColumnTemplate}
                          />
                          <Column
                            field="trans_id"
                            header="Transaction ID"
                            body={this.transactionIdColumnTemplate}
                          />
                          <Column
                            header="Cancellation ID"
                            body={this.cancelledIdColumnTemplate}
                          />
                          <Column
                            field="modified_at"
                            header="Cancellation Date"
                            body={this.dateColumnTemplate}
                          />

                          <Column
                            header="Action"
                            body={this.actionColumnTemplate}
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            visible={this.state.retrievalsDialog}
            style={{ width: "450px" }}
            header="Confirmation"
            modal
            onHide={this.hideTransactionDialog}
          >
            <form onSubmit={this.canelledRequestResponse}>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div style={cardStyle}>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Requested By</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogRequestedBy}
                        </label>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Institution Name</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogInstitutionId}
                        </label>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Transaction ID</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogTransactionId}
                        </label>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Cancellation ID</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogRetrievalId}
                        </label>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Cancellation Date</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogCreatedAt}
                        </label>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Amount</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogAmount}
                        </label>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Type</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogRequestType}
                        </label>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>Purpose</label>
                      </div>
                      <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogDescirption}
                        </label>
                      </div>
                      <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                        <label style={labelStyleLeft}>
                          Cancellation Status
                        </label>
                      </div>
                      <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                        <label style={labelStyleRight}>
                          {this.state.dialogStatus === 0
                            ? "Pending"
                            : this.state.dialogStatus === 1
                            ? "Approved"
                            : this.state.dialogStatus === 2
                            ? "Declined"
                            : this.state.dialogStatus}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="p-grid p-fluid"
                id={this.state.requestId}
                style={{ display: this.state.confirmationBtn }}
              >
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid">
                    <div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-2 p-sm-offset-2 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                      <Button
                        label="Approve"
                        id="1"
                        onClick={() => this.setState({ buttonID: "1" })}
                        className="p-button-info"
                        type="submit"
                        disabled={isRequesting}
                        style={{ marginTop: "1.5em" }}
                      />
                    </div>
                    <div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                      <Button
                        label="Decline"
                        type="submit"
                        disabled={isRequesting}
                        onClick={() => this.setState({ buttonID: "2" })}
                        className="p-button-outlined p-button-info"
                        style={{ marginTop: "1.5em" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(CancelledRequest);
