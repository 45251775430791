import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import dateFormat from 'dateformat';
import { LoginAction } from '../../store/actions/LoginAction';
import { connect } from 'react-redux';
import '../../config';
//import Cookies from 'universal-cookie';
import axios from "axios";
import queryString from 'query-string';
const qs = require('query-string');
//const cookies = new Cookies();

class Request extends Component {

    constructor() {
        super();
        document.title = "Forex Requests - Instant Credit";
        let today1 = new Date();
        this.exportCSV = this.exportCSV.bind(this);
        this.state = {
            searchFilterOld: [
                { label: 'Short Name', value: 'short_name' },
                { label: 'Transaction Id', value: 'trans_id' }
            ],
            searchFilter: [
                { label: 'Phone Number', value: 'phone' },
                { label: 'Transaction Id', value: 'trans_id' }
            ],
			showActionButton:'',
			confirmationBtn:'',
			requestId:'',
			buttonID:'',
            dialogPhone: '',
            dialogRequestedBy: '',
            dateChange: 'N',
            dialogFrom: '',
            dialogTo: '',
            dialogRate: '',
            dialogRequestedOn: '',
            dialogStatus: '',
            dialogStatusText: '',
            dialogDestinationHouse: '',
            dialogExchangeRate: '',
            dialogReciAccountNumber: '',
            dialogReciCurrency: '',
            dialogTransDesc: '',
            dialogVaId: '',
            searchValue: '',
            dataTableDummyValue: '',
            dialogVisible: false,
            forexDialog: false,
            showFiltersWeb: 'none',
            showFiltersMob: 'none',
            productDialog: false,
            retryTransactionButton: 'none',
            reversalTransactionButton: 'none',
            closeTransactionButton: 'none',
            submitted: false,
            dropdownCity: 'All',
            dropdownSearchFilter: '',
            filterCountry: '',
            filterDate: '',
            searchValueText: '',
            today: today1,
            dropdowncountries: "Filter Filter",
            status: null,
            date2: null,
            dataTableValue: [],
            dataTableValueNew: [],
            dataViewValue: [],
            selectedFile: null,
            selectedFiles: null,
            documents: [],
            documentsSelection: null,
            fullCalendarEvents: [],
            layout: 'list',
            cities: [],
        };

        this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
        this.viewForexRequestDetails = this.viewForexRequestDetails.bind(this);
        this.hideTransactionDialog = this.hideTransactionDialog.bind(this);
        this.initiatedByColumnTemplate = this.initiatedByColumnTemplate.bind(this);
        this.fromColumnTemplate = this.fromColumnTemplate.bind(this);
        this.toColumnTemplate = this.toColumnTemplate.bind(this);
        this.institutionColumnTemplate = this.institutionColumnTemplate.bind(this);
        this.rateByColumnTemplate = this.rateColumnTemplate.bind(this);
        this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
    }
	institutionColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Institution</span>
                {rowData.instituion}
            </React.Fragment>
        );
    }
    initiatedByColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Amount</span>
                {rowData.initiated_by}
            </React.Fragment>
        );
    }

    fromColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Source</span>
                {rowData.from_currency}
            </React.Fragment>
        );
    }

    toColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Destination</span>
                {rowData.to_currency}
            </React.Fragment>
        );
    }
    rateColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Country</span>
                {rowData.rate}
            </React.Fragment>
        );
    }
    dateColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Date / Time</span>
                {rowData.modified_at}
            </React.Fragment>
        );
    }

    statusColumnTemplate(rowData) {
        let ptag = rowData.status;
        let pTag = '';
        let status = 'FAILED'
        if (ptag == '1') {
            console.log("ACTIVE");
            status = 'SUCCESS'
            pTag = "p-tag p-tag-success";
            console.log(pTag);
        }
        else {
            console.log("BLOCKED");
            pTag = "p-tag p-tag-danger";
            console.log(pTag);
        }
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                <span className={pTag}>{status}</span>
            </React.Fragment>
        );
    }

    actionColumnTemplate(rowData) {
        return (
            <React.Fragment>
                <span className="p-column-title">Action</span>
				<Button label="" icon="pi pi-eye" className="p-button-info p-mr-2" onClick={() => this.viewForexRequestDetails(rowData)} style={{ fontSize: '15px', padding: '7px' }} />
            </React.Fragment>
        );
    }
	
	requestResponse = (event) => {
		event.preventDefault();
		console.log(this.state.buttonID);
		console.log(this.state.requestId);
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
			request_id : this.state.requestId,
			status : this.state.buttonID,
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'request-forex-status', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
        			this.getTransactionData();
					this.setState({
						confirmationBtn:'none',

					});
					this.hideTransactionDialog();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.hideTransactionDialog();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
	}
	toastSuccess(msg) {
        this.toastBR.show({severity:'success', summary: '', detail:msg, life: 5000});
    }
    toastError(msg) {
        this.toastBR.show({severity:'error', summary: '', detail:msg, life: 5000});
    }
    viewForexRequestDetails(rowData) {
		console.log(rowData);
        this.setState({
            dialogRequestedBy: rowData.initiated_by,
            dialogFrom: rowData.from_currency,
            dialogTo: rowData.to_currency,
            dialogRate: rowData.rate,
            dialogRequestedOn: rowData.modified_at,
            requestId: rowData.request_id,
            forexDialog: true
        });
    }

    hideTransactionDialog() {
		this.setState({
			confirmationBtn:'block',
		});
        this.setState({ forexDialog: false });
    }

    handleSearchValueChange = (e) => {
        this.setState({
            searchValue: e.target.value
        });
    }

    componentDidMount() {
		if('102' == this.props.userDetails.type)
		{
			this.setState({
				showActionButton:'none',
			});
		}
		this.props.userDetails.reqcount = '';//0;
		this.props.loginAction(this.props.userDetails).then(result => {
                            if (result.success) {
                            }
                            else {
                            }
                        })		
        this.setState({
            cities: this.props.userDetails.allCountries
        });
        this.updateDimensions();
        this.getTransactionData();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        if (window.innerWidth < 1025) {
            this.setState({ showFiltersWeb: 'none', showFiltersMob: 'flex' });
        }
        else {
            let update_width = window.innerWidth - 100;
            this.setState({ showFiltersWeb: 'flex', showFiltersMob: 'none' });
        }
    }

    hideDialog = () => {
        this.setState({
            submitted: false,
            productDialog: false
        });
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    openNew = (data) => {
        this.setState({
            submitted: false,
            productDialog: true
        });
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0)
            this.setState({ sortOrder: -1, sortField: value.substring(1, value.length), sortKey: value });
        else
            this.setState({ sortOrder: 1, sortField: value, sortKey: value });
    }
	
	getFilteredDataDate = async () => {
        this.state.dialogVisible = false;
        this.filterDataByDate();
    }
    filterDataByDate = async () => {
        let date_format = dateFormat(this.state.value3, "yyyy-mm-dd");
		console.log(date_format);
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
			search_date : date_format,
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'list-forex-request', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
                    console.log(res.data);
                    this.createDataTable(res.data.data);
                }
                else {
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
    }


    getTransactionData = async () => {
        let date_format = dateFormat(this.state.filterDate, "yyyy-mm-dd");
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'list-forex-request', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
                    console.log(res.data);
                    this.createDataTable(res.data.data);
                }
                else {
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
    }

    createDataTable(data) {
        this.state.dataTableValueNew = [];
        this.state.dataTableValue = [];
        this.state.dataTableValueNew = data;
        this.state.dataTableValueNew.forEach((d) => {
            this.state.dataTableValue.push(
                { "initiated_by": d['initiated_by_name'], "from_currency":d['from_currency'],"to_currency":d['to_currency'],"rate":d['rate'],"modified_at": d['modified_at'],"request_id":d['id'],"institution":d['institution_id']['short_name'] }
            );
        });
        this.setState({ dataTableDummyValue: this.state.dataTableValue });
    }

    render() {
        const dialogFooter = (
            <Button label="Apply" onClick={() => this.getSearchedData()} icon="pi pi-search-plus" />
        );
        const header = <div style={{ textAlign: 'right' }}><Button type="button" icon="pi pi-external-link" label="Export" onClick={this.exportCSV} style={{ width: '12%' }}></Button></div>;
        const forexDialogFooter = (
            <React.Fragment>
                {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
                <Button label="Retry" icon="pi pi-refresh" className="p-button-help" onClick={this.hideTransactionDialog} style={{ marginTop: '1.5em', display: this.state.retryTransactionButton }} />
                <Button label="Reverse Transaction" icon="pi pi-refresh" className="p-button-help" onClick={this.hideTransactionDialog} style={{ marginTop: '1.5em', display: this.state.reversalTransactionButton }} />
                <Button label="Close" className="p-button-success" onClick={this.hideTransactionDialog} style={{ marginTop: '1.5em', display: this.state.closeTransactionButton }} />
            </React.Fragment>
        );
        const productDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveProduct} />
            </React.Fragment>
        );
        const inputBoxStyle = { borderRadius: '5px', border: 'none', paddingLeft: '12px', backgroundColor: '#f7f7f7' };
        const filterInputBoxStyle = { borderRadius: '4px' };
        const pgridCustomStyle = { marginRight: '0', marginLeft: '0' };
        const cardStyle = { borderRadius: '40px' };
        const h3Style = { color: 'rgb(92, 74, 181)', fontWeight: '500', fontSize: '15px', marginBottom: '1em' };
        const labelStyleLeft = { fontSize: '14px' };
        const labelStyleLeftFooter = { fontSize: '14px', color: 'blue' };
        const labelStyleRight = { fontSize: '14px', float: 'left', fontWeight: '500' };
        const labelStyleHeader = { fontSize: '14px', fontWeight: '600', color: 'rgb(92, 74, 181)' };
		const labelStyle = { paddingLeft: '10px', fontSize: '12px', color: '#999', fontWeight: '500' };
		const detailsStyle = { fontSize: '14px', paddingTop: '0.4em', color: 'rgb(92, 74, 181)', float: 'right' };

        return (
            <div className="layout-content">
				<Toast ref={(el) => this.toastBR = el} position="top-right" />
                <div>
                    <div className="p-grid p-fluid" style={{ marginTop: '20px' }}>
                        <div className="p-col-12 p-md-12 p-xl-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4" style={{ textAlign: 'center' }}>
                                    <div>
										<span className="p-tag custom-heading">Forex Requests</span>
                                    </div>
                                </div>
                            </div>

							<div className="p-grid transaction">
								<div className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
									<div className="p-grid p-fluid" style={{ marginTop: '2px', display: this.state.showFiltersMob }}>
										<div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
											<Button icon="pi pi-sliders-h" label="Filters" className="p-button-info" style={{ backgroundColor: '#f0f0f0', border: '1px solid #f0f0f0', color: '#999', float: 'left', width: '75%' }} onClick={() => this.setState({ dialogVisible: true })} />
										</div>
									</div>
									<div className="p-grid p-fluid" style={{ marginTop: '24px', display: this.state.showFiltersWeb }}>
										<div className="p-field p-col-6 p-md-3 p-xl-3">
											<span className="p-float-label">
												<Calendar inputStyle={filterInputBoxStyle} id="calendar" value={this.state.today} onChange={(e) => this.setState({ value3: e.value, dateChange: 'Y' }, () => this.getFilteredDataDate())} showIcon={true} />
												<label htmlFor="calendar">DATE</label>
											</span>
										</div>
									</div>
								</div>
							</div>



                            <div className="p-grid transaction">
                                <div className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ padding: '0' }}>
                                    <div className="datatable-responsive-demo">
                                        <div className="card">
                                            <DataTable className="p-datatable-responsive-demo" resizableColumns columnResizeMode="expand" value={this.state.dataTableValue} paginator={true} rows={10} ref={(el) => { this.dt = el; }}>
                                                <Column field="request_id" style={{display:'none'}} />
                                                <Column field="institution" header="Institution" body={this.institutionColumnTemplate} />
                                                <Column field="initiated_by" header="Requested By" body={this.initiatedByColumnTemplate} />
                                                <Column field="from_currency" header="Source" body={this.fromColumnTemplate} />
                                                <Column field="to_currency" header="Destination" body={this.toColumnTemplate} />
                                                <Column field="rate" header="Rate" body={this.rateColumnTemplate} />
                                                <Column field="modified_at" header="Date" body={this.dateColumnTemplate} />
                                                <Column header="Action" body={this.actionColumnTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog visible={this.state.forexDialog} style={{ width: '450px', overflowY: 'scroll' }} header="Confirmation" modal onHide={this.hideTransactionDialog}>
                	<form onSubmit={this.requestResponse}>
                        <div className="p-grid p-fluid">
							 <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                            <div style={cardStyle}>
                                <div className="p-grid">
                                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                                        <label style={labelStyleLeft}>Request Type:</label>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                                        <label style={labelStyleRight}>Forex</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                                        <label style={labelStyleLeft}>Requested By</label>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                                        <label style={labelStyleRight}>{this.state.dialogRequestedBy}</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                                        <label style={labelStyleLeft}>Source</label>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                                        <label style={labelStyleRight}>{this.state.dialogFrom}</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                                        <label style={labelStyleLeft}>Destination</label>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                                        <label style={labelStyleRight}>{this.state.dialogTo}</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                                        <label style={labelStyleLeft}>Rate</label>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                                        <label style={labelStyleRight}>{this.state.dialogRate}</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                                        <label style={labelStyleLeft}>Date</label>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                                        <label style={labelStyleRight}>{this.state.dialogRequestedOn}</label>
                                    </div>
                                </div>
							</div>
                        </div>
					</div>
					<div style={{display:this.state.showActionButton}}>
                        <div className="p-grid p-fluid" id={this.state.requestId} style={{display:this.state.confirmationBtn}}>
							 <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                <div className="p-grid">
                            		<div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-2 p-sm-offset-2 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
										<Button label="Decline" type="submit" onClick={(e) => this.setState({ buttonID: '2' })} id='2' className="p-button-outlined p-button-info" style={{ marginTop: '1.5em' }} />
									</div>
                            		<div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                		<Button label="Accept" id='1' onClick={(e) => this.setState({ buttonID: '1' })}  className="p-button-info" value="decline" type="submit" style={{ marginTop: '1.5em' }} />
									</div>
								</div>
                            </div>
                        </div>
					</div>
				</form>
			</Dialog>
			<Dialog header="Filters" visible={this.state.dialogVisible} onHide={() => this.setState({ dialogVisible: false })}>
                    <div className="p-grid">
                        <div className="p-field p-col-12 p-md-8 p-md-offset-2" style={{ marginTop: '1.5em' }}>
                            <span className="p-float-label">
                                <Calendar inputStyle={filterInputBoxStyle} id="calendar" value={this.state.today} onChange={(e) => this.setState({ value3: e.value, dateChange: 'Y' }, () => this.getFilteredDataDate())} showIcon={true} style={{ width: '100%' }} />
                                <label htmlFor="calendar">DATE</label>
                            </span>
                        </div>
                    </div>
                </Dialog>
		</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.login.userDetails,
    }
}

const mapDispatchToProps = {
    loginAction: LoginAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Request);

