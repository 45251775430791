import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import '../../config';
//import Cookies from 'universal-cookie';
import axios from "axios";
import queryString from 'query-string';
const qs = require('query-string');
//const cookies = new Cookies();

class FinanceProfile extends Component {

    constructor() {
        super();
        document.title = "Profile - Instant Credit";
		this.toastSuccess = this.toastSuccess.bind(this);
        this.state = {
            password: '',
            isButtonDisabled: true,
            openDialog: false,

            confirmpassword: '',
            confirmPasswordBorder: '',
            passwordMatch: '',

            accountTitle: '',
            billingTitle: '',
            detailsTitle: '',
            helpTitle: '',
            institutionId: '',
            billingRate: '',
            billingType: '',
            email: '',
            joinedOn: '',
            name: '',
            shortName: '',
            callUs: '',
            emailUs: '',
            countries: [],
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            border: '',
            errorBlock: 'none',
            errorMsg: '',
            items: [],
			showLoader:'none',
			contentOpacity:'1',
        };
    }

    componentDidMount() {
        console.log(this.props.userDetails.allCountries);
        console.log("COUNTRIES");
        this.GetProfileData();
		{/*this.toastSuccess();*/}
    }
	
    GetProfileData = async () => {
		this.setState({
				contentOpacity:0.5,
				showLoader:'block'
			});
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id
        }
        axios.post(global.config.main.baseUrl + 'user-profile', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
                    console.log(res.data.data);
                    this.state.countries = res.data.data.billing_detail.country;
                    console.log(this.state.countries);
                    this.state.countries.forEach((d) => {
                        this.state.items.push(
                            <div className="p-col-3 p-md-4 p-lg-2 p-xl-2 profileTop">
                                <span className="p-tag p-tag-warning" style={{ paddingLeft: '8px', paddingRight: '8px' }}>{d}</span>
                            </div>
                        );
                    });
                    console.log(this.state.items);
                    this.setState({
                        accountTitle: res.data.data.account_options.title,
                        billingTitle: res.data.data.billing_detail.title,
                        detailsTitle: res.data.data.details.title,
                        helpTitle: res.data.data.help.title,
                        institutionId: res.data.data.account_options.institution_id,
                        billingRate: res.data.data.billing_detail.billing,
                        billingType: res.data.data.billing_detail.type,
                        email: res.data.data.details.email,
                        joinedOn: res.data.data.details.join_date,
                        name: res.data.data.details.name,
                        shortName: res.data.data.details.short_name,
                        callUs: res.data.data.help.call_us,
                        emailUs: res.data.data.help.email_at,
                    });
					this.setState({
						contentOpacity:1,
						showLoader:'none'
					});
                }
                else {
					this.setState({
						contentOpacity:1,
						showLoader:'none'
					});
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        errorMsg: 'A network error occurred',
                    })
                }
            })
    }
    handleChangeConfirmPassword = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.checkPassword);
    }
    checkPassword = (e) => {
		if('' != this.state.confirmPassword)
		{
        	let confirmPassword = this.state.confirmPassword;
        	let newPassword = this.state.newPassword;
        	if (newPassword === confirmPassword) {
        	    this.setState({
        	        isButtonDisabled: false,
        	        border: '',
        	    });
        	}
        	else {
        	    this.setState({
        	        isButtonDisabled: true,
        	        border: '2px solid red',
        	    });
        	}
		}

    }
    changePasswordDialog = (e) => {
        this.setState({
            openDialog: true
        });
    }
    hideChangePasswordDialog = (e) => {
        this.setState({
            openDialog: false
        });
    }
    HandleChangePasswordSubmit = async (event) => {
        event.preventDefault();
        console.log(this.props.userDetails._id);
        console.log(this.state.currentPassword);
        console.log(this.state.currentPassword);
		if(6 > this.state.newPassword.length)
        {
            this.setState({
                showPasswordErrorMsg:'flex',
                passwordErrorMsg : "* New Password must be at least 6 characters long",
            });
            return false;
        }
        else
        {
            this.setState({
                showPasswordErrorMsg:'none',
                passwordErrorMsg : "",
            });
        }
		if (!/[0-9]/.test(this.state.newPassword))
        {
            this.setState({
                showPasswordErrorMsg:'flex',
                passwordErrorMsg : "* New Password must include at least one numeric digit",
            });
            return false;
        }
        else
        {
            if (!/[A-Z]/.test(this.state.newPassword))
            {
                this.setState({
                    showPasswordErrorMsg:'flex',
                    passwordErrorMsg : "* New Password must include at least one uppercase character",
                });
                return false;
            }
            else
            {
                if (!/[a-z]/.test(this.state.newPassword))
                {
                    this.setState({
                        showPasswordErrorMsg:'flex',
                        passwordErrorMsg : "* New Password must include at least one lowercase character",
                    });
                    return false;
                }
                else
                {
                    if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.newPassword))
{
                        this.setState({
                            showPasswordErrorMsg:'flex',
                            passwordErrorMsg : "* New Password must include at least one special character",
                        });
                        return false;
                    }
					else
                    {
                        if (this.state.newPassword.includes(' '))
                        {
                            this.setState({
                                showPasswordErrorMsg:'flex',
                                passwordErrorMsg : "* New Password cannot have blank space",
                            });
                            return false;
                        }
                    }
                }
            }
        }
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
            oldpassword: this.state.currentPassword,
            newpassword: this.state.newPassword,
        }
        axios.post(global.config.main.baseUrl + 'change-password', qs.stringify(data), config)
            .then(res => {
                if (res.data.status === 200) {
					this.hideChangePasswordDialog();
					this.toastSuccess(res.data.msg);
                }
                else {
					this.hideChangePasswordDialog();
					this.toastError(res.data.msg);
                }
            })
            .catch(error => {
                if (!error.response) {
                    this.setState({
                        msg: 'A network error occurred',
                    })
                }
            })
    }
	toastSuccess(msg) {
        this.toastBR.show({severity:'success', summary: '', detail:msg, life: 5000});
    }
	toastError(msg) {
        this.toastBR.show({severity:'error', summary: '', detail:msg, life: 5000});
    }

    render() {
        const cardStyle = { borderRadius: '20px', padding: '2em',minHeight:'18em' };
        const submitButtonStyle = { backgroundColor: 'rgb(84,67,163)', fontWeight: '600', fontFamily: 'unset', fontSize: '16px', borderRadius: '10px', height: '40px' };
        const pgridCustomStyle = { marginRight: '0', marginLeft: '0', marginTop: '22px' };
        const h1Style = { textAlign: 'center', fontWeight: '600', color: 'rgb(92, 74, 181)', textDecoration: 'underline' };
        const inputBoxStyle = {};//{backgroundColor:'rgb(240,240,240)',border:'0',borderRadius:'10px',padding:'10px',paddingLeft:'12px'};
        const labelStyle = { fontSize: '14px' };
        const labelStyleLeft = { fontSize: '14px' };
        const labelStyleLeftFooter = { fontSize: '14px', color: 'blue' };
        const labelStyleRight = { fontSize: '14px', float: 'left', fontWeight: '500',wordBreak:'break-all' };
        const labelStyleHeader = { fontSize: '14px', fontWeight: '600', color: 'rgb(92, 74, 181)' };
        const dialogHeaderTextStyle = { fontSize: '1.4rem',color:'#5c4ab5' };
        const borderStyle = { border: this.state.confirmPasswordBorder };
        const badgeStyle = { paddingLeft: '8px', paddingRight: '8px' };

        return (
            <div style={{opacity:this.state.contentOpacity}}>
				<Toast ref={(el) => this.toastBR = el} position="top-right" />
				<ProgressSpinner style={{display:this.state.showLoader,width: '40px', height: '40px',position:'fixed',top:'50%',left:'50%',bottom:'50%',right:'50%',zIndex:'1111111111'}} strokeWidth="3" animationDuration=".5s"/>
                <Dialog visible={this.state.openDialog} style={{ width: '600px' }} modal onHide={this.hideChangePasswordDialog}>
                    <form onSubmit={this.HandleChangePasswordSubmit}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2" style={{ textAlign: 'center' }}>
                                <label style={dialogHeaderTextStyle}>Change your password</label>
                            </div>
                        </div>
                        <div className="p-grid p-fluid profile">
                            <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                                <label htmlFor="calendar" style={labelStyle}>Confirm your current password</label>
                            </div>
                            <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                                <InputText type="password" id="inputtext" style={{ height: '36px' }} placeholder="Curent Password" value={this.state.currentPassword} onChange={(e) => this.setState({ currentPassword: e.target.value })} required />
                            </div>
                        </div>
                        <div className="p-grid p-fluid profile">
                            <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                                <label htmlFor="calendar" style={labelStyle}>Enter your new password (Keep your account more secure. Don't use your name.)</label>
                            </div>
                            <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                                <InputText type="password" id="inputtext" style={{ height: '36px' }} placeholder="New Password" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value }, () => this.checkPassword())} required />
                            </div>
							<div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2" style={{display:this.state.showPasswordErrorMsg}}>
                                <label htmlFor="calendar" style={{fontSize: '14px',color:'red'}}>{this.state.passwordErrorMsg}</label>
                            </div>
                            <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                                <InputText type="password" id="inputtext" style={{ height: '36px', border: this.state.border }} placeholder="Confirm New Password" name='confirmPassword' value={this.state.confirmPassword} onChange={this.handleChangeConfirmPassword} required />
                            </div>
                        </div>
                        <div className="p-grid p-fluid" style={{ paddingBottom: '3em' }}>
                            <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                                <Button type="submit" disabled={this.state.isButtonDisabled} label="Change Password" style={{ height: '36px' }} className="p-button-info" style={{ marginTop: '1.5em' }} />
                            </div>
                        </div>
                    </form>
                </Dialog>

                <div className="p-grid p-fluid" style={{ marginTop: '20px' }}>
                    <div className="p-col-12 p-md-12 p-xl-12">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{textAlign:'center'}}>
								<span className="p-tag custom-heading">Profile</span>
                            </div>
                        </div>
                        <div className="p-grid p-fluid profile" style={pgridCustomStyle}>
                            <div className="p-col-12 p-sm-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
                                <div className="card card-w-title" style={cardStyle}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-6 p-xl-6">
                                            <label style={labelStyleHeader}>{this.state.detailsTitle}</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-5 p-md-6 p-lg-6 p-xl-6 profileTop">
                                            <label style={labelStyleLeft}>Name</label>
                                        </div>
                                        <div className="p-col-7 p-md-6 p-lg-6 p-xl-6 profileTop">
                                            <label style={labelStyleRight}>{this.state.name}</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-5 p-md-6 p-lg-6 p-xl-6 profileTop">
                                            <label style={labelStyleLeft}>Username</label>
                                        </div>
                                        <div className="p-col-7 p-md-6 p-lg-6 p-xl-6 profileTop">
                                            <label style={labelStyleRight}>{this.state.shortName}</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-5 p-md-6 p-lg-6 p-xl-6 profile">
                                            <label style={labelStyleLeft}>Email</label>
                                        </div>
                                        <div className="p-col-7 p-md-6 p-lg-6 p-xl-6 profile">
                                            <label style={labelStyleRight}>{this.state.email}</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-5 p-md-6 p-lg-6 p-xl-6 profile">
                                            <label style={labelStyleLeft}>Joined On</label>
                                        </div>
                                        <div className="p-col-7 p-md-6 p-lg-6 p-xl-6 profile">
                                            <label style={labelStyleRight}>{this.state.joinedOn}</label>
                                        </div>
                                    </div>
                                    <div className="p-grid" style={{ display: 'none' }}>
                                        <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-4 p-lg-offset-4 p-xl-offset-4 profile">
                                            <Button label="Change Password" style={{ fontSize: '15px', padding: '7px' }} className="p-button-raised p-button-secondary p-button-text" onClick={() => this.changePasswordDialog()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-sm-12 p-md-5 p-lg-5 p-xl-5">
                                <div className="card card-w-title" style={cardStyle}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-6 p-xl-6">
                                            <label style={labelStyleHeader}>{this.state.accountTitle}</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-5 p-md-6 p-lg-6 p-xl-6 profileTop">
                                            <label style={labelStyleLeft}>User ID</label>
                                        </div>
                                        <div className="p-col-7 p-md-6 p-lg-6 p-xl-6 profileTop">
                                            <label style={labelStyleRight}>{this.state.institutionId}</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-5 p-md-6 p-lg-6 p-xl-6 profile">
                                            <label style={labelStyleLeft}>Nationality</label>
                                        </div>
                                        <div className="p-col-7 p-md-6 p-lg-6 p-xl-6 profile">
                                            <label style={labelStyleRight}>Ghanaian</label>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12 profile">
                                            <label style={labelStyleLeftFooter} onClick={() => this.changePasswordDialog()}>Change your password</label>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12 profile">
                                            <label style={labelStyleLeftFooter}>Close your account</label>
                                        </div>
                                    </div>
									*/}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.login.userDetails,
    }
}

export default connect(mapStateToProps, null)(FinanceProfile);
