import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import dateFormat from "dateformat";
import { connect } from "react-redux";
import "../../config";
//import Cookies from 'universal-cookie';
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
//const cookies = new Cookies();

class HelpDeskRequest extends Component {
  constructor() {
    super();
    document.title = "Requests - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      status: [
        { label: "All", value: "All" },
        { label: "Pending", value: "0" },
        { label: "Approved", value: "1" },
        { label: "Declined", value: "2" },
      ],
      searchFilterOld: [
        { label: "Short Name", value: "short_name" },
        { label: "Transaction Id", value: "trans_id" },
      ],
      searchFilter: [
        { label: "Phone Number", value: "phone" },
        { label: "Transaction Id", value: "trans_id" },
      ],
      confirmationBtn: "",
      transId: "",
      buttonID: "",
      dialogPhone: "",
      dialogRequestedBy: "",
      dateChange: "N",
      dropdownStatus: "All",
      dialogInstitution: "",
      dialogRequestedOn: "",
      dialogRate: "",
      dialogRequestedOn: "",
      dialogStatus: "",
      dialogStatusText: "",
      dialogDestinationHouse: "",
      dialogExchangeRate: "",
      dialogReciAccountNumber: "",
      dialogReciCurrency: "",
      dialogTransDesc: "",
      dialogVaId: "",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      requestDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      reversalTransactionButton: "none",
      closeTransactionButton: "none",
      submitted: false,
      dropdownCity: "All",
      dropdownSearchFilter: "",
      filterCountry: "",
      filterDate: "",
      searchValueText: "",
      dropdowncountries: "Filter Filter",
      date2: null,
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      selectedFile: null,
      selectedFiles: null,
      documents: [],
      documentsSelection: null,
      fullCalendarEvents: [],
      layout: "list",
      cities: [],
      rangeValue: [],
      showLoader: "none",
      contentOpacity: "1",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.transIdColumnTemplate = this.transIdColumnTemplate.bind(this);
    this.viewRetryRequestDetails = this.viewRetryRequestDetails.bind(this);
    this.hideTransactionDialog = this.hideTransactionDialog.bind(this);
    this.requestedByColumnTemplate = this.requestedByColumnTemplate.bind(this);
    this.institutionColumnTemplate = this.institutionColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.requestTypeColumnTemplate = this.requestTypeColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.apprDecByColumnTemplate = this.apprDecByColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  transIdColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Transaction ID</span>
        {rowData.trans_id}
      </React.Fragment>
    );
  }
  apprDecByColumnTemplate(rowData) {
    let response = "";
    if (0 == rowData.approved_by) {
      response = "N/A";
    } else {
      response = rowData.approved_by;
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Accepted/Declined By</span>
        {response}
      </React.Fragment>
    );
  }
  requestTypeColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Request Type</span>
        Retry
      </React.Fragment>
    );
  }
  requestedByColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Requested By</span>
        {rowData.requested_by}
      </React.Fragment>
    );
  }

  institutionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Institution</span>
        {rowData.user_id}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Requested On</span>
        {rowData.modified_at}
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let status = rowData.status;
    let pTag = "";
    let statusText = "";
    if (status == "0") {
      pTag = "p-tag p-tag-warning";
      statusText = "PENDING";
    } else if (status == "1") {
      statusText = "APPROVED";
      pTag = "p-tag p-tag-success";
    } else {
      pTag = "p-tag p-tag-danger";
      statusText = "DECLINED";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{statusText}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-pencil"
          className="p-button-info p-mr-2"
          onClick={() => this.viewRetryRequestDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="Take Action"
          data-for="toolTip1"
          data-place="top"
        />
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }

  requestResponse = (event) => {
    event.preventDefault();
    console.log(this.state.buttonID);
    console.log(this.state.transId);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      trans_id: this.state.transId,
      status: this.state.buttonID,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "retry-request-status",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getRetryRequestData();
          this.setState({
            confirmationBtn: "none",
          });
          this.toastSuccess(res.data.msg);
        } else {
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  viewRetryRequestDetails(rowData) {
    console.log(rowData);
    this.setState({
      dialogRequestedBy: rowData.requested_by,
      dialogInstitution: rowData.user_id,
      dialogRequestedOn: rowData.modified_at,
      transId: rowData.trans_id,
      requestDialog: true,
    });
  }

  hideTransactionDialog() {
    console.log("heya");
    this.setState({
      confirmationBtn: "block",
    });
    this.setState({ requestDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      cities: this.props.userDetails.allCountries,
    });
    this.updateDimensions();
    this.getRetryRequestData();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  GetFilteredDate = async () => {
    if (null != this.state.rangeValue[1]) {
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        start_date: startDate,
        end_date: endDate,
        token: this.props.userDetails._id,
      };
      axios
        .post(
          global.config.main.baseUrl + "list-retry-request",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data);
            this.createDataTable(res.data.data);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };
  GetFilteredStatus = async () => {
    this.setState({
      dialogVisible: false,
      contentOpacity: 0.5,
      showLoader: "block",
    });
    if ("" == this.state.dropdownStatus) {
      this.getRetryRequestData();
      return false;
    }

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      status: this.state.dropdownStatus,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-retry-request",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getRetryRequestData = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-retry-request",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        request_type: "Retry",
        requested_by: d["requested_by"],
        modified_at: d["modified_at"],
        user_id: d["user_id"],
        trans_id: d["trans_id"],
        approved_by: d["approved_by"],
        status: d["status"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  render() {
    const dialogFooter = (
      <Button
        label="Apply"
        onClick={() => this.getSearchedData()}
        icon="pi pi-search-plus"
      />
    );
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const requestDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.retryTransactionButton,
          }}
        />
        <Button
          label="Reverse Transaction"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.reversalTransactionButton,
          }}
        />
        <Button
          label="Close"
          className="p-button-success"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.closeTransactionButton,
          }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px", width: "100%" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "left",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };

    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  <div>
                    <span className="p-tag custom-heading">Requests</span>
                  </div>
                </div>
              </div>
              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-download"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "right",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "24px",
                      display: this.state.showFiltersWeb,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-3">
                      <span className="p-float-label">
                        <Calendar
                          id="range"
                          value={this.state.rangeValue}
                          onChange={(e) =>
                            this.setState(
                              { rangeValue: e.value, dateChange: "Y" },
                              () => this.GetFilteredDate()
                            )
                          }
                          selectionMode="range"
                          readOnlyInput
                          showIcon={true}
                        />
                        <label htmlFor="calendar">DATE RANGE</label>
                      </span>
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                      <span className="p-float-label">
                        <Dropdown
                          style={filterInputBoxStyle}
                          id="dropdown1"
                          options={this.state.status}
                          value={this.state.dropdownStatus}
                          onChange={(event) =>
                            this.setState(
                              {
                                dropdownStatus: event.value,
                                statusChanged: "Y",
                              },
                              () => this.GetFilteredStatus()
                            )
                          }
                          autoWidth={false}
                        />
                        <label htmlFor="calendar">STATUS</label>
                      </span>
                    </div>
                    <div className="p-field p-col-6 p-md-2 p-md-offset-4"></div>
                  </div>
                </div>
              </div>

              <div className="p-grid">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        value={this.state.dataTableValue}
                        paginator={true}
                        rows={10}
                        ref={(el) => {
                          this.dt = el;
                        }}
                      >
                        <Column field="trans_id" style={{ display: "none" }} />
                        <Column
                          header="Action"
                          body={this.actionColumnTemplate}
                          style={{ display: "none" }}
                        ></Column>
                        <Column
                          field="request_type"
                          header="Request Type"
                          body={this.requestTypeColumnTemplate}
                        />
                        <Column
                          field="trans_id"
                          header="Transaction Id"
                          body={this.transIdColumnTemplate}
                        />
                        <Column
                          field="status"
                          header="Status"
                          body={this.statusColumnTemplate}
                        />
                        <Column
                          field="approved_by"
                          header="Accepted/Declined By"
                          body={this.apprDecByColumnTemplate}
                        />
                        <Column
                          field="modified_at"
                          header="Requested On"
                          body={this.dateColumnTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          visible={this.state.requestDialog}
          style={{ width: "450px", overflowY: "scroll" }}
          header="Confirmation"
          modal
          onHide={this.hideTransactionDialog}
        >
          <form onSubmit={this.requestResponse}>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div style={cardStyle}>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Request Type</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>Retry</label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Transaction ID</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.transId}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Requested By</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogRequestedBy}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Institution</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogInstitution}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Requested On</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogRequestedOn}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="p-grid p-fluid"
              style={{ display: this.state.confirmationBtn }}
            >
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid">
                  <div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-4 p-sm-offset-4 p-md-offset-4 p-lg-offset-4 p-xl-offset-4">
                    <Button
                      label="Decline"
                      type="submit"
                      onClick={(e) => this.setState({ buttonID: "2" })}
                      className="p-button-outlined p-button-info"
                      style={{ marginTop: "1.5em" }}
                    />
                  </div>
                  <div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                    <Button
                      label="Accept"
                      id="1"
                      onClick={(e) => this.setState({ buttonID: "1" })}
                      className="p-button-info"
                      value="decline"
                      type="submit"
                      style={{ marginTop: "1.5em" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          stylw={{ width: "450px" }}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.GetFilteredDate()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                  style={{ width: "100%" }}
                />
                <label htmlFor="calendar">DATE RANGE</label>
              </span>
            </div>
          </div>
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Dropdown
                  style={filterInputBoxStyle}
                  id="dropdown1"
                  options={this.state.status}
                  value={this.state.dropdownStatus}
                  onChange={(event) =>
                    this.setState(
                      { dropdownStatus: event.value, statusChanged: "Y" },
                      () => this.GetFilteredStatus()
                    )
                  }
                  autoWidth={false}
                />
                <label htmlFor="calendar">STATUS</label>
              </span>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(HelpDeskRequest);
