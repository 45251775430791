import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import { Provider } from 'react-redux'
import StoreConfig from './store/ConfigStore'

const ProviderComponent = () => {
    const store = StoreConfig();
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}
ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <ProviderComponent />
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('root')
);
