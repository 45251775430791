export const LogoutAction = (payload) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let result = { ...payload }
        let date = new Date();
        result.userName = "";
        result.date = date.toDateString();
        result._id = Math.random();
        result.isLogin = 'N';
        result.isLoggedOut = 'Y';
        dispatch({ type: "LOGIN_ACTION_KEY", payload: result });
        resolve({ success: true })
        // setTimeout(() => {
        //     alert("LoginAction");
        //     let result = { ...payload }
        //     let date = new Date();
        //     result.date = date.toDateString();
        //     result._id = Math.random();
        //     dispatch({ type: "LOGIN_ACTION_KEY", payload: result });
        //     resolve({ success: true })
        // }, 3000);
    });
}