import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../config";
const qs = require("query-string");

const checkHistory = "assets/layout/images/refresh.png";
const topUp = "assets/layout/images/topup.png";
const deduct = "assets/layout/images/topdown.png";
const detailsStyle = {
  fontSize: "8px",
  paddingTop: "0.8em",
  color: "rgb(189,220,254)",
};
const topupButtonStyle = {
  padding: "5px",
  background: "rgb(92, 74, 181)",
  border: "0",
  color: "#fff",
  paddingRight: "10px",
  float: "right",
  width: "80%",
};
const cardHeadingStyle = {
  color: "#5c4ab5",
  fontWeight: "600",
  position: "absolute",
};
const labelStyle = {
  paddingLeft: "10px",
  fontSize: "12px",
  color: "#999",
  fontWeight: "500",
};

class FinanceVirtualAccounts extends Component {
  constructor() {
    super();
    document.title = "Virtual Accounts - Instant Credit";
    this.state = {
      showBox: "none",
      data: [],
      items: [],
      topUpDialog: false,
      deducationDialog: false,
      institutionName: "",
      amount: "",
      vaId: "",
      purpose: "",
      accountName: "",
      topUpAmountError: "",
      topUpPurposeError: "",
      deductAmountError: "",
      deductPurposeError: "",
      deductAmount: "",
      deductPurpose: "",
      balanceAmountTooltip: "",
      requestInProgress: false,
    };
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props);
    this.getVirtualAccountData();
  }

  deductVirtualAccount = (event) => {
    event.preventDefault();

    if (this.state.requestInProgress) {
      return;
    }
    if ("" == this.state.deductAmount) {
      this.setState({
        deductAmountError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        deductAmountError: "",
      });
    }
    if ("" == this.state.deductPurpose) {
      this.setState({
        deductPurposeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        deductPurposeError: "",
      });
    }
    if (this.buttonRef.current) {
      this.buttonRef.current.disabled = true;
    }
    this.setState({ requestInProgress: true });

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      request_type: 1,
      va_id: this.state.vaId,
      amount: this.state.deductAmount,
      description: this.state.deductPurpose,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-add",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getVirtualAccountData();
          this.hideDeductionDialog();
          this.toastSuccess(res.data.msg);
          if (this.buttonRef.current) {
            this.buttonRef.current.disabled = false;
          }
          this.setState({ requestInProgress: false });
        } else {
          this.hideDeductionDialog();
          this.toastError(res.data.msg);
          if (this.buttonRef.current) {
            this.buttonRef.current.disabled = false;
          }
          this.setState({ requestInProgress: false });
        }
      })
      .catch((error) => {
        if (!error.response) {
          if (this.buttonRef.current) {
            this.buttonRef.current.disabled = false;
          }
          this.setState({
            msg: "A network error occurred",
            requestInProgress: false,
          });
        }
      });
  };

  topUpVirtualAccount = (event) => {
    console.log("TOP UP BUTTON TRIGGERED", event);
    event.preventDefault();

    if (this.state.requestInProgress) {
      return;
    }

    if ("" == this.state.amount) {
      this.setState({
        topUpAmountError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        topUpAmountError: "",
      });
    }
    if ("" == this.state.purpose) {
      this.setState({
        topUpPurposeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        topUpPurposeError: "",
      });
    }

    if (this.buttonRef.current) {
      this.buttonRef.current.disabled = true;
    }
    this.setState({ requestInProgress: true });

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      request_type: 0,
      va_id: this.state.vaId,
      amount: this.state.amount,
      description: this.state.purpose,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-add",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideTopUpDialog();
          this.toastSuccess(res.data.msg);

          if (this.buttonRef.current) {
            this.buttonRef.current.disabled = false;
          }
          this.setState({ requestInProgress: false });
        } else {
          this.hideTopUpDialog();
          this.toastError(res.data.msg);
          if (this.buttonRef.current) {
            this.buttonRef.current.disabled = false;
          }
          this.setState({ requestInProgress: false });
        }
      })
      .catch((error) => {
        if (!error.response) {
          if (this.buttonRef.current) {
            this.buttonRef.current.disabled = false;
          }
          this.setState({
            msg: "A network error occurred",
            requestInProgress: false,
          });
        }
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  navigate2VAHistory = (id) => {
    let payload = { vaid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/va-history");
      } else {
        alert("Failed");
      }
    });
  };

  openTopUpDialog = (vaId, acName) => {
    console.log(vaId);
    this.setState({
      vaId: vaId,
      accountName: acName,
    });
    this.state.topUpDialog = true;
  };

  hideTopUpDialog = () => {
    this.setState({
      purpose: "",
      amount: "",
      topUpDialog: false,
    });
  };

  openDeductDialog = (vaId, acName) => {
    this.setState({
      vaId: vaId,
      accountName: acName,
    });
    this.state.deducationDialog = true;
  };
  hideDeductionDialog = () => {
    this.setState({
      deductAmount: "",
      deductPurpose: "",
    });
    this.state.deducationDialog = false;
  };

  getVirtualAccountData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      userId: this.props.userId,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-user-va",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          if ("" == res.data.data) {
            this.toastSuccess("No Virtual Account created");
            this.setState({
              showBox: "none",
            });
          } else {
            this.setState({
              showBox: "flex",
            });
          }
          this.state.items = [];
          this.state.data = [];
          this.state.institutionName = res.data.name;
          this.state.data = res.data.data;
          this.state.data.forEach((d) => {
            let str = d["balance"];
            this.setState({
              balanceAmountTooltip: d["balance"],
            });

            let tipAmount =
              d["currency"] +
              " " +
              this.state.balanceAmountTooltip.toLocaleString();
            this.state.items.push(
              <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                  <div className="highlight-details ">
                    <span style={cardHeadingStyle}>{d["account_name"]}</span>
                    <span
                      className="count"
                      data-tip={tipAmount}
                      data-for="toolTip1"
                      data-place="top"
                      style={{
                        paddingTop: "1em",
                        wordWrap: "break-word",
                        display: "inline-block",
                        maxWidth: "100%",
                      }}
                    >
                      {d["currency"]}&nbsp;{str.toLocaleString()}
                    </span>
                    <ReactTooltip id="toolTip1" />
                    <div className="p-grid cardFooter">
                      <div className="p-col-7 p-md-8 p-lg- p-xl-6"></div>
                      <div className="p-col-5 p-md-4 p-lg-4 p-xl-6">
                        <button
                          style={topupButtonStyle}
                          onClick={() =>
                            this.openTopUpDialog(d["va_id"], d["account_name"])
                          }
                        >
                          <span>
                            <img
                              src={topUp}
                              alt="my image"
                              style={{ width: "10px" }}
                            />
                          </span>
                          <span style={{ fontSize: "12px" }}>Top up</span>
                        </button>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-7 p-md-8 p-lg- p-xl-6">
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <img
                            src={checkHistory}
                            style={{ height: "15px", width: "15px" }}
                          />
                          <Link
                            to="/va-history"
                            onClick={() => this.navigate2VAHistory(d["va_id"])}
                            style={{ color: "rgb(189, 220, 254)" }}
                          >
                            Check History
                          </Link>
                        </span>
                      </div>
                      <div className="p-col-5 p-md-4 p-lg-4 p-xl-6">
                        <button
                          style={topupButtonStyle}
                          onClick={() =>
                            this.openDeductDialog(d["va_id"], d["account_name"])
                          }
                        >
                          <span>
                            <img
                              src={deduct}
                              alt="my image"
                              style={{ width: "10px" }}
                            />
                          </span>
                          <span style={{ fontSize: "12px" }}>Deduct</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          this.setState({ items2: this.state.item });
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">
                  {this.state.institutionName}
                </span>
              </div>
            </div>
            <div className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
              <div
                className="p-grid p-fluid virtualAccount card"
                style={{ borderRadius: "20px", display: this.state.showBox }}
              >
                {this.state.items}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          visible={this.state.topUpDialog}
          style={{ width: "450px" }}
          header="Top Up Account"
          modal
          onHide={this.hideTopUpDialog}
        >
          <form onSubmit={this.topUpVirtualAccount}>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        value={this.state.accountName}
                        style={{ color: "#8c8c8c", backgroundColor: "#d4d4d4" }}
                        readOnly
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Account Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        <div className="p-inputgroup">
                          <InputText
                            type="text"
                            value={this.state.amount}
                            onChange={(e) =>
                              this.setState({ amount: e.target.value })
                            }
                            style={{
                              border: this.state.topUpAmountError,
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                            }}
                            step="any"
                            min="1"
                          />
                          <span
                            className="p-inputgroup-addon"
                            style={{
                              border: "0",
                              backgroundColor: "#5c4ab5",
                              color: "#fff",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            ₵
                          </span>
                          <label htmlFor="dropdown1" style={labelStyle}>
                            Amount
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputTextarea
                        id="purpose"
                        rows={3}
                        cols={30}
                        autoResize={true}
                        value={this.state.purpose}
                        onChange={(e) =>
                          this.setState({ purpose: e.target.value })
                        }
                        style={{ border: this.state.topUpPurposeError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Description
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Request Top Up"
                  className="p-button-info"
                  type="submit"
                  disabled={this.state.requestInProgress}
                  ref={this.buttonRef}
                  style={{ marginTop: "1.5em", width: "50%" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.deducationDialog}
          style={{ width: "450px" }}
          header="Deduct Account"
          modal
          onHide={this.hideDeductionDialog}
        >
          <form onSubmit={this.deductVirtualAccount}>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        value={this.state.accountName}
                        readOnly
                        style={{ color: "#8c8c8c", backgroundColor: "#d4d4d4" }}
                      />
                      <label style={labelStyle}>Account Name</label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        <div className="p-inputgroup">
                          <InputText
                            type="text"
                            value={this.state.deductAmount}
                            onChange={(e) =>
                              this.setState({ deductAmount: e.target.value })
                            }
                            style={{
                              border: this.state.deductAmountError,
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                            }}
                            min="1"
                          />
                          <span
                            className="p-inputgroup-addon"
                            style={{
                              border: "0",
                              backgroundColor: "#5c4ab5",
                              color: "#fff",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            ₵
                          </span>
                          <label htmlFor="dropdown1" style={labelStyle}>
                            Amount
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputTextarea
                        id="purpose"
                        rows={3}
                        cols={30}
                        autoResize={true}
                        value={this.state.deductPurpose}
                        onChange={(e) =>
                          this.setState({ deductPurpose: e.target.value })
                        }
                        style={{ border: this.state.deductPurposeError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Description
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Request Deduction"
                  className="p-button-info"
                  disabled={this.state.requestInProgress}
                  ref={this.buttonRef}
                  type="submit"
                  style={{ marginTop: "1.5em", width: "50%" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    userId: state.login.vaData.userid,
  };
};

const mapDispatchToProps = {
  vaAction: VAAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceVirtualAccounts);
