import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Link, withRouter } from "react-router-dom";
import { Toast } from "primereact/toast";
import "../../config";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Message } from "primereact/message";
import { LoginAction } from "../../store/actions/LoginAction";
import { ProgressSpinner } from "primereact/progressspinner";
import { connect } from "react-redux";
const qs = require("query-string");

class Login extends Component {
  constructor() {
    super();
    document.title = "Login - Instant Credit";
    this.state = {
      usernameError: "",
      passwordError: "",
      openDialog: false,
      email: "",
      password: "",
      msg: "",
      confirmNewPassword: "",
      newPassword: "",
      token: "",
      border: "",
      isButtonDisabled: true,
      showLoader: "none",
      contentOpacity: "1",
      severity: "warning",
      severityDisplay: "none",
      severityDisplayResetPassword: "none",
      resetPasswordMsg: "Input correct password",
      dummyNetwork: [
        { label: "MTN", value: "MTN" },
        { label: "Airteltigo", value: "Airteltigo" },
        { label: "Vodafone", value: "Vodafone" },
        { label: "Vodacom", value: "Vodacom" },
        { label: "EST", value: "EST" },
      ],
    };
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      this.checkPassword
    );
  };

  handleChangeConfirmPassword = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      this.checkPassword
    );
  };

  checkPassword = (e) => {
    if ("" != this.state.confirmNewPassword) {
      let confirmPassword = this.state.confirmNewPassword;
      let newPassword = this.state.newPassword;
      //console.log(newPassword);
      if (newPassword === confirmPassword) {
        this.setState({
          isButtonDisabled: false,
          border: "",
        });
      } else {
        this.setState({
          isButtonDisabled: true,
          border: "2px solid red",
        });
      }
    }
  };

  hideChangePasswordDialog = (e) => {
    this.setState({
      openDialog: false,
    });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "rgb(247,247,247)";
    //document.body.style.overflow = 'hidden';//22 oct 2020
  }

  HandleLoginSubmit = async (event) => {
    event.preventDefault();
    if ("" == this.state.email) {
      this.setState({
        usernameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        usernameError: "",
      });
    }
    if ("" == this.state.password) {
      this.setState({
        passwordError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        passwordError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      email: this.state.email,
      password: this.state.password,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    axios
      .post(
        global.config.main.baseUrl + "user-login",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          //console.log(res.data);
          console.log("DONE");
          if (res.data.isFirstLogin === "Y") {
            this.setState({
              openDialog: true,
              token: res.data.token,
            });
          } else {
            let payload = {
              username: res.data.name,
              userId: res.data.token,
              allCountries: res.data.co,
              type: res.data.type,
              currency: res.data.cr,
              reqcount: res.data.reqcount,
              instCount: res.data.instCount,
              instReqCount: res.data.instReqCount,
              noOfUser: res.data.noOfUser,
              noOfUserReq: res.data.noOfUserReq,
              noOfInstUser: res.data.noOfInstUser,
              network: this.state.dummyNetwork,
              rp: res.data.rp,
            };
            this.props.loginAction(payload).then((result) => {
              if (result.success) {
                //setIsLoading(false);
                this.props.history.push("/dashboard");
              } else {
                alert("Failed");
              }
            });
            // this.setState({
            //     openDialog: false,
            //     token: res.data.token,
            //     msg: res.data.msg,
            // }, window.location.href = "/data");
          }
        } else {
          //console.log(res);
          this.setState({
            severityDisplay: "block",
            severity: "error",
            msg: res.data.msg,
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  HandleResetPasswordSubmit = async (event) => {
    event.preventDefault();
    if (6 > this.state.newPassword.length) {
      this.setState({
        showPasswordErrorMsg: "flex",
        passwordErrorMsg: "* New Password must be at least 6 characters long",
      });
      return false;
    } else {
      this.setState({
        showPasswordErrorMsg: "none",
        passwordErrorMsg: "",
      });
    }
    if (!/[0-9]/.test(this.state.newPassword)) {
      this.setState({
        showPasswordErrorMsg: "flex",
        passwordErrorMsg:
          "* New Password must include at least one numeric digit",
      });
      return false;
    } else {
      if (!/[A-Z]/.test(this.state.newPassword)) {
        this.setState({
          showPasswordErrorMsg: "flex",
          passwordErrorMsg:
            "* New Password must include at least one uppercase character",
        });
        return false;
      } else {
        if (!/[a-z]/.test(this.state.newPassword)) {
          this.setState({
            showPasswordErrorMsg: "flex",
            passwordErrorMsg:
              "* New Password must include at least one lowercase character",
          });
          return false;
        } else {
          if (
            !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
              this.state.newPassword
            )
          ) {
            this.setState({
              showPasswordErrorMsg: "flex",
              passwordErrorMsg:
                "* New Password must include at least one special character",
            });
            return false;
          }
        }
      }
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.state.token,
      newpassword: this.state.newPassword,
    };
    axios
      .post(
        global.config.main.baseUrl + "user-reset",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            openDialog: false,
            password: "",
            //severityDisplay: 'block',
            //severity: 'success',
            //msg: res.data.msg,
          });
          this.toastSuccess(res.data.msg);
        } else {
          //console.log(res);
          {
            /*this.setState({
                        severityDisplay: 'block',
                        severity: 'error',
                        msg: res.data.msg,
                    });*/
          }
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            severityDisplay: "block",
            severity: "error",
            msg: "A network error occurred",
          });
        }
      });
  };

  render() {
    const cardStyle = {
      borderRadius: "40px",
      padding: "4em",
      margin: "15em",
      width: "800px",
      marginRight: "auto",
      marginLeft: "auto",
      display: "flex",
      justifyContebt: "center",
    };
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const submitButtonStyle = {
      backgroundColor: "rgb(84,67,163)",
      marginBottom: "10px",
      fontWeight: "600",
      fontFamily: "unset",
      fontSize: "16px",
      borderRadius: "10px",
      marginTop: "1em",
      height: "40px",
    };
    const pgridCustomStyle = {
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "0",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    };
    const logo = "assets/layout/images/instantCreditLogo.png";
    const dialogHeaderTextStyle = { fontSize: "1.4rem" };
    const labelStyle = { fontSize: "14px" };
    return (
      <div style={{ opacity: this.state.contentOpacity }}>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <form onSubmit={this.HandleLoginSubmit}>
          <div className="p-grid p-fluid " style={pgridCustomStyle}>
            <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-8 ">
              <div className="card card-w-title" style={cardStyle}>
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-mx-auto"
                    style={{ textAlign: "center", paddingBottom: "2em" }}
                  >
                    <img src={logo} style={{ width: "190px" }} />
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                    style={{
                      textAlign: "center",
                      display: this.state.severityDisplay,
                    }}
                  >
                    <Message
                      severity={this.state.severity}
                      text={this.state.msg}
                      style={{ fontSize: "13px" }}
                    />
                  </div>
                  <div className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3">
                    <InputText
                      id="email"
                      name="email"
                      type="text"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Username"
                      style={
                        (inputBoxStyle, { border: this.state.usernameError })
                      }
                    />
                  </div>
                  <div className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3">
                    {/*<Password id="password" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Password" style={inputBoxStyle} />*/}
                    <InputText
                      type="password"
                      id="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="Password"
                      style={
                        (inputBoxStyle, { border: this.state.passwordError })
                      }
                    />
                  </div>
                  <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-4 p-lg-offset-4 p-xl-offset-4">
                    <Button
                      type="submit"
                      label="Sign in"
                      style={submitButtonStyle}
                    />
                  </div>
                  <div
                    className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-4 p-lg-offset-4 p-xl-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    <Link
                      to="/forgot-password"
                      style={{
                        color: "rgb(84, 67, 163)",
                      }}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <Dialog
          visible={this.state.openDialog}
          style={{ width: "600px" }}
          modal
          onHide={this.hideChangePasswordDialog}
        >
          <form onSubmit={this.HandleResetPasswordSubmit}>
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2"
                style={{ textAlign: "center" }}
              >
                <label style={dialogHeaderTextStyle}>Reset your password</label>
              </div>
            </div>
            <div
              className="p-col-12 p-md-12 p-lg-12 p-xl-12"
              style={{
                textAlign: "center",
                display: this.state.severityDisplayResetPassword,
              }}
            >
              <Message
                severity="error"
                text={this.state.resetPasswordMsg}
                style={{ fontSize: "13px" }}
              />
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                <label style={labelStyle}>
                  Enter your new password (Keep your account more secure. Don't
                  use your name.)
                </label>
              </div>
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                <InputText
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  style={{ height: "36px" }}
                  placeholder="New password"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div
                className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2"
                style={{ display: this.state.showPasswordErrorMsg }}
              >
                <label
                  htmlFor="calendar"
                  style={{ fontSize: "14px", color: "red" }}
                >
                  {this.state.passwordErrorMsg}
                </label>
              </div>
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                <InputText
                  type="password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  style={{ height: "36px", border: this.state.border }}
                  placeholder="Confirm new password"
                  value={this.state.confirmNewPassword}
                  onChange={this.handleChangeConfirmPassword}
                  required
                />
              </div>
            </div>
            <div className="p-grid p-fluid" style={{ paddingBottom: "3em" }}>
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                <Button
                  type="submit"
                  disabled={this.state.isButtonDisabled}
                  label="Reset Password"
                  style={{ height: "36px", marginTop: "1.5em" }}
                  className="p-button-info"
                />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loginAction: LoginAction,
};

export default withRouter(connect(null, mapDispatchToProps)(Login));
