import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import { format } from "date-fns";
import "../../config";
const qs = require("query-string");

const viewAll = "assets/layout/images/eye.png";
const cardStyle = { borderRadius: "20px" };
const detailsStyle = {
  fontSize: "12px",
  paddingTop: "0.8em",
  color: "rgb(189,220,254)",
  float: "right",
};
const cardHeadingStyle = { color: "#5c4ab5", fontWeight: "600" };

class AllRequests extends Component {
  constructor() {
    super();
    document.title = "Requests - Instant Credit";
    this.state = {
      data: [],
      items: [],
      rangeValue: [],
      topUpDialog: false,
      deducationDialog: false,
      institutionName: "",
      amount: "",
      vaId: "",
      purpose: "",
      accountName: "",
      topUpAmountError: "",
      topUpPurposeError: "",
      deductAmountError: "",
      deductPurposeError: "",
      deductAmount: "",
      deductPurpose: "",
      instTopUpReq: "",
      instRetrievalReqCount: "",
      instCancelReqCount: "",
      showInsTopUp: "none",
    };
    let today = new Date();
    this.state.rangeValue[0] = today;
    this.state.rangeValue[1] = today;
  }

  componentDidMount() {
    this.getRequestCount();
    if ("101" == this.props.userDetails.type) {
      this.setState({
        showInsTopUp: "initial",
      });
    } else {
      this.setState({
        showInsTopUp: "none",
      });
    }
  }

  getRequestCount = async () => {
    console.log("GETTING DATA");
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const data = {
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "request-count",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.setState({
            topUpReq: res.data.topupCount,
            forexReq: res.data.forexReqCount,
            adjustmentReq: res.data.adjustmentReqCount,
            instTopUpReq: res.data.instTopUpCount,
            instRetrievalReqCount: res.data.instRetrievalReqCount,
            instCancelReqCount: res.data.instCancelReqCount,
          });
        } else {
          console.log(res.data);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">Requests</span>
              </div>
            </div>
            <div className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
              <div
                className="p-grid p-fluid virtualAccount card"
                style={cardStyle}
              >
                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>Adjustment</span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.adjustmentReq}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/adjustments"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>Forex</span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.forexReq}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/forex-request"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>Top Up</span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.topUpReq}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/top-up-requests"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="p-col-12 p-md-6 p-xl-3"
                  style={{ display: this.state.showInsTopUp }}
                >
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>Institution Top Up</span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.instTopUpReq}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/institution-topup-request"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>Retrieval Requests</span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.instRetrievalReqCount}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/retrievals"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>
                        Cancelled Transaction Requests
                      </span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.instCancelReqCount}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/cancelled-request"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    userId: state.login.vaData.userid,
  };
};

const mapDispatchToProps = {
  vaAction: VAAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllRequests);
