import React, { Component } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import "../../config";
import queryString from "query-string";
const qs = require("query-string");
class Report extends Component {
  constructor() {
    super();
    document.title = "Reports - Instant Credit";
    this.state = {
      dateError: "",
      BLError: "",
      TLError: "",
      BRError: "",
      TRError: "",
      dateErrored: "",
      BLErrored: "",
      TLErrored: "",
      BRErrored: "",
      TRErrored: "",
      start_date: "",
      end_date: "",
      fileData: "",
      fileData1: [
        { location: "232323", name: "ZZZZZZZ", phoneNumber: "2003223232" },
        { location: "23334", name: "XYZ", phoneNumber: "1234323334" },
      ],
      cities: [],
      dropdownCountry: "",
      dropDownStatusError: "",
      dropDownCountryError: "",
      dropdownCountry: "",
      dropdownStatus: "",
      status: [
        { label: "All", value: "All" },
        { label: "Pending", value: "0" },
        { label: "Success", value: "1" },
        { label: "Failed", value: "2" },
        { label: "Cancelled", value: "3" },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      cities: this.props.userDetails.allCountries,
    });
  }

  handleReportExportSubmit = async (event) => {
    event.preventDefault();
    if ("" == this.state.start_date) {
      console.log("EMPTY DATE");
      this.setState({
        dateError: "1.5px solid red",
        BLError: "10px",
        TLError: "10px",
        BRError: "4px",
        TRError: "4px",
      });
      return false;
    } else {
      this.setState({
        dateError: "",
        BLError: "",
        TLError: "",
        BRError: "",
        TRError: "",
      });
    }
    if ("" == this.state.end_date) {
      this.setState({
        dateErrored: "1.5px solid red",
        BLErrored: "10px",
        TLErrored: "10px",
        BRErrored: "4px",
        TRErrored: "4px",
      });
      return false;
    } else {
      this.setState({
        dateErrored: "",
        BLErrored: "",
        TLErrored: "",
        BRErrored: "",
        TRErrored: "",
      });
    }
    if ("" == this.state.dropdownStatus) {
      this.setState({
        dropDownStatusError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropDownStatusError: "",
      });
    }
    if ("" == this.state.dropdownCountry) {
      this.setState({
        dropDownCountryError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropDownCountryError: "",
      });
    }
    let start_date = dateFormat(this.state.start_date, "yyyy-mm-dd");
    let end_date = dateFormat(this.state.end_date, "yyyy-mm-dd");
    let status = this.state.dropdownStatus;
    let country = this.state.dropdownCountry;
    let token = this.props.userDetails._id;
    window.location.href =
      global.config.main.baseUrl +
      "export-trans-report?token=" +
      token +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&status=" +
      status +
      "&country=" +
      country;
  };

  render() {
    //const cardStyle = { borderRadius: '40px', padding: '4em', paddingTop: '3em'};INSCRED
    const cardStyle = { borderRadius: "20px" };
    const inputBoxStyle = {
      borderRadius: "10px",
      color: "rgb(180,180,180)",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const submitButtonStyle = {
      backgroundColor: "rgb(84,67,163)",
      marginBottom: "10px",
      fontWeight: "600",
      fontFamily: "unset",
      fontSize: "16px",
      borderRadius: "10px",
      marginTop: "1em",
      height: "40px",
    };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const h1Style = {
      textAlign: "center",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
      textDecoration: "underline",
    };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    return (
      <div>
        {this.state.fileData != "" ? (
          <CSVDownload
            filename="dummy.csv"
            data={this.state.fileData1}
            target="_blank"
          />
        ) : null}
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">Reports</span>
              </div>
            </div>
            <div className="p-grid p-fluid report" style={pgridCustomStyle}>
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
                <form onSubmit={this.handleReportExportSubmit}>
                  <div className="card card-w-title" style={cardStyle}>
                    <div className="p-grid">
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ textAlign: "center" }}
                      >
                        <h3 style={h3Style}>Transaction Information</h3>
                      </div>
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "5px" }}
                      >
                        <span className="p-float-label">
                          <Calendar
                            id="calendar"
                            value={this.state.start_date}
                            onChange={(e) =>
                              this.setState({ start_date: e.value })
                            }
                            showIcon={true}
                            style={{
                              border: this.state.dateError,
                              borderBottomLeftRadius: this.state.BLError,
                              borderTopLeftRadius: this.state.TLError,
                              borderBottomRightRadius: this.state.BRError,
                              borderTopRightRadius: this.state.TRError,
                            }}
                          />
                          <label htmlFor="calendar" style={labelStyle}>
                            Start Date
                          </label>
                        </span>
                      </div>
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Calendar
                            id="calendar"
                            value={this.state.end_date}
                            onChange={(e) =>
                              this.setState({ end_date: e.value })
                            }
                            showIcon={true}
                            style={{
                              border: this.state.dateErrored,
                              borderBottomLeftRadius: this.state.BLErrored,
                              borderTopLeftRadius: this.state.TLErrored,
                              borderBottomRightRadius: this.state.BRErrored,
                              borderTopRightRadius: this.state.TRErrored,
                            }}
                          />
                          <label htmlFor="calendar" style={labelStyle}>
                            End Date
                          </label>
                        </span>
                      </div>
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Dropdown
                            options={this.state.status}
                            value={this.state.dropdownStatus}
                            onChange={(event) =>
                              this.setState({ dropdownStatus: event.value })
                            }
                            autoWidth={false}
                            style={{ border: this.state.dropDownStatusError }}
                          />
                          <label htmlFor="dropdown1" style={labelStyle}>
                            Status
                          </label>
                        </span>
                      </div>
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Dropdown
                            options={this.state.cities}
                            value={this.state.dropdownCountry}
                            onChange={(event) =>
                              this.setState({ dropdownCountry: event.value })
                            }
                            autoWidth={false}
                            style={{ border: this.state.dropDownCountryError }}
                          />
                          <label htmlFor="dropdown1" style={labelStyle}>
                            Country
                          </label>
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-4 p-lg-offset-4 p-xl-offset-4">
                        {/*<Button icon="pi pi-download" label="Export" style={submitButtonStyle} />*/}
                        <Button
                          type="submit"
                          label="Export"
                          style={submitButtonStyle}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(Report);
