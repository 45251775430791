import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../config";

import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
//const cookies = new Cookies();
class FinanceDashboard extends Component {
  constructor() {
    super();
    document.title = "Dashboard - Instant Credit";
    let today1 = new Date();
    let month1 = today1.getMonth();
    let year = today1.getFullYear();
    this.state = {
      forexTable: "",
      forexTableValueNew: "",
      forexValue: "",
      forexTableNew: "",
      dataTableDummyValue: "",

      value3: "",
      rangeValue: [],
      msg: "",
      successfulTransactionCount: "0",
      successfulTransactionAmount: "0",
      failedTransactionCount: "0",
      failedTransactionAmount: "0",
      countriesPresent: "0",
      errorBlock: "none",
      errorMsg: "",
      graphData: [],
      graphLabel: [],
      lineData: {},
      tasks: [],
      month: month1 + "/" + year,
      city: null,
      noOfInstitution: "0",
      transPending: "0",
      transPendingAmountSum: "0",
      showLoader: "none",
      contentOpacity: "1",
      successfulTransactionAmountTooltip: "",
      failedTransactionAmountTooltip: "",
      transPendingAmountSumTooltip: "",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = today;
    this.state.rangeValue[1] = today;
    this.fromColumnTemplate = this.fromColumnTemplate.bind(this);
    this.toColumnTemplate = this.toColumnTemplate.bind(this);
    this.rateColumnTemplate = this.rateColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.instColumnTemplate = this.instColumnTemplate.bind(this);
  }

  componentDidMount = () => {
    this.getFilteredData();
  };

  toColumnTemplate(rowData) {
    let toCurrency = "USD";
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Destination</span>
        <span className={pTag} style={{ fontSize: "10px" }}>
          {rowData.to_currency}
        </span>
      </React.Fragment>
    );
  }

  fromColumnTemplate(rowData) {
    let fromCurrency = "INR";
    return (
      <React.Fragment>
        <span className="p-column-title">Source</span>
        <span>{rowData.from_currency}</span>
      </React.Fragment>
    );
  }

  rateColumnTemplate(rowData) {
    let rate = "5%";
    return (
      <React.Fragment>
        <span className="p-column-title">Rate</span>
        <span>{rowData.rate}</span>
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    let date = "17/09/2020";
    return (
      <React.Fragment>
        <span className="p-column-title" style={{ paddingLeft: "22px" }}>
          Date
        </span>
        <span>{rowData.modified_at}</span>
      </React.Fragment>
    );
  }

  instColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title" style={{ paddingLeft: "22px" }}>
          Institution
        </span>
        <span>{rowData.inst}</span>
      </React.Fragment>
    );
  }

  CreateGraph() {
    let lineChartData = {
      //labels: ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: this.state.graphLabel,
      datasets: [
        {
          label: "MONTHLY TRANSACTION HISTORY",
          data: this.state.graphData,
          fill: false,
          borderColor: "rgb(99,208,242)",
          lineTension: "0",
          borderWidth: "3",
          pointBackgroundColor: "rgb(70,101,217)",
          pointBorderWidth: "5",
        },
      ],
    };
    this.setState({
      lineData: lineChartData,
    });
  }

  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }

  getFilteredData = async () => {
    {
      /*let date_format = dateFormat(this.state.value3, "yyyy-mm-dd");*/
    }
    if (null != this.state.rangeValue[1]) {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      console.log(startDate);
      console.log(endDate);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        start_date: startDate,
        end_date: endDate,
        token: this.props.userDetails._id,
      };
      axios
        .post(
          global.config.main.baseUrl + "dashboard",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            console.log(res.data);
            let str = res.data.amountSuccess;
            str = Number(str);
            str = str.toLocaleString();
            this.setState({
              successfulTransactionAmountTooltip: "₵" + res.data.amountSuccess,
            });

            let str1 = res.data.amountFailed;
            str1 = str1.toLocaleString();
            this.setState({
              failedTransactionAmountTooltip: "₵" + res.data.amountFailed,
            });

            let str2 = res.data.transPendingAmountSum;
            str2 = str2.toLocaleString();

            this.setState({
              transPendingAmountSumTooltip:
                "₵" + res.data.transPendingAmountSum,
            });

            this.setState({
              errorBlock: "none",
              errorMsg: "",
              successfulTransactionCount: res.data.successTransCount,
              successfulTransactionAmount: str,
              failedTransactionCount: res.data.failedTransCount,
              failedTransactionAmount: str1,
              countriesPresent: res.data.countryCount,
              noOfInstitution: res.data.noOfInstitution,
              transPending: res.data.transPending,
              transPendingAmountSum: str2,
            });
            let chartLabel = res.data.graph.label;
            let chartData = res.data.graph.data;
            this.GraphLabelData(chartLabel, chartData);
            this.createForexTable(res.data.forexRate);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          } else {
            this.toastError(res.data.msg);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              errorBlock: "block",
              errorMsg: "A network error occurred",
            });
          }
        });
    }
  };
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  getDashboardData = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "dashboard",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          let str = res.data.amountSuccess;
          str = str.toLocaleString();

          this.setState({
            successfulTransactionAmountTooltip:
              "₵" + res.data.amountSuccess.toLocaleString(),
          });

          let str1 = res.data.amountFailed;
          str1 = str1.toLocaleString();

          this.setState({
            failedTransactionAmountTooltip:
              "₵" + res.data.amountFailed.toLocaleString(),
          });

          let str2 = res.data.transPendingAmountSum;
          str2 = str2.toLocaleString();

          this.setState({
            transPendingAmountSumTooltip:
              "₵" + res.data.transPendingAmountSum.toLocaleString(),
          });

          this.setState({
            errorBlock: "none",
            errorMsg: "",
            successfulTransactionCount: res.data.successTransCount,
            successfulTransactionAmount: str,
            failedTransactionCount: res.data.failedTransCount,
            failedTransactionAmount: str1,
            countriesPresent: res.data.countryCount,
            noOfInstitution: res.data.noOfInstitution,
            transPending: res.data.transPending,
            transPendingAmountSum: str2,
          });
          let chartLabel = res.data.graph.label;
          let chartData = res.data.graph.data;
          this.GraphLabelData(chartLabel, chartData);
          this.createForexTable(res.data.forexRate);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.toastError(res.data.msg);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            errorBlock: "block",
            errorMsg: "A network error occurred",
          });
        }
      });
  };
  createForexTable(data) {
    this.state.forexTableValueNew = [];
    this.state.forexValue = [];
    this.state.forexTableValueNew = data;
    console.log("FOREX DATA");
    console.log(this.state.forexTableValueNew);
    this.state.forexTableValueNew.forEach((d) => {
      this.state.forexValue.push({
        from_currency: d["from_currency"],
        to_currency: d["to_currency"],
        rate: d["rate"],
        modified_at: d["modified_at"],
        inst: d["institution_id"]["name"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.forexValue });
  }
  GraphLabelData = (graphLabel, graphData) => {
    this.setState({
      graphLabel: graphLabel,
      graphData: graphData,
    });
    this.CreateGraph();
  };

  render() {
    const checkedLogo = "assets/layout/images/checked_new.png";
    const crossLogo = "assets/layout/images/cross_new.png";
    const filterInputBoxStyle = { borderRadius: "4px" };
    return (
      <div style={{ opacity: this.state.contentOpacity }}>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">Dashboard</span>
                {/*<h1 style={{ fontWeight: '600', color: 'rgb(92,74,181)', textDecoration: 'underline' }}>Dashboard</h1>*/}
              </div>
            </div>
            <div className="p-grid p-fluid" style={{ marginTop: "35px" }}>
              {/*<div className="p-field p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-2 p-xl-offset-1">*/}
              <div className="p-col-12 p-md-4 p-lg-3 p-xl-3 p-lg-offset-1 p-xl-offset-1">
                <span className="p-float-label">
                  {/*<Calendar inputStyle={filterInputBoxStyle} id="calendar" value={this.state.today} onChange={(e) => this.setState({ value3: e.value }, () => this.getFilteredData())} showIcon={true} style={{ width: '100%' }} />*/}
                  <Calendar
                    id="range"
                    value={this.state.rangeValue}
                    onChange={(e) =>
                      this.setState({ rangeValue: e.value }, () =>
                        this.getFilteredData()
                      )
                    }
                    selectionMode="range"
                    readOnlyInput
                    showIcon={true}
                  />
                  <label htmlFor="calendar">DATE RANGE</label>
                </span>
              </div>
            </div>
            <div className="p-grid p-fluid dashboard">
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2 p-lg-offset-1 p-xl-offset-1">
                <div className="highlight-box">
                  <div
                    className="highlight-details "
                    style={{ minHeight: "8.5em" }}
                  >
                    <span className="span-custom-success">
                      <img
                        alt="Logo"
                        src={checkedLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      Count of Successful Transaction
                    </span>
                    <span className="count" style={{ paddingTop: "1em" }}>
                      {this.state.successfulTransactionCount}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2">
                <div className="highlight-box">
                  {/*<div className="initials" style={{backgroundColor:'#ef6262',color:'#a83d3b'}}><span>TI</span></div>*/}
                  <div
                    className="highlight-details "
                    style={{ minHeight: "8.5em" }}
                  >
                    <span className="span-custom-failed">
                      <img
                        alt="Logo"
                        src={crossLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      Count of Failed Transactions
                    </span>
                    <span
                      className="count"
                      style={{
                        paddingTop: "1em",
                        textAlign: "center",
                        color: "orange",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.failedTransactionCount}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2">
                <div className="highlight-box">
                  <div
                    className="highlight-details "
                    style={{
                      minHeight: "8.99em",
                      maxWidth: "100%",
                      overflow: "auto",
                    }}
                  >
                    <span className="span-custom-success">
                      <img
                        alt="Logo"
                        src={checkedLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      Amount of Successful Transactions
                    </span>
                    {/*<span className="count" style={{ marginTop: '4em',marginTop:'4em',fontSize:'12px'}}>₵{this.state.successfulTransactionAmount}</span>*/}
                    <span
                      className="count"
                      data-tip={this.state.successfulTransactionAmountTooltip}
                      data-for="toolTip1"
                      data-place="top"
                      style={{
                        paddingTop: "1em",
                        textAlign: "center",
                        color: "orange",
                        fontWeight: "600",
                        wordWrap: "break-word",
                        display: "inline-block",
                        maxWidth: "100%",
                      }}
                    >
                      ₵{this.state.successfulTransactionAmount}
                    </span>
                    <ReactTooltip id="toolTip1" />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2">
                <div className="highlight-box">
                  <div
                    className="highlight-details "
                    style={{
                      minHeight: "8.99em",
                      maxWidth: "100%",
                      overflow: "auto",
                    }}
                  >
                    <span className="span-custom-failed">
                      <img
                        alt="Logo"
                        src={crossLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      Amount of Failed Transactions
                    </span>
                    {/*<span className="count" style={{ paddingTop: '1em', textAlign: 'center', color: 'orange', fontWeight: '600' }}>₵{this.state.failedTransactionAmount}</span>*/}
                    <span
                      className="count"
                      data-tip={this.state.failedTransactionAmountTooltip}
                      data-for="toolTip1"
                      data-place="top"
                      style={{
                        paddingTop: "1em",
                        textAlign: "center",
                        color: "orange",
                        fontWeight: "600",
                        wordWrap: "break-word",
                        display: "inline-block",
                        maxWidth: "100%",
                      }}
                    >
                      ₵{this.state.failedTransactionAmount}
                    </span>
                    <ReactTooltip id="toolTip1" />
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2">
                <div className="highlight-box">
                  <div
                    className="highlight-details "
                    style={{ minHeight: "8.5em" }}
                  >
                    <span className="span-custom-success">
                      <img
                        alt="Logo"
                        src={checkedLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      Countries Present
                    </span>
                    <span
                      className="count"
                      style={{
                        paddingTop: "1.1em",
                        textAlign: "center",
                        color: "rgb(92,74,181)",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.countriesPresent}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2 p-lg-offset-1 p-xl-offset-1">
                <div className="highlight-box">
                  <div
                    className="highlight-details "
                    style={{ minHeight: "8.5em" }}
                  >
                    <span className="span-custom-success">
                      <img
                        alt="Logo"
                        src={checkedLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      No. of Institutions
                    </span>
                    <span
                      className="count"
                      style={{
                        paddingTop: "1.1em",
                        textAlign: "center",
                        color: "rgb(92,74,181)",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.noOfInstitution}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2">
                <div className="highlight-box">
                  <div
                    className="highlight-details "
                    style={{ minHeight: "8.5em" }}
                  >
                    <span className="span-custom-success">
                      <img
                        alt="Logo"
                        src={checkedLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      Count of Pending Transactions
                    </span>
                    <span
                      className="count"
                      style={{
                        paddingTop: "1.1em",
                        textAlign: "center",
                        color: "rgb(92,74,181)",
                        fontWeight: "600",
                      }}
                    >
                      {this.state.transPending}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-2 p-xl-2">
                <div className="highlight-box">
                  <div
                    className="highlight-details "
                    style={{
                      minHeight: "8.99em",
                      maxWidth: "100%",
                      overflow: "auto",
                    }}
                  >
                    <span className="span-custom-success">
                      <img
                        alt="Logo"
                        src={checkedLogo}
                        style={{ height: "20px", width: "20px" }}
                      />
                      Amount of Pending Transactions
                    </span>
                    {/*<span className="count" style={{ paddingTop: '1.1em', textAlign: 'center', color: 'rgb(92,74,181)', fontWeight: '600' }}>₵{this.state.transPendingAmountSum}</span>*/}
                    <span
                      className="count"
                      data-tip={this.state.transPendingAmountSumTooltip}
                      data-for="toolTip1"
                      data-place="top"
                      style={{
                        paddingTop: "1em",
                        textAlign: "center",
                        color: "orange",
                        fontWeight: "600",
                        wordWrap: "break-word",
                        display: "inline-block",
                        maxWidth: "100%",
                      }}
                    >
                      ₵{this.state.transPendingAmountSum}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid dashboard">
              <div className="p-col-12 p-lg-6 p-xl-6 p-lg-offset-1 p-xl-offset-1">
                <div className="card">
                  <Chart type="line" data={this.state.lineData} />
                </div>
              </div>
              <div className="p-col-12 p-lg-4 p-xl-4">
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <DataTable
                      className="p-datatable-responsive-demo"
                      resizableColumns
                      columnResizeMode="expand"
                      value={this.state.forexValue}
                      paginator={false}
                      rows={3}
                      ref={(el) => {
                        this.dt = el;
                      }}
                      header="Forex Rate"
                    >
                      <Column
                        field="institution"
                        header="Institution"
                        style={{
                          fontSize: "11px",
                          paddingLeft: "0",
                          backgroundColor: "#fff",
                        }}
                        body={this.instColumnTemplate}
                      />
                      <Column
                        style={{ fontSize: "11px", backgroundColor: "#fff" }}
                        field="from_currency"
                        header="Source"
                        body={this.fromColumnTemplate}
                      />
                      <Column
                        style={{ fontSize: "11px", backgroundColor: "#fff" }}
                        field="to_currency"
                        header="Destination"
                        body={this.toColumnTemplate}
                      />
                      <Column
                        style={{ fontSize: "11px", backgroundColor: "#fff" }}
                        field="rate"
                        header="Rate"
                        body={this.rateColumnTemplate}
                      />
                      {/* <Column style={{fontSize:'11px',backgroundColor:'#fff',paddingLeft: '0'}} field="modified_at" header="Date"  body={this.dateColumnTemplate}  /> */}
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(FinanceDashboard);
