import { Component } from "react";
import { LogoutAction } from "../../store/actions/LogoutAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Logout extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.logoutAction(this.props.payload).then((result) => {
      if (result.success) {
        this.props.history.push("/login");
      } else {
        alert("Failed");
      }
    });
  }

  render() {
    return "";
  }
}

const mapDispatchToProps = {
  logoutAction: LogoutAction,
};

export default withRouter(connect(null, mapDispatchToProps)(Logout));
