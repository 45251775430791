import React, { Component } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactTooltip from "react-tooltip";
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { connect } from "react-redux";
import "../../config";
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
const floatLabel = {
  fontWeight: "500",
  color: "rgb(153, 153, 153)",
  fontSize: "12px",
};
class PartnerConfiguration extends Component {
  constructor() {
    super();
    document.title = "Remittance Partners- Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      requestType: [
        { label: "POST", value: "post" },
        { label: "GET", value: "get" },
      ],
      country: [
        { label: "Afghanistan", value: "AFGHANISTAN" },
        { label: "Angola", value: "ANGOLA" },
        { label: "Argentina", value: "ARGENTINA" },
        { label: "Australia", value: "AUSTRALIA" },
        { label: "Austria", value: "AUSTRIA" },
        { label: "Ghana", value: "GHANA" },
        { label: "Nigeria", value: "NIGERIA" },
        { label: "India", value: "INDIA" },
      ],
      networkError: "",
      editNetworkError: "",
      editDescription: "",
      dropdownNetwork: "",
      dialogShortNameError: "",
      dialogRegionError: "",
      dialogSubRegionError: "",
      dialogSubRegionError: "",
      dropdownStatus: "",
      shortName: "",
      dropdownCountry: "",
      countryError: "",
      currency: "",
      currencyError: "",
      currencyError: "",
      shortNameError: "",
      region: "",
      regionError: "",
      subRegion: "",
      subRegionError: "",
      dialogEditCountryName: "",
      dialogCurrency: "",
      dialogSubRegion: "",
      dialogRegion: "",
      description: "",
      dialogNetwork: "",

      dialogShortName: "",

      countryId: "",
      showEdit: "",
      showFiltersWeb: "none",
      showFiltersMob: "none",
      dataTableValue: [],
      providerDialog: "",
      dataTableValueNew: [],
      dataViewValue: [],

      networkSuggestion: null,
      showLoader: "none",
      contentOpacity: "1",
      rangeValue: [],
      showConfigureApi: "",
      liveUrl: "",
      liveUrlError: "",
      liveKey: "",
      liveKeyError: "",
      sandBoxUrl: "",
      sandBoxUrlError: "",
      sandboxKey: "",
      sandboxKeyError: "",
      sandboxApiEnable: true,
      liveUrlEnable: false,
      networkId: "",

      name: "",
      liveUrl: "",
      testUrl: "",
      userName: "",
      password: "",
      pin: "",
      nameError: "",
      liveUrlError: "",
      testUrlError: "",
      userNameError: "",
      passwordError: "",
      pinError: "",
      editName: "",
      editLiveUrl: "",
      editTestUrl: "",
      editUserName: "",
      editPassword: "",
      editPin: "",
      editNameError: "",
      editLiveUrlError: "",
      editTestUrlError: "",
      editUserNameError: "",
      editPasswordError: "",
      editPinError: "",
      entryId: "",
      editLiveUrlEnable: "",
      viewDialogLiveUrl: "",
      viewDialogDate: "",
      viewDialogName: "",
      viewDialogPassword: "",
      viewDialogPin: "",
      viewDialogTestUrl: "",
      viewDialogUsername: "",
      rp: [],
      testUserName: "",
      testUserNameError: "",
      testPassword: "",
      testPasswordError: "",
      testPin: "",
      testPinError: "",
      editLUserName: "",
      editLPassword: "",
      editLPin: "",
      editTUserName: "",
      editTPassword: "",
      editTPin: "",
      entryId: "",
      dialogStatusText: "",
      dialogStatus: "",
      showErrorMsg: "none",
      errorMsg: "",
      showEditErrorMsg: "",
      errorEditMsg: "",
      searchFilter: [{ label: "Name", value: "name" }],
      dropdownSearchFilter: "",
      searchValue: "",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = today;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.editCountryDialog = this.editCountryDialog.bind(this);
    this.nameColumnTemplate = this.nameColumnTemplate.bind(this);
    this.shortNameColumnTemplate = this.shortNameColumnTemplate.bind(this);
    this.subRegionColumnTemplate = this.subRegionColumnTemplate.bind(this);
    this.currencyColumnTemplate = this.currencyColumnTemplate.bind(this);
    this.currencySymbolColumnTemplate =
      this.currencySymbolColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.regionColumnTemplate = this.regionColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.networkColumnTemplate = this.networkColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);

    this.networks = [
      "Azercell",
      "Bakcell",
      "Nar",
      "Nakhtel",
      "MTS",
      "A1",
      "Proximus",
      "Orange",
      "BASE",
      "BH Telecom",
      "HT Eronet",
      "Vivacom",
      "Telenor",
      "MTN",
      "Airteltigo",
      "Vodafone",
    ];
  }
  enableSandbox = () => {
    this.setState({
      sandboxApiEnable: true,
      liveUrlEnable: false,
    });
  };
  enableLive = () => {
    this.setState({
      sandboxApiEnable: false,
      liveUrlEnable: true,
    });
  };
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  suggestNetwork(event) {
    let results = this.networks.filter((dropdownNetwork) => {
      return dropdownNetwork
        .toLowerCase()
        .startsWith(event.query.toLowerCase());
    });

    this.setState({ networkSuggestion: results });
  }

  componentDidMount() {
    console.log("RP");
    console.log(this.props.userDetails.rp);
    this.setState({
      rp: this.props.userDetails.rp,
    });
    if ("0" != this.props.userDetails.type) {
      this.setState({
        showEdit: "none",
      });
    } else {
      this.setState({
        showEdit: "inherit",
      });
    }
    this.updateDimensions();
    this.getRemitPartners();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  editRemitPartner = (event) => {
    event.preventDefault();
    console.log(this.state.entryId);
    console.log(this.state.edit);
    if ("" == this.state.editName) {
      this.setState({
        editNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        editNameError: "",
      });
    }
    if ("" == this.state.editLiveUrl) {
      this.setState({
        editLiveUrlError: "1.5px solid red",
      });
      return false;
    } else {
      if (!this.state.editLiveUrl.includes("http")) {
        this.setState({
          showEditErrorMsg: "initial",
          errorEditMsg: "* Please enter a valid URL",
          editLiveUrlError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          showEditErrorMsg: "none",
          errorEditMsg: "",
          editLiveUrlError: "",
        });
      }
    }
    {
      /*if('' == this.state.editLUserName)
		{
			this.setState({
				editLUserNameError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				editLUserNameError:'',
			});
		}
		if('' == this.state.editLPassword)
        {
            this.setState({
                editLPasswordError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
               	editLPasswordError:'',
            });
        }
		if('' == this.state.editLPin)
        {
            this.setState({
                editLPinError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
                editLPinError:'',
            });
        }
		if('' == this.state.editTestUrl)
		{
			this.setState({
				editTestUrlError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				editTestUrlError:'',
			});
		}
		if('' == this.state.editTUserName)
		{
			this.setState({
				editTUserNameError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				editTUserNameError:'',
			});
		}
		if('' == this.state.editTPassword)
        {
            this.setState({
                editTPasswordError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
               	editTPasswordError:'',
            });
        }
		if('' == this.state.editTPin)
        {
            this.setState({
                editTPinError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
                editTPinError:'',
            });
        }
		let editLiveEnabled;
		if(true == this.state.editLiveUrlEnable)
		{
			editLiveEnabled='1';
		}
		else
		{
			editLiveEnabled='0';
		}
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
			name : this.state.editName,
			live_url: this.state.editLiveUrl,
			test_url: this.state.editTestUrl,
			l_username: this.state.editLUserName,
			l_password: this.state.editLPassword,
			l_pin: this.state.editLPin,
			t_username: this.state.editTUserName,
			t_password: this.state.editTPassword,
			t_pin: this.state.editTPin,
			entry_id:this.state.entryId,
			is_enabled:editLiveEnabled,
        }*/
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      name: this.state.editName,
      live_url: this.state.editLiveUrl,
      entry_id: this.state.entryId,
    };
    axios
      .post(global.config.main.baseUrl + "rp/edit", qs.stringify(data), config)
      .then((res) => {
        if (res.data.status === 200) {
          this.hideEditCountryDialog();
          this.toastSuccess(res.data.msg);
          this.getRemitPartners();
        } else {
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  addRemitPartner = (event) => {
    event.preventDefault();
    console.log(this.state.name);
    console.log(this.state.liveUrl);
    console.log(this.state.userName);
    console.log(this.state.password);
    console.log(this.state.pin);
    console.log(this.state.testUrl);
    console.log(this.state.testUserName);
    console.log(this.state.testPassword);
    console.log(this.state.testPin);
    if ("" == this.state.name) {
      this.setState({
        nameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        nameError: "",
      });
    }
    if ("" == this.state.liveUrl) {
      this.setState({
        liveUrlError: "1.5px solid red",
      });
      return false;
    } else {
      if (!this.state.liveUrl.includes("http")) {
        this.setState({
          showErrorMsg: "initial",
          errorMsg: "* Please enter a valid URL",
          liveUrlError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          showErrorMsg: "none",
          errorMsg: "",
          liveUrlError: "",
        });
      }
    }
    {
      /*if('' == this.state.userName)
		{
			this.setState({
				userNameError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				userNameError:'',
			});
		}
		if('' == this.state.password)
        {
            this.setState({
                passwordError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
                passwordError:'',
            });
        }
		if('' == this.state.pin)
        {
            this.setState({
                pinError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
                pinError:'',
            });
        }
		if('' == this.state.testUrl)
		{
			this.setState({
				testUrlError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				testUrlError:'',
			});
		}
		if('' == this.state.testUserName)
		{
			this.setState({
				testUserNameError:'1.5px solid red',
			});
			return false;
		}
		else
		{
			this.setState({
				testUserNameError:'',
			});
		}
		if('' == this.state.testPassword)
        {
            this.setState({
                testPasswordError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
                testPasswordError:'',
            });
        }
		if('' == this.state.testPin)
        {
            this.setState({
                testPinError:'1.5px solid red',
            });
            return false;
        }
        else
        {
            this.setState({
                testPinError:'',
            });
        }
		let liveEnabled;
		if(true == this.state.liveUrlEnable)
		{
			liveEnabled='1';
		}
		else
		{
			liveEnabled='0';
		}
        const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token: this.props.userDetails._id,
			name : this.state.name,
			live_url: this.state.liveUrl,
			test_url: this.state.testUrl,
			l_username: this.state.userName,
			l_password: this.state.password,
			l_pin: this.state.pin,
			t_username: this.state.testUserName,
			t_password: this.state.testPassword,
			t_pin: this.state.testPin,
			is_enabled:liveEnabled,
        }*/
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      name: this.state.name,
      live_url: this.state.liveUrl,
    };
    axios
      .post(global.config.main.baseUrl + "rp/add", qs.stringify(data), config)
      .then((res) => {
        if (res.data.status === 200) {
          this.hideAddRemitPartnerDialog();
          this.toastSuccess(res.data.msg);
          this.getRemitPartners();
          this.setState({
            liveUrl: "",
            testUrl: "",
            name: "",
            userName: "",
            password: "",
            pin: "",
            liveUrlEnable: false,
          });
        } else {
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  updateNetwork = (event) => {
    event.preventDefault();
    console.log(this.state.dialogEditCountryName);
    console.log(this.state.countryId);
    console.log(this.state.dialogNetwork);
    console.log(this.state.editDescription);
    if ("" == this.state.dialogNetwork) {
      this.setState({
        editNetworkError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        editNetworkError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      country: this.state.dialogEditCountryName,
      name: this.state.dialogNetwork,
      description: this.state.editDescription,
      entry_id: this.state.countryId,
    };
    axios
      .post(
        global.config.main.baseUrl + "network/edit",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideEditCountryDialog();
          this.toastSuccess(res.data.msg);
          this.getRemitPartners();
        } else {
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    else this.state.dialogVisible = false;
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(global.config.main.baseUrl + "rp/list", qs.stringify(data), config)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          console.log(res.data);
          this.createDataTable(res.data.data);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getRemitPartners = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      //start_date: startDate,
      //end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(global.config.main.baseUrl + "rp/list", qs.stringify(data), config)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          console.log(res.data);
          this.createDataTable(res.data.data);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    console.log("DATATABLE VALUE");
    console.log(data);
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        id: d["id"],
        is_enabled: d["is_enabled"],
        live_url: d["live_url"],
        modified_at: d["modified_at"],
        name: d["name"],
        l_password: d["l_password"],
        l_pin: d["l_pin"],
        requested_by: d["requested_by"],
        requested_by_name: d["requested_by_name"],
        status: d["status"],
        test_url: d["test_url"],
        l_username: d["l_username"],
        t_password: d["t_password"],
        t_username: d["t_username"],
        t_pin: d["t_pin"],
        url: d["live_url"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  nameColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        <span className={pTag}>{rowData.name}</span>
      </React.Fragment>
    );
  }
  networkColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Network</span>
        {rowData.network}
      </React.Fragment>
    );
  }

  shortNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Short Name</span>
        {rowData.short_name}
      </React.Fragment>
    );
  }

  regionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Region</span>
        {rowData.region}
      </React.Fragment>
    );
  }

  subRegionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Sub Region</span>
        {rowData.sub_region}
      </React.Fragment>
    );
  }

  currencyColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Currency</span>
        {rowData.currency}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        {rowData.modified_at}
      </React.Fragment>
    );
  }

  currencySymbolColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    let currencySymbol = "";
    if ("INR" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else if ("GHC" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else if ("NI" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else {
      currencySymbol = "&#x20B9;";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Currency Symbol</span>
        <span style={{ width: "40%" }} className={pTag}>
          {rowData.currency}
        </span>
      </React.Fragment>
    );
  }

  statusColumnTemplate1(rowData) {
    let pTag = "";
    let enabled = "";
    if (rowData.is_enabled == "1") {
      enabled = "Live";
      pTag = "p-tag p-tag-success";
    } else {
      enabled = "Test";
      pTag = "p-tag p-tag-success";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">URL</span>
        <span className={pTag}>{enabled}</span>
      </React.Fragment>
    );
  }
  statusColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">URL</span>
        <span style={{ wordBreak: "break-all" }}>{rowData.url}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-pencil"
          className="p-button-info p-mr-2"
          onClick={() => this.editCountryDialog(rowData)}
          style={{
            fontSize: "15px",
            padding: "7px",
            display: this.state.showEdit,
          }}
          data-tip="Edit Remittance Partner"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <Button
          label=""
          icon="pi pi-eye"
          className="p-button-success p-mr-2"
          onClick={() => this.viewProviderDetails(rowData)}
          style={{ border: "0", fontSize: "15px", padding: "7px" }}
          data-tip="View Remittance Partner Details"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        {/*<Button label="" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={() => this.showAddRemitPartnerDialog(rowData)} style={{ fontSize: '15px', padding: '7px'}} data-tip="Configure API" data-for='toolTip1' data-place='top' />&nbsp;*/}
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }
  viewProviderDetails = (rowData) => {
    console.log(rowData);
    if ("1" == rowData.is_enabled) {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Live URL Enabled",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Test URL Enabled",
      });
    }
    this.setState({
      viewDialogLiveUrl: rowData.live_url,
      viewDialogDate: rowData.modified_at,
      viewDialogName: rowData.name,
      viewDialogLPassword: rowData.l_password,
      viewDialogLPin: rowData.l_pin,
      viewDialogLUsername: rowData.l_username,
      viewDialogTPassword: rowData.t_password,
      viewDialogTPin: rowData.t_pin,
      viewDialogTUsername: rowData.t_username,
      viewDialogTestUrl: rowData.test_url,
      providerDialog: true,
    });
  };
  hideProviderDetails = (rowData) => {
    this.setState({
      providerDialog: false,
    });
  };
  editCountryDialog(rowData) {
    console.log(rowData);
    if (0 == rowData.is_enabled) {
      this.setState({
        editLiveUrlEnable: false,
      });
    } else if (1 == rowData.is_enabled) {
      this.setState({
        editLiveUrlEnable: true,
      });
    }
    this.setState({
      editName: rowData.name,
      editLiveUrl: rowData.live_url,
      editTestUrl: rowData.test_url,
      editLUserName: rowData.l_username,
      editLPassword: rowData.l_password,
      editLPin: rowData.l_pin,
      editTUserName: rowData.t_username,
      editTPassword: rowData.t_password,
      editTPin: rowData.t_pin,
      entryId: rowData.id,
      editCountryDialog: true,
    });
  }
  showAddRemitPartnerDialog = (rowData) => {
    this.setState({ showConfigureApi: true });
  };
  hideAddRemitPartnerDialog = (rowData) => {
    console.log(rowData);
    this.setState({
      name: "",
      liveUrl: "",
      showConfigureApi: false,
    });
  };

  hideEditCountryDialog = () => {
    this.setState({ editCountryDialog: false });
  };

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getRemitPartnersByDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        start_date: startDate,
        end_date: endDate,
        token: this.props.userDetails._id,
      };
      axios
        .post(
          global.config.main.baseUrl + "rp/list",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
            this.createDataTable(res.data.data);
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };

  getSearchedData = async () => {
    this.state.dialogVisible = false;
    this.getTransactionDataSearchValue();
  };

  openAddForexDialog = () => {
    this.state.addNetworkDialog = true;
  };

  hideAddForexDialog = () => {
    this.state.addNetworkDialog = false;
  };

  addNetwork = (event) => {
    event.preventDefault();
    console.log(this.state.dropdownNetwork);
    if ("" == this.state.dropdownCountry) {
      this.setState({
        countryError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        countryError: "",
      });
    }
    if ("" == this.state.dropdownNetwork) {
      this.setState({
        networkError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        networkError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      country: this.state.dropdownCountry,
      name: this.state.dropdownNetwork,
      description: this.state.description,
    };
    axios
      .post(
        global.config.main.baseUrl + "network/add",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getRemitPartners();
          this.hideAddForexDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            dropdownCountry: "",
            dropdownNetwork: "",
          });
        } else {
          this.hideAddForexDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  render() {
    const addForex = "assets/layout/images/forex_add.png";
    const detailsStyle = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "right",
    };
    const checkHistory = "assets/layout/images/refresh.png";
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
      wordBreak: "break-all",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };

    return (
      <div>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div className="layout-content">
          <ProgressSpinner
            style={{
              display: this.state.showLoader,
              width: "40px",
              height: "40px",
              position: "fixed",
              top: "50%",
              left: "50%",
              bottom: "50%",
              right: "50%",
              zIndex: "1111111111",
            }}
            strokeWidth="3"
            animationDuration=".5s"
          />
          <div>
            <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
              <div className="p-col-12 p-md-12 p-xl-12">
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    {/*<div className="card card-w-title">*/}
                    <div>
                      <span className="p-tag custom-heading">
                        Remittance Partners
                      </span>
                    </div>
                  </div>
                </div>

                <div className="p-grid transaction">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "2px",
                        display: this.state.showFiltersMob,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          icon="pi pi-sliders-h"
                          label="Filters"
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            border: "1px solid #f0f0f0",
                            color: "#999",
                            float: "left",
                            width: "75%",
                          }}
                          onClick={() => this.setState({ dialogVisible: true })}
                        />
                      </div>
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.showAddRemitPartnerDialog()}
                            className="p-button-info"
                            style={{ float: "right" }}
                            data-tip="Add Remittance Partner"
                            data-for="toolTip1"
                            data-place="top"
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "24px",
                        display: this.state.showFiltersWeb,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-3 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            id="range"
                            value={this.state.rangeValue}
                            onChange={(e) =>
                              this.setState(
                                { rangeValue: e.value, dateChange: "Y" },
                                () => this.getRemitPartnersByDate()
                              )
                            }
                            selectionMode="range"
                            readOnlyInput
                            showIcon={true}
                          />
                          <label htmlFor="calendar">DATE RANGE</label>
                        </span>
                      </div>

                      <div className="p-col-12 p-md-4 p-xl-4 p-xl-offset-4">
                        <div className="p-grid">
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingRight: "2px" }}
                          >
                            <div className="p-inputgroup">
                              <span className="p-float-label">
                                <Dropdown
                                  style={{ width: "20%" }}
                                  options={this.state.searchFilter}
                                  value={this.state.dropdownSearchFilter}
                                  onChange={(event) =>
                                    this.setState({
                                      dropdownSearchFilter: event.value,
                                    })
                                  }
                                  autoWidth={false}
                                />
                                <label style={floatLabel}>Select Filter</label>
                              </span>
                            </div>
                          </div>
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingLeft: "0.4px" }}
                          >
                            <div className="p-inputgroup">
                              <InputText
                                value={this.state.searchValue}
                                onChange={this.handleSearchValueChange}
                                placeholder="Search Value"
                              />
                              <Button
                                onClick={() => this.getData()}
                                icon="pi pi-search"
                                className="p-button-info"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-4 p-md-1 p-xl-1">
                        {/*<Button icon="pi pi-download" onClick={this.exportCSV} className="p-button-info" style={{ backgroundColor: '#f0f0f0', color: '#999', float: 'right' }} data-tip="Download Provider List" data-for='toolTip1' data-place='top' />&nbsp;&nbsp;&nbsp;*/}
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.showAddRemitPartnerDialog()}
                            className="p-button-info"
                            style={{
                              float: "right",
                              border: "1px solid rgb(92, 74, 181)",
                            }}
                            data-tip="Add Remittance Partner"
                            data-for="toolTip1"
                            data-place="top"
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                        <ReactTooltip id="toolTip1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ padding: "0" }}
                  >
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <DataTable
                          className="p-datatable-responsive-demo"
                          resizableColumns
                          columnResizeMode="expand"
                          value={this.state.dataTableValue}
                          paginator={true}
                          rows={10}
                          ref={(el) => {
                            this.dt = el;
                          }}
                        >
                          <Column field="id" style={{ display: "none" }} />
                          <Column
                            field="is_enabled"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="live_url"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="requested_by"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="requested_by_name"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="test_url"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="l_username"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="l_password"
                            style={{ display: "none" }}
                          />
                          <Column field="l_pin" style={{ display: "none" }} />
                          <Column
                            field="t_username"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="t_password"
                            style={{ display: "none" }}
                          />
                          <Column field="t_pin" style={{ display: "none" }} />
                          <Column
                            field="name"
                            header="Name"
                            body={this.nameColumnTemplate}
                          />
                          <Column
                            field="url"
                            header="URL"
                            body={this.statusColumnTemplate}
                          />
                          <Column
                            field="modified_at"
                            header="Date"
                            body={this.dateColumnTemplate}
                          />
                          <Column
                            header="Action"
                            body={this.actionColumnTemplate}
                          />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            header="Filters"
            visible={this.state.dialogVisible}
            onHide={() => this.setState({ dialogVisible: false })}
          >
            <div className="p-grid">
              <div
                className="p-field p-col-12 p-md-8 p-md-offset-2"
                style={{ marginTop: "1.5em" }}
              >
                <span className="p-float-label">
                  <Calendar
                    id="range"
                    value={this.state.rangeValue}
                    onChange={(e) =>
                      this.setState(
                        { rangeValue: e.value, dateChange: "Y" },
                        () => this.getRemitPartnersByDate()
                      )
                    }
                    selectionMode="range"
                    readOnlyInput
                    showIcon={true}
                    style={{ width: "100%" }}
                  />
                  <label htmlFor="calendar">DATE RANGE</label>
                </span>
              </div>
              <div
                className="p-field p-col-12 p-md-8 p-md-offset-2"
                style={{ marginTop: "1em" }}
              >
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <Dropdown
                      style={{ width: "20%" }}
                      options={this.state.searchFilter}
                      value={this.state.dropdownSearchFilter}
                      onChange={(event) =>
                        this.setState({ dropdownSearchFilter: event.value })
                      }
                      autoWidth={false}
                    />
                    <label style={floatLabel}>Select Filter</label>
                  </span>
                </div>
              </div>
              <div className="p-field p-col-12 p-md-8 p-md-offset-2">
                <div className="p-inputgroup">
                  <InputText
                    value={this.state.searchValue}
                    onChange={this.handleSearchValueChange}
                    placeholder="Search Value"
                  />
                </div>
              </div>
              <div
                className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Apply"
                  onClick={() => this.getData()}
                  icon="pi pi-search-plus"
                  style={{ width: "50%" }}
                />
              </div>
            </div>
          </Dialog>

          <Dialog
            visible={this.state.editCountryDialog}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Edit Remittance Partner"
            modal
            onHide={this.hideEditCountryDialog}
          >
            <form onSubmit={this.editRemitPartner}>
              <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.editName}
                          onChange={(e) =>
                            this.setState({ editName: e.target.value })
                          }
                          style={{ border: this.state.editNameError }}
                        />
                        <label style={labelStyle}>Name</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.editLiveUrl}
                          onChange={(e) =>
                            this.setState({ editLiveUrl: e.target.value })
                          }
                          style={{ border: this.state.editLiveUrlError }}
                        />
                        <label style={labelStyle}>API URL</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ display: this.state.showEditErrorMsg }}
                    >
                      <label
                        style={{
                          paddingLeft: "10px",
                          fontSize: "14px",
                          color: "red",
                        }}
                      >
                        {this.state.errorEditMsg}
                      </label>
                    </div>
                    {/*<div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editLUserName} onChange={(e) => this.setState({ editLUserName: e.target.value})} style={{border:this.state.editLUserNameError}}/>
                                            <label style={labelStyle}>Username</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editLPassword} onChange={(e) => this.setState({editLPassword: e.target.value})} style={{border:this.state.editLPasswordError}}/>
                                            <label style={labelStyle}>Password</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-5 p-lg-5 p-xl-5" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editLPin} onChange={(e) => this.setState({ editLPin: e.target.value})} style={{border:this.state.editLPinError}}/>
                                            <label style={labelStyle}>PIN</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editTestUrl} onChange={(e) => this.setState({editTestUrl: e.target.value})} style={{border:this.state.editTestUrlError}}/>
                                            <label style={labelStyle}>Test URL</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editTUserName} onChange={(e) => this.setState({ editTUserName: e.target.value})} style={{border:this.state.editTUserNameError}}/>
                                            <label style={labelStyle}>Username</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editTPassword} onChange={(e) => this.setState({editTPassword: e.target.value})} style={{border:this.state.editTPasswordError}}/>
                                            <label style={labelStyle}>Password</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-5 p-lg-5 p-xl-5" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.editTPin} onChange={(e) => this.setState({ editTPin: e.target.value})} style={{border:this.state.editTPinError}}/>
                                            <label style={labelStyle}>PIN</label>
                                        </span>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '10px' }}>
								  		<label style={labelStyle}>Enable Live URL</label>
                                    </div>
                                    <div className="p-col-2 p-md-2 p-lg-2 p-xl-2" style={{ marginTop: '10px' }}>
										<InputSwitch checked={this.state.editLiveUrlEnable} onChange={event => this.setState({editLiveUrlEnable: event.value})} />
                                    </div>
									<div className="p-col-10 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{fontStyle:'italic'}}>
                                        <label style={labelStyle}><b>Note: </b>Test URL is enabled if Live URL is disabled</label>
                                    </div>*/}
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Submit"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.showConfigureApi}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Add Remittance Partner"
            modal
            onHide={this.hideAddRemitPartnerDialog}
          >
            <form onSubmit={this.addRemitPartner}>
              <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          style={{ border: this.state.nameError }}
                        />
                        <label style={labelStyle}>Name</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.liveUrl}
                          onChange={(e) =>
                            this.setState({ liveUrl: e.target.value })
                          }
                          style={{ border: this.state.liveUrlError }}
                        />
                        <label style={labelStyle}>API URL</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ display: this.state.showErrorMsg }}
                    >
                      <label
                        style={{
                          paddingLeft: "10px",
                          fontSize: "14px",
                          color: "red",
                        }}
                      >
                        {this.state.errorMsg}
                      </label>
                    </div>
                    {/*<div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value})} style={{border:this.state.userNameError}}/>
                                            <label style={labelStyle}>Username</label>
                                        </span>
                                    </div>
									<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.password} onChange={(e) => this.setState({ password: e.target.value})} style={{border:this.state.passwordError}}/>
                                            <label style={labelStyle}>Password</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-5 p-lg-5 p-xl-5" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.pin} onChange={(e) => this.setState({ pin: e.target.value})} style={{border:this.state.pinError}}/>
                                            <label style={labelStyle}>PIN</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.testUrl} onChange={(e) => this.setState({testUrl: e.target.value})} style={{border:this.state.testUrlError}}/>
                                            <label style={labelStyle}>Test URL</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.testUserName} onChange={(e) => this.setState({ testUserName: e.target.value})} style={{border:this.state.testUserNameError}}/>
                                            <label style={labelStyle}>Username</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.testPassword} onChange={(e) => this.setState({ testPassword: e.target.value})} style={{border:this.state.testPasswordError}}/>
                                            <label style={labelStyle}>Password</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-5 p-lg-5 p-xl-5" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText value={this.state.testPin} onChange={(e) => this.setState({ testPin: e.target.value})} style={{border:this.state.testPinError}}/>
                                            <label style={labelStyle}>PIN</label>
                                        </span>
                                    </div>
                                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '10px' }}>
								  		<label style={labelStyle}>Enable Live URL</label>
                                    </div>
                                    <div className="p-col-2 p-md-2 p-lg-2 p-xl-2" style={{ marginTop: '10px' }}>
										<InputSwitch checked={this.state.liveUrlEnable} onChange={event => this.setState({liveUrlEnable: event.value})} />
                                    </div>
									<div className="p-col-10 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{fontStyle:'italic'}}>
                                        <label style={labelStyle}><b>Note: </b>Test URL is enabled if Live URL is disabled</label>
                                    </div>*/}
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Submit"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.providerDialog}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Remittance Partner Details"
            modal
            onHide={this.hideProviderDetails}
          >
            {/*<span className={this.state.dialogStatus}>{this.state.dialogStatusText}</span>*/}
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div style={cardStyle}>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Name</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogName}
                      </label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>API URL</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogLiveUrl}
                      </label>
                    </div>
                    {/*<div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleLeft}>Username</label>
                                    </div>
                                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleRight}>{this.state.viewDialogLUsername}</label>
                                    </div>
                                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleLeft}>Test URL</label>
                                    </div>
                                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleRight}>{this.state.viewDialogTestUrl}</label>
                                    </div>
                                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleLeft}>Username</label>
                                    </div>
                                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleRight}>{this.state.viewDialogTUsername}</label>
                                    </div>*/}
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Date</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogDate}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(PartnerConfiguration);
