import React, { Component } from "react";
import { InputSwitch } from "primereact/inputswitch";
import copy from "copy-to-clipboard";
import { ProgressSpinner } from "primereact/progressspinner";
import { Menu } from "primereact/menu";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import "../../config";
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
const floatLabel = {
  fontWeight: "500",
  color: "rgb(153, 153, 153)",
  fontSize: "12px",
};
const DEFAULT_PAGE_SIZE = 50;

class InstitutionList extends Component {
  constructor() {
    super();
    document.title = "Institution - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      billingType: [
        { label: "ACTIVE", value: "ACTIVE" },
        { label: "PASSIVE", value: "PASSIVE" },
        { label: "FLAT", value: "FLAT" },
        { label: "FX_COMMISSION", value: "FX_COMMISSION" },
      ],
      userType: [
        { label: "Admin User", value: "1" },
        { label: "Finance Admin", value: "101" },
        { label: "Finance User", value: "102" },
        { label: "Help Desk Admin", value: "201" },
        { label: "Help Desk User", value: "202" },
        { label: "Institution Admin", value: "301" },
        { label: "Institution Admin Admin", value: "302" },
        { label: "Institution User", value: "303" },
      ],
      accountType: [
        { label: "Bank", value: "0" },
        { label: "Wallet", value: "1" },
      ],
      userId: "",
      editInstitutionEmail: "",
      vaName: "",
      dropdownAccountType: "",
      vaThreshold: "",
      dropdownCurrency: "",

      editInstitutionName: "",
      dropdownEditBillingType: "",
      dropdownEditBillingTypeError: "",
      editInstitutionEmailError: "",
      editBilling: "",
      editBillingError: "",
      dropdownEditStatus: "",
      dropdownEditStatusError: "",
      institutionName: "",
      userName: "",
      shortName: "",
      email: "",
      phone: "",
      dropdownBillingType: "",
      billing: "",
      dropdownUserType: "",
      dropdownCurrency: "",
      currencyError: "",
      rateError: "",
      addInstDialog: false,
      currency: "",
      rate: "",
      dateChange: "N",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      institutionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      reversalTransactionButton: "none",
      closeTransactionButton: "none",
      submitted: false,
      dropdownCity: "All",
      dropdownSearchFilter: "",
      filterCountry: "",
      filterDate: "",
      searchValueText: "",
      status: [
        { label: "All", value: "All" },
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ],
      editStatus: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ],
      dropdownStatus: "All",
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      cities: [],
      showEdit: "",
      showLoader: "none",
      contentOpacity: "1",
      rangeValue: [],
      rp: [],
      addModeDialogVisible: "",
      modeType: [
        { label: "No Mode", value: "0" },
        { label: "Fetch Mode", value: "1" },
        { label: "Api Mode", value: "2" },
      ],
      displayRP: "none",
      mode: "",
      rpValue: "",
      modeError: "",
      rpValueError: "",
      instId: "",
      partnerNameDisplay: "none",
      partnerName: "",
      modeSelected: "",
      partnerUsername: "",
      partnerUsernameError: "",
      partnerPassword: "",
      partnerPasswordError: "",
      partnerPin: "",
      partnerPinError: "",
      generatedKey: "",
      displayKey: "none",
      copyToolTipText: "Copy",
      showRegenerateKey: "none",
      regenerateToolTipText: "Regenerate Key",
      retryOption: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      retryOptionValue: "",
      retryOptionValueError: "",

      retryIntervalOption: [
        { label: "5", value: "5" },
        { label: "10", value: "10" },
        { label: "15", value: "15" },
        { label: "20", value: "20" },
        { label: "25", value: "25" },
        { label: "30", value: "30" },
        { label: "35", value: "35" },
        { label: "40", value: "40" },
        { label: "45", value: "45" },
        { label: "50", value: "50" },
        { label: "55", value: "55" },
        { label: "60", value: "60" },
      ],
      retryIntervalOptionValue: "",
      retryIntervalOptionValueError: "",
      canUserRetry: false,
      canRetryDiv: "none",
      retryOptionValueEdit: "",
      retryOptionValueEditError: "",
      previousFunction: null,
      retryIntervalOptionValueEdit: "",
      retryIntervalOptionValueEditError: "",
      canUserRetryEdit: false,
      canRetryDivEdit: "none",
      showRetryDetails: "none",
      retryDetail: "",
      retryIntervalDetail: "",
      cUrl: "",
      cUrlError: "",
      showEditErrorMsg: "none",
      errorEditMsg: "",
      searchFilter: [
        { label: "Name", value: "name" },
        { label: "Short Name", value: "shortName" },
      ],
      lazyParams: {
        first: 0,
        rows: DEFAULT_PAGE_SIZE,
        page: 1,
      },
      statusFilterValue: "",
      totalCount: DEFAULT_PAGE_SIZE,
      dropdownSearchFilter: "",
      searchValue: "",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.billingTypeColumnTemplate = this.billingTypeColumnTemplate.bind(this);
    this.viewInstitutionDetails = this.viewInstitutionDetails.bind(this);
    this.editInstitutionDetails = this.editInstitutionDetails.bind(this);
    this.hideInstitutionDetailDialog =
      this.hideInstitutionDetailDialog.bind(this);
    this.institutionColumnTemplate = this.institutionColumnTemplate.bind(this);
    this.shortNameColumnTemplate = this.shortNameColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.noOfCountriesColumnTemplate =
      this.noOfCountriesColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);
    this.changePage = this.changePage.bind(this);
  }
  showHideRetryDiv = () => {
    if (true == this.state.canUserRetry) {
      this.setState({
        canRetryDiv: "initial",
      });
    } else {
      this.setState({
        canRetryDiv: "none",
      });
    }
  };
  showHideRetryDivEdit = () => {
    if (true == this.state.canUserRetryEdit) {
      this.setState({
        canRetryDivEdit: "initial",
      });
    } else {
      this.setState({
        canRetryDivEdit: "none",
      });
    }
  };
  copyKey = () => {
    copy(this.state.generatedKey);

    this.state.copyToolTipText = "Copied";
    this.setState({
      copyToolTipText: "Copied",
    });

    this.setState({ dummyValue: "Copied" });
    this.state.dummyValue = "Copied";
  };
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }

  changePage(event) {
    console.log(event);
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams });

    const selectedPage = event.page;
    this.state.previousFunction(selectedPage);
  }

  displayRPDropdown = () => {
    console.log(this.state.mode);
    if (0 == this.state.mode) {
      this.setState({
        displayRP: "none",
        displayKey: "none",
        //cUrl:'',
      });
    } else if (1 == this.state.mode) {
      this.setState({
        displayRP: "initial",
        displayKey: "none",
        //cUrl:'',
      });
    } else {
      this.setState({
        cUrl: "",
      });
      this.generateKeyApi();
      this.setState({
        displayRP: "none",
        displayKey: "initial",
        //cUrl:'',
      });
    }
  };
  generateKeyApi = async () => {
    console.log("GENERATE API");
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      inst_id: this.state.instId,
      mode: "2",
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "api-key-generate",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          console.log(res.data);
          this.setState({
            generatedKey: res.data.api_key,
            cUrl: res.data.c_url,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  regenerateKey = async () => {
    console.log("RE-GENERATE API");
    console.log(this.state.generatedKey);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      inst_id: this.state.instId,
      mode: "2",
      api_key: this.state.generatedKey,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "api-key-regenerate",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.state.regenerateToolTipText = "Key Re-Generated";
          this.setState({
            regenerateToolTipText: "Key Re-Generated",
          });
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          console.log(res.data);
          this.setState({
            generatedKey: res.data.api_key,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  navigate2VA = (id) => {
    let payload = { userid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/va");
      } else {
        alert("Failed");
      }
    });
  };
  navigate2InstitutionUsers = (id) => {
    let payload = { userid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/institution-users");
      } else {
        alert("Failed");
      }
    });
  };

  viewVirtualAccountDialog = (rowData) => {
    console.log(rowData);
    let billingType = "";
    if ("Active" == rowData.billing_type) {
      billingType = 1;
    } else {
      billingType = 0;
    }
    console.log(billingType);
    this.setState(
      {
        dropdownBillingType: billingType,
        userId: rowData.user_id,
        viewVirtualAccountDialog: true,
      },
      () => console.log(this.state.userId)
    );
  };

  addModeSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.mode);
    console.log(this.state.rpValue);
    console.log(this.state.instId);
    if ("" == this.state.mode) {
      this.setState({
        modeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        modeError: "",
      });
    }
    let remitPartnerId = "";
    let remitPartnerUsername = "";
    let remitPartnerPassword = "";
    let remitPartnerPin = "";
    let callbackUrl = "";
    if (0 == this.state.mode) {
      remitPartnerId = "";
      remitPartnerUsername = "";
      remitPartnerPassword = "";
      remitPartnerPin = "";
      callbackUrl = "";
    } else if (1 == this.state.mode) {
      if ("" == this.state.rpValue) {
        this.setState({
          rpValueError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          rpValueError: "",
        });
        remitPartnerId = this.state.rpValue;
      }
      if ("" == this.state.partnerUsername) {
        this.setState({
          partnerUsernameError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          partnerUsernameError: "",
        });
        remitPartnerUsername = this.state.partnerUsername;
      }
      if ("" == this.state.partnerPassword) {
        this.setState({
          partnerPasswordError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          partnerPasswordError: "",
        });
        remitPartnerPassword = this.state.partnerPassword;
      }
      if ("" == this.state.partnerPin) {
        this.setState({
          partnerPinError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          partnerPinError: "",
        });
        remitPartnerPin = this.state.partnerPin;
      }
    } else if (2 == this.state.mode) {
      if ("" == this.state.cUrl) {
        this.setState({
          cUrlError: "1.5px solid red",
        });
        return false;
      } else {
        if (!this.state.cUrl.includes("http")) {
          this.setState({
            showEditErrorMsg: "initial",
            errorEditMsg: "* Please enter a valid URL",
            cUrlError: "1.5px solid red",
          });
          return false;
        } else {
          this.setState({
            showEditErrorMsg: "none",
            errorEditMsg: "",
            cUrlError: "",
          });
          this.setState({
            cUrlError: "",
          });
        }
        callbackUrl = this.state.cUrl;
      }
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      mode: this.state.mode,
      partner_id: remitPartnerId,
      inst_id: this.state.instId,
      partner_username: remitPartnerUsername,
      partner_password: remitPartnerPassword,
      partner_pin: remitPartnerPin,
      c_url: callbackUrl,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "user/institute/add-mode",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideAddMode();
          this.getInstitutionList();
          this.toastSuccess(res.data.msg);
        } else {
          this.hideAddMode();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };
  createVirtualAccount = (event) => {
    event.preventDefault();
    console.log(this.state.userId);
    if ("" == this.state.vaName) {
      this.setState({
        vaNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        vaNameError: "",
      });
    }
    if ("" == this.state.dropdownAccountType) {
      this.setState({
        accountTypeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        accountTypeError: "",
      });
    }
    if ("" == this.state.vaThreshold) {
      this.setState({
        vaThresholdError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        vaThresholdError: "",
      });
    }
    if ("" == this.state.dropdownCurrency) {
      this.setState({
        currencyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        currencyError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      userId: this.state.userId,
      account_name: this.state.vaName,
      account_type: this.state.dropdownAccountType,
      threshold_amount: this.state.vaThreshold,
      currency: this.state.dropdownCurrency,
      token: this.props.userDetails._id,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "create-va",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideVirtualAccountDialog();
          this.toastSuccess(res.data.msg);
        } else {
          this.hideVirtualAccountDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  hideVirtualAccountDialog = () => {
    this.state.viewVirtualAccountDialog = false;
    this.setState({
      vaName: "",
      dropdownAccountType: "",
      vaThreshold: "",
      dropdownCurrency: "",
    });
  };

  billingTypeColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Billing Type</span>
        <span>{rowData.billing_type}</span>
      </React.Fragment>
    );
  }
  institutionColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Institution</span>
        <span>{rowData.name}</span>
      </React.Fragment>
    );
  }

  shortNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Short Name</span>
        {rowData.short_name}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        <span>{rowData.created_at}</span>
      </React.Fragment>
    );
  }

  noOfCountriesColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">No. of Countries</span>
        <span>{rowData.countries}</span>
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let pTag = "";
    let status = "";
    if ("Active" == rowData.status) {
      status = "Active";
      pTag = "p-tag p-tag-success";
    } else {
      status = "Inactive";
      pTag = "p-tag p-tag-danger";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    let disp = "";
    if ("Inactive" == rowData.status) {
      disp = "none";
    } else {
      disp = "initial";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-user-edit"
          className="p-button-primary p-mr-2"
          onClick={() => this.editInstitutionDetails(rowData)}
          style={{
            display: this.state.showEdit,
            fontSize: "15px",
            padding: "7px",
          }}
          data-tip="Edit Institution"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <Button
          label=""
          icon="pi pi-eye"
          className="p-button-success p-mr-2"
          onClick={() => this.viewInstitutionDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="View Institution Details"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <Button
          label=""
          icon="pi pi-user-plus"
          className="p-button-info p-mr-2"
          onClick={() => this.addMode(rowData)}
          style={{
            fontSize: "15px",
            padding: "7px",
            border: "1px solid #5443a3",
            display: disp,
          }}
          data-tip="Assign Mode"
          data-for="toolTip1"
          data-place="top"
        />
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }

  viewInstitutionDetails(rowData) {
    console.log(rowData);
    if (0 == rowData.mode) {
      this.setState({
        partnerNameDisplay: "none",
        modeSelected: "No Mode",
      });
    } else if (1 == rowData.mode) {
      this.setState({
        partnerNameDisplay: "flex",
        partnerName: rowData.partner_name,
        modeSelected: "FETCH",
      });
    } else {
      this.setState({
        partnerNameDisplay: "none",
        modeSelected: "API",
      });
    }
    if ("Inactive" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "Inactive",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
      });
    }
    if ("Passive" == rowData.billing_type) {
      this.setState({
        dialogBillingType: "Passive",
      });
    } else {
      this.setState({
        dialogBillingType: "Active",
      });
    }
    if ("0" != rowData.retry) {
      this.setState({
        showRetryDetails: "flex",
        retryDetail: rowData.retry,
        retryIntervalDetail: rowData.retry_time,
      });
    } else {
      this.setState({
        showRetryDetails: "none",
        retryDetail: "0",
        retryIntervalDetail: "0",
      });
    }
    this.setState({
      userId: rowData.user_id,
      dialogInstitutionName: rowData.name,
      dialogInstitutionShortName: rowData.short_name,
      dialogEmail: rowData.email,
      dialogPhone: rowData.phone,
      dialogJoinedOn: rowData.created_at,
      dialogBilling: rowData.billing,
      institutionDialog: true,
    });
  }

  hideInstitutionDetailDialog() {
    this.setState({ institutionDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };
  addMode = (rowData) => {
    console.log(rowData);
    this.state.generatedKey = "";
    console.log("MOE");
    console.log(rowData.mode);
    if (0 == rowData.mode) {
      this.setState({
        showRegenerateKey: "none",
        displayKey: "none",
        displayRP: "none",
        displayKey: "none",
        mode: "0",
        cUrl: "",
      });
    } else if (1 == rowData.mode) {
      this.setState({
        showRegenerateKey: "none",
        displayKey: "none",
        displayRP: "initial",
        mode: "1",
        rpValue: rowData.partner_id,
        partnerUsername: rowData.partner_username,
        partnerPassword: rowData.partner_password,
        partnerPin: rowData.partner_pin,
        cUrl: "",
      });
    } else {
      this.setState({
        showRegenerateKey: "initial",
        displayRP: "none",
        displayKey: "initial",
        generatedKey: rowData.api_mode_key,
        cUrl: rowData.c_url,
        mode: "2",
      });
    }
    this.setState({
      instId: rowData.user_id,
      addModeDialogVisible: true,
    });
  };
  hideAddMode = (rowData) => {
    this.setState({
      copyToolTipText: "Copy",
      regenerateToolTipText: "Regenerate Key",
      mode: "",
      displayRP: "none",
      rpValue: "",
      partnerUsername: "",
      partnerPassword: "",
      partnerPin: "",
      addModeDialogVisible: false,
    });
  };
  editInstitutionDetails(rowData) {
    console.log(rowData);
    let billingType = "";
    if ("0" != rowData.retry) {
      this.setState({
        canRetryDivEdit: "initial",
        canUserRetryEdit: true,
        retryOptionValueEdit: rowData.retry,
        retryIntervalOptionValueEdit: rowData.retry_time,
      });
    } else {
      this.setState({
        canRetryDivEdit: "none",
        canUserRetryEdit: false,
        retryOptionValueEdit: "0",
        retryIntervalOptionValueEdit: "0",
      });
    }
    if ("Active" == rowData.billing_type) {
      billingType = "1";
    } else {
      billingType = "0";
    }
    if ("Inactive" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "Inactive",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
      });
    }
    if ("Inactive" == rowData.status) {
      this.setState({
        dropdownEditStatus: "0",
      });
    } else {
      this.setState({
        dropdownEditStatus: "1",
      });
    }
    this.setState({
      editInstitutionName: rowData.name,
      editInstitutionEmail: rowData.email,
      dropdownEditBillingType: billingType,
      editBilling: rowData.billing,
      userId: rowData.user_id,
      editInstitutionDialog: true,
    });
  }

  componentDidMount() {
    this.setState({
      rp: this.props.userDetails.rp,
    });
    {
      /*if('0' == this.props.userDetails.type)
        {
            this.setState({
                showEdit:'inherit',
            });
        }
        else
        {
            this.setState({
                showEdit:'none'
            });
        }*/
    }
    this.setState({
      currency: this.props.userDetails.currency,
    });
    this.updateDimensions();
    this.getInstitutionList();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      this.filterDataByDate();
    }
  };

  filterDataByDate = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-inst",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.createDataTable(res.data.data);
          this.setState({
            rp: res.data.rp,
          });
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getSearchedData = async () => {
    this.state.dialogVisible = false;
    this.getTransactionDataSearchValue();
  };

  openAddForexDialog = () => {
    this.state.addInstDialog = true;
  };
  hideAddInstDialog = () => {
    this.setState({
      institutionName: "",
      shortName: "",
      email: "",
      phone: "",
      dropdownBillingType: "",
      billing: "",
      dropdownUserType: "",
      retryOptionValue: "",
      retryIntervalOptionValue: "",
    });
    this.state.addInstDialog = false;
  };
  hideEditInstDialog = () => {
    this.state.editInstitutionDialog = false;
  };
  editInstitution = (event) => {
    event.preventDefault();
    if ("" == this.state.editInstitutionName) {
      this.setState({
        editInstNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        editInstNameError: "",
      });
    }
    if ("" == this.state.editInstitutionEmail) {
      this.setState({
        editInstitutionEmailError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        editInstitutionEmailError: "",
      });
    }
    if ("" == this.state.dropdownEditBillingType) {
      this.setState({
        dropdownEditBillingTypeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownEditBillingTypeError: "",
      });
    }
    if ("" == this.state.editBilling) {
      this.setState({
        editBillingError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        editBillingError: "",
      });
    }
    if ("" == this.state.dropdownEditStatus) {
      this.setState({
        dropdownEditStatusError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownEditStatusError: "",
      });
    }
    console.log(this.state.canUserRetryEdit);
    if (true == this.state.canUserRetryEdit) {
      console.log("HY");
      console.log(this.state.retryOptionValueEdit);
      if (
        "" == this.state.retryOptionValueEdit ||
        "0" == this.state.retryOptionValueEdit
      ) {
        this.setState({
          retryOptionValueEditError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          retryOptionValueEditError: "",
        });
      }
      if (
        "" == this.state.retryIntervalOptionValueEdit ||
        "0" == this.state.retryIntervalOptionValueEdit
      ) {
        this.setState({
          retryIntervalOptionValueEditError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          retryIntervalOptionValueEditError: "",
        });
      }
    } else {
      this.setState({
        retryOptionValueEdit: "0",
        retryIntervalOptionValueEdit: "0",
      });
      this.state.retryOptionValueEdit = "0";
      this.state.retryIntervalOptionValueEdit = "0";
    }
    console.log(this.state.retryOptionValueEdit);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      entry_id: this.state.userId,
      name: this.state.editInstitutionName,
      email: this.state.editInstitutionEmail,
      billing_type: this.state.dropdownEditBillingType,
      billing: this.state.editBilling,
      status: this.state.dropdownEditStatus,
      retry: this.state.retryOptionValueEdit,
      retry_interval: this.state.retryIntervalOptionValueEdit,
    };
    axios
      .post(
        global.config.main.baseUrl + "edit-users-details",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideEditInstDialog();
          this.getInstitutionList();
          this.toastSuccess(res.data.msg);
        } else {
          this.hideEditInstDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  addInstitution = (event) => {
    event.preventDefault();
    console.log(this.state.canUserRetry);
    if ("" == this.state.institutionName) {
      this.setState({
        institutionNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        institutionNameError: "",
      });
    }
    {
      /*if ('' == this.state.userName) {
            this.setState({
                userNameError: "1.5px solid red",
            });
            return false;
        }
        else {
            this.setState({
                userNameError: "",
            });
        }*/
    }
    if ("" == this.state.shortName) {
      this.setState({
        shortNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        shortNameError: "",
      });
    }
    if ("" == this.state.email) {
      this.setState({
        emailError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        emailError: "",
      });
    }
    if ("" == this.state.phone) {
      this.setState({
        phoneError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        phoneError: "",
      });
    }
    if ("" == this.state.dropdownBillingType) {
      this.setState({
        dropdownBillingTypeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownBillingTypeError: "",
      });
    }
    if ("" == this.state.billing) {
      this.setState({
        billingError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        billingError: "",
      });
    }
    {
      /*if ('' == this.state.dropdownUserType) {
            this.setState({
                dropdownUserTypeError: "1.5px solid red",
            });
            return false;
        }
        else {
            this.setState({
                dropdownUserTypeError: "",
            });
        }*/
    }
    let retryOpt = "0";
    let retryInt = "0";
    if (true == this.state.canUserRetry) {
      if ("" == this.state.retryOptionValue) {
        this.setState({
          retryOptionValueError: "1.5px solid red",
        });
        return false;
      } else {
        retryOpt = this.state.retryOptionValue;
        this.setState({
          retryOptionValueError: "",
        });
      }
      if ("" == this.state.retryIntervalOptionValue) {
        this.setState({
          retryIntervalOptionValueError: "1.5px solid red",
        });
        return false;
      } else {
        retryInt = this.state.retryIntervalOptionValue;
        this.setState({
          retryIntervalOptionValueError: "",
        });
      }
    } else {
      this.setState({
        retryOptionValue: "0",
        retryIntervalOptionValue: "0",
      });
      retryOpt = "0";
      retryInt = "0";
      this.state.retryOptionValue = "0";
      this.state.retryIntervalOptionValue = "0";
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      email: this.state.email,
      phone: this.state.phone,
      name: this.state.institutionName,
      user_name: this.state.shortName,
      short_name: this.state.shortName,
      user_type: "301", //this.state.dropdownUserType,
      billing_type: this.state.dropdownBillingType,
      billing: this.state.billing,
      retry: retryOpt, //this.state.retryOptionValue,
      retry_interval: retryInt, //this.state.retryIntervalOptionValue,
    };
    axios
      .post(
        global.config.main.baseUrl + "register-users",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getInstitutionList();
          this.hideAddInstDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            email: "",
            institutionName: "",
            userName: "",
            shortName: "",
            dropdownUserType: "",
            dropdownBillingType: "",
            billing: "",
          });
        } else {
          this.hideAddInstDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  GetFilteredStatus = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      dialogVisible: false,
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      status: this.state.dropdownStatus,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-inst",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DATA");
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            rp: res.data.rp,
          });
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getData = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    else this.state.dialogVisible = false;
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-inst",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DATA");
          console.log(res.data);
          this.setState({
            rp: res.data.rp,
          });
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getInstitutionList = async (page = 1) => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      page: page,
      size: DEFAULT_PAGE_SIZE,
    };

    this.setState({
      previousFunction: this.getInstitutionList,
    });

    axios
      .post(
        global.config.main.baseUrl + "list-inst",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DATA");
          console.log(res.data);
          this.setState({
            rp: res.data.rp,
          });
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
            totalCount: res.data.total_count || DEFAULT_PAGE_SIZE,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    console.log(data);
    let user_status = "";
    let user_billing_type = "";
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      if ("0" == d["status"]) {
        user_status = "Inactive";
      } else {
        user_status = "Active";
      }
      if ("0" == d["billing_type"]) {
        user_billing_type = "Passive";
      } else {
        user_billing_type = "Active";
      }

      this.state.dataTableValue.push({
        created_at: d["created_at"],
        email: d["email"],
        name: d["name"],
        phone: d["phone"],
        short_name: d["short_name"],
        countries: d["no_va"],
        status: user_status,
        user_id: d["user_id"],
        billing_type: user_billing_type,
        billing: d["billing"],
        mode: d["mode"],
        partner_id: d["partner_id"],
        partner_name: d["partner_name"],
        partner_password: d["partner_password"],
        partner_pin: d["partner_pin"],
        partner_username: d["partner_username"],
        api_mode_key: d["api_key"],
        retry: d["retry"],
        retry_time: d["retry_time"],
        c_url: d["c_url"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  render() {
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const institutionDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideInstitutionDetailDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.retryTransactionButton,
          }}
        />
        <Button
          label="Reverse Transaction"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideInstitutionDetailDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.reversalTransactionButton,
          }}
        />
        <Button
          label="Close"
          className="p-button-success"
          onClick={this.hideInstitutionDetailDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.closeTransactionButton,
          }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const addForex = "assets/layout/images/forex_add.png";
    const detailsStyle = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "right",
    };
    const detailsStyle1 = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "left",
    };
    const checkHistory = "assets/layout/images/refresh.png";
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px", width: "100%" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };
    const vaNameborder = { border: this.state.vaNameError };

    return (
      <div>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div className="layout-content">
          <ProgressSpinner
            style={{
              display: this.state.showLoader,
              width: "40px",
              height: "40px",
              position: "fixed",
              top: "50%",
              left: "50%",
              bottom: "50%",
              right: "50%",
              zIndex: "1111111111",
            }}
            strokeWidth="3"
            animationDuration=".5s"
          />
          <div style={{ opacity: this.state.contentOpacity }}>
            <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
              <div className="p-col-12 p-md-12 p-xl-12">
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    {/*<div className="card card-w-title">*/}
                    <div>
                      <span className="p-tag custom-heading">Institution</span>
                    </div>
                  </div>
                </div>

                <div className="p-grid transaction">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "2px",
                        display: this.state.showFiltersMob,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          icon="pi pi-sliders-h"
                          label="Filters"
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            border: "1px solid #f0f0f0",
                            color: "#999",
                            float: "left",
                            width: "75%",
                          }}
                          onClick={() => this.setState({ dialogVisible: true })}
                        />
                      </div>
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          icon="pi pi-download"
                          onClick={this.exportCSV}
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#999",
                            float: "right",
                          }}
                          data-tip="Download Institution List"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.openAddForexDialog()}
                            className="p-button-info"
                            style={{ float: "right" }}
                            data-tip="Add Institution"
                            data-for="toolTip1"
                            data-place="top"
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "24px",
                        display: this.state.showFiltersWeb,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-3 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            id="range"
                            value={this.state.rangeValue}
                            onChange={(e) =>
                              this.setState(
                                { rangeValue: e.value, dateChange: "Y" },
                                () => this.getFilteredDataDate()
                              )
                            }
                            selectionMode="range"
                            readOnlyInput
                            showIcon={true}
                          />
                          <label htmlFor="calendar">DATE RANGE</label>
                        </span>
                      </div>

                      <div className="p-field p-col-6 p-md-2 p-xl-2">
                        <span className="p-float-label">
                          <Dropdown
                            style={filterInputBoxStyle}
                            id="dropdown1"
                            options={this.state.status}
                            value={this.state.dropdownStatus}
                            onChange={(event) =>
                              this.setState(
                                {
                                  dropdownStatus: event.value,
                                  statusChanged: "Y",
                                },
                                () => this.GetFilteredStatus()
                              )
                            }
                            autoWidth={false}
                          />
                          <label htmlFor="calendar">STATUS</label>
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4 p-xl-4 p-xl-offset-1">
                        <div className="p-grid">
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingRight: "2px" }}
                          >
                            <div className="p-inputgroup">
                              <span className="p-float-label">
                                <Dropdown
                                  style={{ width: "20%" }}
                                  options={this.state.searchFilter}
                                  value={this.state.dropdownSearchFilter}
                                  onChange={(event) =>
                                    this.setState({
                                      dropdownSearchFilter: event.value,
                                    })
                                  }
                                  autoWidth={false}
                                />
                                <label style={floatLabel}>Select Filter</label>
                              </span>
                            </div>
                          </div>
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingLeft: "0.4px" }}
                          >
                            <div className="p-inputgroup">
                              <InputText
                                value={this.state.searchValue}
                                onChange={this.handleSearchValueChange}
                                placeholder="Search Value"
                              />
                              <Button
                                onClick={() => this.getData()}
                                icon="pi pi-search"
                                className="p-button-info"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-4 p-md-2 p-xl-2">
                        <Button
                          icon="pi pi-download"
                          onClick={this.exportCSV}
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#999",
                            border: "1px solid #f0f0f0",
                          }}
                          data-tip="Download Institution List"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle1}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.openAddForexDialog()}
                            className="p-button-info"
                            data-tip="Add Institution"
                            data-for="toolTip1"
                            data-place="top"
                            style={{ border: "1px solid rgb(92, 74, 181)" }}
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ padding: "0" }}
                  >
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <DataTable
                          className="p-datatable-responsive-demo"
                          resizableColumns
                          columnResizeMode="expand"
                          value={this.state.dataTableValue}
                          totalRecords={this.state.totalCount}
                          paginator={true}
                          rows={DEFAULT_PAGE_SIZE}
                          lazy
                          first={this.state.lazyParams.first}
                          onPage={this.changePage}
                          ref={(el) => {
                            this.dt = el;
                          }}
                        >
                          <Column
                            field="retry_time"
                            style={{ display: "none" }}
                          />
                          <Column field="retry" style={{ display: "none" }} />
                          <Column field="billing" style={{ display: "none" }} />
                          <Column field="mode" style={{ display: "none" }} />
                          <Column
                            field="partner_id"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="partner_name"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="partner_password"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="partner_pin"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="partner_username"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="api_mode_key"
                            style={{ display: "none" }}
                          />
                          <Column field="c_url" style={{ display: "none" }} />

                          <Column
                            field="billing_type"
                            header="Billing type"
                            body={this.billingTypeColumnTemplate}
                            style={{ display: "none" }}
                          />
                          <Column
                            field="name"
                            header="Institution"
                            body={this.institutionColumnTemplate}
                          />
                          <Column
                            field="short_name"
                            header="Short Name"
                            body={this.shortNameColumnTemplate}
                          />
                          <Column
                            field="countries"
                            header="No. of Countries"
                            body={this.noOfCountriesColumnTemplate}
                          />
                          <Column
                            field="status"
                            header="Status"
                            body={this.statusColumnTemplate}
                          />
                          <Column
                            field="created_at"
                            header="Date"
                            body={this.dateColumnTemplate}
                          />
                          <Column
                            header="Action"
                            body={this.actionColumnTemplate}
                            style={{ width: "220px" }}
                          />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            header="Filters"
            visible={this.state.dialogVisible}
            onHide={() => this.setState({ dialogVisible: false })}
          >
            <div className="p-grid">
              <div
                className="p-field p-col-12 p-md-12 p-lg-12 p-xl-12"
                style={{ marginTop: "1.5em" }}
              >
                <span className="p-float-label">
                  <Calendar
                    id="range"
                    value={this.state.rangeValue}
                    onChange={(e) =>
                      this.setState(
                        { rangeValue: e.value, dateChange: "Y" },
                        () => this.getFilteredDataDate()
                      )
                    }
                    selectionMode="range"
                    readOnlyInput
                    showIcon={true}
                  />
                  <label htmlFor="calendar">DATE RANGE</label>
                </span>
              </div>
            </div>
            <div className="p-grid">
              <div
                className="p-field p-col-12 p-md-12 p-lg-12 p-xl-12"
                style={{ marginTop: "1.5em" }}
              >
                <span className="p-float-label">
                  <Dropdown
                    style={filterInputBoxStyle}
                    id="dropdown1"
                    options={this.state.status}
                    value={this.state.dropdownStatus}
                    onChange={(event) =>
                      this.setState(
                        { dropdownStatus: event.value, statusChanged: "Y" },
                        () => this.GetFilteredStatus()
                      )
                    }
                    autoWidth={false}
                  />
                  <label htmlFor="calendar">STATUS</label>
                </span>
              </div>
            </div>
            <div className="p-grid">
              <div
                className="p-field p-col-12 p-md-8 p-md-offset-2"
                style={{ marginTop: "1em" }}
              >
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <Dropdown
                      style={{ width: "100%" }}
                      options={this.state.searchFilter}
                      value={this.state.dropdownSearchFilter}
                      onChange={(event) =>
                        this.setState({ dropdownSearchFilter: event.value })
                      }
                      autoWidth={false}
                    />
                    <label style={floatLabel}>Select Filter</label>
                  </span>
                </div>
              </div>
              <div className="p-field p-col-12 p-md-8 p-md-offset-2">
                <div className="p-inputgroup">
                  <InputText
                    value={this.state.searchValue}
                    onChange={this.handleSearchValueChange}
                    placeholder="Search Value"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div
                className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Apply"
                  onClick={() => this.getData()}
                  icon="pi pi-search-plus"
                  style={{ width: "50%" }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={this.state.addInstDialog}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Add Institution"
            modal
            onHide={this.hideAddInstDialog}
          >
            <form onSubmit={this.addInstitution}>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ institutionName: e.target.value })
                          }
                          value={this.state.institutionName}
                          style={{ border: this.state.institutionNameError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Enter Institution Name
                        </label>
                      </span>
                    </div>
                    {/*<div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText onChange={(e) => this.setState({ userName: e.target.value })} value={this.state.userName} style={{ border: this.state.userNameError }} />
                                            <label htmlFor="dropdown1" style={labelStyle}>User Name</label>
                                        </span>
                                    </div>*/}
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ shortName: e.target.value })
                          }
                          value={this.state.shortName}
                          style={{ border: this.state.shortNameError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Short Name
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          value={this.state.email}
                          style={{ border: this.state.emailError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Email
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          type="number"
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          value={this.state.phone}
                          style={{ border: this.state.phoneError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Phone
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.billingType}
                          value={this.state.dropdownBillingType}
                          onChange={(event) =>
                            this.setState({
                              dropdownBillingType: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{
                            border: this.state.dropdownBillingTypeError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Billing Type
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ billing: e.target.value })
                          }
                          value={this.state.billing}
                          style={{ border: this.state.billingError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Billing Rate
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px", display: "none" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.userType}
                          value={this.state.dropdownUserType}
                          onChange={(event) =>
                            this.setState({
                              dropdownUserType: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{ border: this.state.dropdownUserTypeError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          User Type
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-8 p-md-8 p-lg-8 p-xl-8 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <label style={labelStyle}>Enable Retry</label>
                    </div>
                    <div
                      className="p-col-2 p-md-2 p-lg-2 p-xl-2"
                      style={{ marginTop: "10px" }}
                    >
                      <InputSwitch
                        checked={this.state.canUserRetry}
                        onChange={(event) =>
                          this.setState({ canUserRetry: event.value }, () =>
                            this.showHideRetryDiv()
                          )
                        }
                      />
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.canRetryDiv,
                      }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.retryOption}
                          value={this.state.retryOptionValue}
                          onChange={(event) =>
                            this.setState({
                              retryOptionValue: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{ border: this.state.retryOptionValueError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          No. of Retry
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.canRetryDiv,
                      }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.retryIntervalOption}
                          value={this.state.retryIntervalOptionValue}
                          onChange={(event) =>
                            this.setState({
                              retryIntervalOptionValue: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{
                            border: this.state.retryIntervalOptionValueError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Retry Interval
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Add"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.institutionDialog}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Institution Detail"
            modal
            onHide={this.hideInstitutionDetailDialog}
          >
            <span className={this.state.dialogStatus}>
              {this.state.dialogStatusText}
            </span>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div style={cardStyle}>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Institution Name</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogInstitutionName}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>
                        Institution Short Name
                      </label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogInstitutionShortName}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Email</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogEmail}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Mode</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.modeSelected}
                      </label>
                    </div>
                  </div>
                  <div
                    className="p-grid"
                    style={{ display: this.state.partnerNameDisplay }}
                  >
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Partner Name</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.partnerName}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Billing Type</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogBillingType}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Billing</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogBilling}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Phone</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogPhone}
                      </label>
                    </div>
                  </div>
                  <div
                    className="p-grid"
                    style={{ display: this.state.showRetryDetails }}
                  >
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Number of Retry</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.retryDetail}
                      </label>
                    </div>
                  </div>
                  <div
                    className="p-grid"
                    style={{ display: this.state.showRetryDetails }}
                  >
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Retry Interval</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.retryIntervalDetail}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Joined On</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogJoinedOn}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid" style={{ marginTop: "20px" }}>
                    <div className="p-col-6 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-xl-offset-1 profileTop">
                      <Link
                        to="/va"
                        onClick={() => this.navigate2VA(this.state.userId)}
                      >
                        <Button
                          label="Virtual Accounts"
                          className="p-button-info p-mr-2"
                          style={{ fontSize: "15px", padding: "7px" }}
                          data-tip="View Virtual Account"
                          data-for="toolTip1"
                          data-place="top"
                        />
                      </Link>
                    </div>
                    <div className="p-col-6 p-md-5 p-lg-5 p-xl-5 profileTop">
                      <Link
                        to="/institution-users"
                        onClick={() =>
                          this.navigate2InstitutionUsers(this.state.userId)
                        }
                      >
                        <Button
                          label="Institution Users"
                          className="p-button-success p-mr-2"
                          style={{ fontSize: "15px", padding: "7px" }}
                          data-tip="View Virtual Account"
                          data-for="toolTip1"
                          data-place="top"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={this.state.editInstitutionDialog}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Edit Institution"
            modal
            onHide={this.hideEditInstDialog}
          >
            <span className={this.state.dialogStatus}>
              {this.state.dialogStatusText}
            </span>
            <form onSubmit={this.editInstitution}>
              <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.editInstitutionName}
                          onChange={(event) =>
                            this.setState({
                              editInstitutionName: event.target.value,
                            })
                          }
                          style={{ border: this.state.editInstNameError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Enter Institution Name
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.editInstitutionEmail}
                          onChange={(event) =>
                            this.setState({
                              editInstitutionEmail: event.target.value,
                            })
                          }
                          style={{
                            border: this.state.editInstitutionEmailError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Enter Email
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.billingType}
                          value={this.state.dropdownEditBillingType}
                          onChange={(event) =>
                            this.setState({
                              dropdownEditBillingType: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{
                            border: this.state.dropdownEditBillingTypeError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Billing Type
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(e) =>
                            this.setState({ editBilling: e.target.value })
                          }
                          value={this.state.editBilling}
                          style={{ border: this.state.editBillingError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Billing
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.editStatus}
                          value={this.state.dropdownEditStatus}
                          onChange={(event) =>
                            this.setState({
                              dropdownEditStatus: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{ border: this.state.dropdownEditStatusError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Status
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-8 p-md-8 p-lg-8 p-xl-8 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <label style={labelStyle}>Enable Retry</label>
                    </div>
                    <div
                      className="p-col-2 p-md-2 p-lg-2 p-xl-2"
                      style={{ marginTop: "10px" }}
                    >
                      <InputSwitch
                        checked={this.state.canUserRetryEdit}
                        onChange={(event) =>
                          this.setState({ canUserRetryEdit: event.value }, () =>
                            this.showHideRetryDivEdit()
                          )
                        }
                      />
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.canRetryDivEdit,
                      }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.retryOption}
                          value={this.state.retryOptionValueEdit}
                          onChange={(event) =>
                            this.setState({
                              retryOptionValueEdit: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{
                            border: this.state.retryOptionValueEditError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          No. of Retry
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.canRetryDivEdit,
                      }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.retryIntervalOption}
                          value={this.state.retryIntervalOptionValueEdit}
                          onChange={(event) =>
                            this.setState({
                              retryIntervalOptionValueEdit: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{
                            border:
                              this.state.retryIntervalOptionValueEditError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Retry Interval
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Update"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.viewVirtualAccountDialog}
            style={{ width: "450px" }}
            header="Create Virtual Account"
            modal
            onHide={this.hideVirtualAccountDialog}
          >
            <form onSubmit={this.createVirtualAccount}>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          name="dialogName"
                          onChange={(event) =>
                            this.setState({ vaName: event.target.value })
                          }
                          value={this.state.vaName}
                          style={vaNameborder}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Virtual Account Name
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "10px" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          id="dropdown1"
                          options={this.state.accountType}
                          value={this.state.dropdownAccountType}
                          onChange={(event) =>
                            this.setState({
                              dropdownAccountType: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{
                            ...filterInputBoxStyle,
                            border: this.state.accountTypeError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Select Account Type
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          type="number"
                          name="dialogName"
                          onChange={(event) =>
                            this.setState({ vaThreshold: event.target.value })
                          }
                          value={this.state.vaThreshold}
                          style={{ border: this.state.vaThresholdError }}
                          min="1"
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Threshold Amount
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-5 p-lg-5 p-xl-5"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          style={{
                            filterInputBoxStyle,
                            border: this.state.currencyError,
                          }}
                          id="dropdown1"
                          options={this.state.currency}
                          value={this.state.dropdownCurrency}
                          onChange={(event) =>
                            this.setState({
                              dropdownCurrency: event.target.value,
                            })
                          }
                          autoWidth={false}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Select Currency
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-7 p-lg-offset-7 p-xl-offset-7">
                  <Button
                    label="Confirm"
                    className="p-button-info"
                    type="submit"
                    style={{ marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.addModeDialogVisible}
            style={{ width: "450px", overflowY: "visible" }}
            header="Assign Mode"
            modal
            onHide={this.hideAddMode}
          >
            <form onSubmit={this.addModeSubmit}>
              <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.modeType}
                          value={this.state.mode}
                          onChange={(event) =>
                            this.setState({ mode: event.target.value }, () =>
                              this.displayRPDropdown()
                            )
                          }
                          autoWidth={false}
                          style={{ border: this.state.modeError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Mode Type
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        display: this.state.displayKey,
                        marginTop: "24px",
                      }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(event) =>
                            this.setState({ cUrl: event.target.value })
                          }
                          value={this.state.cUrl}
                          style={{ border: this.state.cUrlError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Callback URL
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ display: this.state.showEditErrorMsg }}
                    >
                      <label
                        style={{
                          paddingLeft: "10px",
                          fontSize: "14px",
                          color: "red",
                        }}
                      >
                        {this.state.errorEditMsg}
                      </label>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ display: this.state.displayKey }}
                    >
                      <label
                        htmlFor="dropdown1"
                        style={{
                          paddingLeft: "17px",
                          fontSize: "12px",
                          color: "#999",
                          fontWeight: "500",
                        }}
                      >
                        Key
                      </label>
                      <label
                        className="edit_hover_class"
                        tooltip={this.state.copyToolTipText}
                        tooltipOptions={{ position: "right" }}
                      >
                        <div
                          className="tooltipClipBoard"
                          style={{ display: "block", paddingTop: "5px" }}
                        >
                          <span className="p-input-icon-right">
                            <InputText
                              value={this.state.generatedKey}
                              readOnly
                            />
                            <span
                              className="tooltiptext"
                              style={{ bottom: "100%", right: "0%" }}
                            >
                              {this.state.copyToolTipText}
                            </span>
                            <i
                              className="pi pi-copy"
                              onClick={() => this.copyKey()}
                            />
                          </span>
                        </div>
                      </label>
                      <label
                        htmlFor="dropdown1"
                        style={{
                          paddingLeft: "17px",
                          fontSize: "12px",
                          color: "#999",
                          fontWeight: "500",
                          float: "right",
                          paddingTop: "1em",
                          display: this.state.showRegenerateKey,
                        }}
                      >
                        <div
                          className="tooltipClipBoard"
                          style={{ display: "block" }}
                        >
                          <span>
                            <span
                              className="tooltiptext1"
                              style={{ bottom: "100%", right: "0%" }}
                            >
                              {this.state.regenerateToolTipText}
                            </span>
                            Regenerate Key&nbsp;
                            <i
                              className="pi pi-refresh"
                              onClick={() => this.regenerateKey()}
                            />
                          </span>
                        </div>
                      </label>
                    </div>
                    {/*<div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px',display:this.state.displayKey }}>
										<label htmlFor="dropdown1" style={labelStyle}>Key</label>
										<span className="p-input-icon-right">
											<InputText value={this.state.generatedKey} readOnly />
											<i className="pi pi-copy"  data-tip={this.state.copyToolTipText} data-for='toolTip1' data-place='top' onClick={()=>this.copyKey()}/>
											<ReactTooltip id="toolTip1" />
                                        </span>
                                    </div>*/}
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.displayRP,
                      }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.rp}
                          value={this.state.rpValue}
                          onChange={(event) =>
                            this.setState({ rpValue: event.target.value })
                          }
                          autoWidth={false}
                          style={{ border: this.state.rpValueError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Remittance Partner
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.displayRP,
                      }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(event) =>
                            this.setState({
                              partnerUsername: event.target.value,
                            })
                          }
                          value={this.state.partnerUsername}
                          style={{ border: this.state.partnerUsernameError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Username
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.displayRP,
                      }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(event) =>
                            this.setState({
                              partnerPassword: event.target.value,
                            })
                          }
                          value={this.state.partnerPassword}
                          style={{ border: this.state.partnerPasswordError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Password
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{
                        marginTop: "20px",
                        display: this.state.displayRP,
                      }}
                    >
                      <span className="p-float-label">
                        <InputText
                          onChange={(event) =>
                            this.setState({ partnerPin: event.target.value })
                          }
                          value={this.state.partnerPin}
                          style={{ border: this.state.partnerPinError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Pin
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Submit"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};
const mapDispatchToProps = {
  vaAction: VAAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(InstitutionList);
