import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LogoutAction } from './store/actions/LogoutAction';
import { Link } from 'react-router-dom';
import axios from "axios";
import queryString from 'query-string';
const qs = require('query-string');

class AppTopbar extends Component {

    constructor() {
        super();
        this.state = {
            showMenuBar: 'none',
        }
    }
    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }

    updateDimensions() {
        if (window.innerWidth < 1025) {
            {/*this.setState({ width: 450, height: 102 });*/ }
            this.setState({ showMenuBar: 'block' });
        }
        else {
            let update_width = window.innerWidth - 100;
            {/*let update_height = Math.round(update_width/4.4);
      		this.setState({ width: update_width, height: update_height });*/}
            this.setState({ showMenuBar: 'none' });
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }
	logout = async (event) => {
		console.log("IN USER LOGOUT");
		const config =
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const data =
        {
            token : this.props.userDetails._id
        }
		axios.post(global.config.main.baseUrl + 'logout', qs.stringify(data), config)
            .then(res=>{
                if(res.data.status === 200)
                {
					console.log(res.data.msg);
                }
                else
                {
					console.log(res.data.msg);
                }
            })
	}
    render() {
        return (
            <div className="layout-topbar clearfix">
                <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu} style={{ color: 'rgb(92,74,181)', marginTop: '12px', float: 'left', display: this.state.showMenuBar }}>
                    <span className="pi pi-bars" />
                </button>
                <div className="layout-topbar-icons">
                    <div className="topBarProfile">
                        <div className="layout-profile-userinfo" >
                            <span className="layout-topbar-item-name">{this.props.userDetails.userName}</span>
                            <Link to='/logout' style={{ color: 'rgb(92, 74, 181)' }} onClick={this.logout}>
                                <span className="layout-topbar-item-logout">Signout</span>
                            </Link>
                        </div>
                        <Link to='/profile' style={{color:'rgb(92, 74, 181)'}}>
                        	<img src="/assets/img/profile.jpg" alt="new" style={{ width: '50px' }} />
                        </Link>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    console.log('AppTopbar state', state.login.userDetails.userName);
    return {
        userDetails: state.login.userDetails,
    }
}

export default connect(mapStateToProps, null)(AppTopbar);
