import React, { Component } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactTooltip from "react-tooltip";
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { connect } from "react-redux";
import "../../config";
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
const floatLabel = {
  fontWeight: "500",
  color: "rgb(153, 153, 153)",
  fontSize: "12px",
};
class Provider extends Component {
  constructor() {
    super();
    document.title = "Provider - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      country: [],
      network: [
        { label: "Azercell", value: "Azercell" },
        { label: "Bakcell", value: "Bakcell" },
        { label: "Nar", value: "Nar" },
        { label: "Nakhtel", value: "Nakhtel" },
        { label: "MTS", value: "MTS" },
        { label: "A1", value: "A1" },
        { label: "Proximus", value: "Proximus" },
        { label: "Orange", value: "Orange" },
        { label: "BASE", value: "BASE" },
        { label: "BH Telecom", value: "BH Telecom" },
        { label: "HT Eronet", value: "HT Eronet" },
        { label: "Vivacom", value: "Vivacom" },
        { label: "Telenor", value: "Telenor" },
      ],
      status: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ],
      networkError: "",
      editNetworkError: "",
      editDescription: "",
      dropdownNetwork: "",
      dialogShortNameError: "",
      dialogRegionError: "",
      dialogSubRegionError: "",
      dialogSubRegionError: "",
      dropdownStatus: "",
      shortName: "",
      dropdownCountry: "",
      countryError: "",
      currency: "",
      currencyError: "",
      currencyError: "",
      shortNameError: "",
      region: "",
      regionError: "",
      subRegion: "",
      subRegionError: "",
      dialogEditCountryName: "",
      dialogCurrency: "",
      dialogSubRegion: "",
      dialogRegion: "",
      description: "",
      dialogNetwork: "",

      dialogShortName: "",

      countryId: "",
      showEdit: "",
      showFiltersWeb: "none",
      showFiltersMob: "none",
      dataTableValue: [],
      providerDialog: "",
      dataTableValueNew: [],
      dataViewValue: [],

      networkSuggestion: null,
      showLoader: "none",
      contentOpacity: "1",
      rangeValue: [],
      showConfigureApi: "",
      liveUrl: "",
      liveUrlError: "",
      liveKey: "",
      liveKeyError: "",
      sandBoxUrl: "",
      sandBoxUrlError: "",
      sandboxKey: "",
      sandboxKeyError: "",
      sandboxApiEnable: true,
      liveApiEnable: false,
      networkId: "",
      searchFilter: [
        { label: "Country", value: "country" },
        { label: "Provider", value: "network" },
      ],
      dropdownSearchFilter: "",
      searchValue: "",
      providerAliasValue: "",
      providerAliasValueError: "",
      dialogProviderAlias: "",
      dialogProviderAliasError: "",
      viewDialogProviderAlias: "",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.editCountryDialog = this.editCountryDialog.bind(this);
    this.configureApi = this.configureApi.bind(this);
    this.countryColumnTemplate = this.countryColumnTemplate.bind(this);
    this.shortNameColumnTemplate = this.shortNameColumnTemplate.bind(this);
    this.subRegionColumnTemplate = this.subRegionColumnTemplate.bind(this);
    this.currencyColumnTemplate = this.currencyColumnTemplate.bind(this);
    this.currencySymbolColumnTemplate =
      this.currencySymbolColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.regionColumnTemplate = this.regionColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.networkColumnTemplate = this.networkColumnTemplate.bind(this);
    this.aliasColumnTemplate = this.aliasColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);

    this.networks = [
      "Azercell",
      "Bakcell",
      "Nar",
      "Nakhtel",
      "MTS",
      "A1",
      "Proximus",
      "Orange",
      "BASE",
      "BH Telecom",
      "HT Eronet",
      "Vivacom",
      "Telenor",
      "MTN",
      "Airteltigo",
      "Vodafone",
    ];
  }
  enableSandbox = () => {
    this.setState({
      sandboxApiEnable: true,
      liveApiEnable: false,
    });
  };
  enableLive = () => {
    this.setState({
      sandboxApiEnable: false,
      liveApiEnable: true,
    });
  };
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  suggestNetwork(event) {
    let results = this.networks.filter((dropdownNetwork) => {
      return dropdownNetwork
        .toLowerCase()
        .startsWith(event.query.toLowerCase());
    });

    this.setState({ networkSuggestion: results });
  }

  componentDidMount() {
    if ("0" != this.props.userDetails.type) {
      this.setState({
        showEdit: "none",
      });
    } else {
      this.setState({
        showEdit: "inherit",
      });
    }
    {
      /*this.setState({
			country: this.props.userDetails.allCountries,
			network: this.props.userDetails.network
		});*/
    }
    this.updateDimensions();
    this.getNetworkList();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  submitConfigureApi = (event) => {
    event.preventDefault();
    if ("" == this.state.sandBoxUrl) {
      this.setState({
        sandBoxUrlError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        sandBoxUrlError: "",
      });
    }
    if ("" == this.state.sandboxKey) {
      this.setState({
        sandboxKeyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        sandboxKeyError: "",
      });
    }
    if ("" == this.state.liveUrl) {
      this.setState({
        liveUrlError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        liveUrlError: "",
      });
    }
    if ("" == this.state.liveKey) {
      this.setState({
        liveKeyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        liveKeyError: "",
      });
    }
    let liveEnabled;
    if (true == this.state.liveApiEnable) {
      liveEnabled = "1";
    } else {
      liveEnabled = "0";
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      network_id: this.state.networkId,
      s_url: this.state.sandBoxUrl,
      s_key: this.state.sandboxKey,
      l_url: this.state.liveUrl,
      l_key: this.state.liveKey,
      short_name: this.state.shortName,
      l_enable: liveEnabled,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    
    axios
      .post(
        global.config.main.baseUrl + "provider/api/add",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideConfigureApi();
          this.toastSuccess(res.data.msg);
          this.getNetworkList();
          this.setState({
            sandBoxUrl: "",
            sandboxKey: "",
            liveUrl: "",
            liveKey: "",
            liveApiEnable: false,
          });
        } else {
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };
  updateNetwork = (event) => {
    event.preventDefault();
    console.log(this.state.dialogEditCountryName);
    console.log(this.state.countryId);
    console.log(this.state.dialogNetwork);
    console.log(this.state.editDescription);
    if ("" == this.state.dialogNetwork) {
      this.setState({
        editNetworkError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        editNetworkError: "",
      });
    }
    if ("" == this.state.dialogProviderAlias) {
      this.setState({
        dialogProviderAliasError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogProviderAliasError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      country: this.state.dialogEditCountryName,
      name: this.state.dialogNetwork,
      description: this.state.editDescription,
      entry_id: this.state.countryId,
      alias: this.state.dialogProviderAlias,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    axios
      .post(
        global.config.main.baseUrl + "network/edit",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideEditCountryDialog();
          this.toastSuccess(res.data.msg);
          this.getNetworkList();
        } else {
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };
  getData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    else this.state.dialogVisible = false;
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "network/list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.setState({
            country: res.data.country,
          });
          console.log("NETWORK LIST");
          console.log(res.data);
          this.createDataTable(res.data.data);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getNetworkList = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      //start_date: startDate,
      //end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "network/list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.setState({
            country: res.data.country,
          });
          console.log("NETWORK LIST");
          console.log(res.data);
          this.createDataTable(res.data.data);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    console.log("DATATABLE VALUE");
    console.log(data);
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        country: d["country_name"],
        id: d["id"],
        network: d["name"],
        description: d["description"],
        modified_at: d["modified_at"],
        network_alias: d["network_alias"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  aliasColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Alias</span>
        <span>{rowData.network_alias}</span>
      </React.Fragment>
    );
  }
  countryColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Country</span>
        <span className={pTag}>{rowData.country}</span>
      </React.Fragment>
    );
  }
  networkColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Network</span>
        {rowData.network}
      </React.Fragment>
    );
  }

  shortNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Short Name</span>
        {rowData.short_name}
      </React.Fragment>
    );
  }

  regionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Region</span>
        {rowData.region}
      </React.Fragment>
    );
  }

  subRegionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Sub Region</span>
        {rowData.sub_region}
      </React.Fragment>
    );
  }

  currencyColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Currency</span>
        {rowData.currency}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        {rowData.modified_at}
      </React.Fragment>
    );
  }

  currencySymbolColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    let currencySymbol = "";
    if ("INR" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else if ("GHC" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else if ("NI" == rowData.currency) {
      currencySymbol = "&#x20B9;";
    } else {
      currencySymbol = "&#x20B9;";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Currency Symbol</span>
        <span style={{ width: "40%" }} className={pTag}>
          {rowData.currency}
        </span>
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let ptag = rowData.status;
    let pTag = "";
    let status = "";
    if (ptag == "1") {
      status = "ACTIVE";
      pTag = "p-tag p-tag-success";
    } else {
      status = "IN-ACTIVE";
      pTag = "p-tag p-tag-danger";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-pencil"
          className="p-button-info p-mr-2"
          onClick={() => this.editCountryDialog(rowData)}
          style={{
            fontSize: "15px",
            padding: "7px",
            display: this.state.showEdit,
          }}
          data-tip="Edit Provider"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <Button
          label=""
          icon="pi pi-eye"
          className="p-button-success p-mr-2"
          onClick={() => this.viewProviderDetails(rowData)}
          style={{ border: "0", fontSize: "15px", padding: "7px" }}
          data-tip="View Provider Details"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <Button
          label=""
          icon="pi pi-plus"
          className="p-button-info p-mr-2"
          onClick={() => this.configureApi(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="Configure API"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }
  viewProviderDetails = (rowData) => {
    console.log(rowData);
    this.setState({
      viewDialogProviderAlias: rowData.network_alias,
      viewDialogCountry: rowData.country,
      viewDialogNetwork: rowData.network,
      viewDialogDescription: rowData.description,
      viewDialogDate: rowData.modified_at,
      providerDialog: true,
    });
  };
  hideProviderDetails = (rowData) => {
    this.setState({
      providerDialog: false,
    });
  };
  editCountryDialog(rowData) {
    console.log(rowData);
    if (0 == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-pending",
        dialogStatusText: "Inactive",
        dropdownStatus: "0",
      });
    } else if (1 == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
        dropdownStatus: "1",
      });
    }
    this.setState({
      dialogProviderAlias: rowData.network_alias,
      dialogEditCountryName: rowData.country,
      dialogNetwork: rowData.network,
      editDescription: rowData.description,
      countryId: rowData.id,
      editCountryDialog: true,
    });
  }
  configureApi = (rowData) => {
    console.log(rowData);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      network_id: rowData.id,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "provider/api/get",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          if ("1" == res.data.l_enable) {
            this.setState({
              liveApiEnable: true,
            });
          } else {
            this.setState({
              liveApiEnable: false,
            });
          }
          this.setState(
            {
              liveUrl: res.data.l_url,
              liveKey: res.data.l_key,
              sandBoxUrl: res.data.s_url,
              sandboxKey: res.data.s_key,
            },
            () => this.setState({ showConfigureApi: true })
          );
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
    this.setState({
      networkId: rowData.id,
    });
  };
  hideConfigureApi = (rowData) => {
    console.log(rowData);
    this.setState({
      liveUrl: "",
      liveUrlError: "",
      liveKey: "",
      liveKeyError: "",
      sandBoxUrl: "",
      sandBoxUrlError: "",
      sandboxKey: "",
      sandboxKeyError: "",
      showConfigureApi: false,
    });
  };

  hideEditCountryDialog = () => {
    this.setState({ editCountryDialog: false });
  };

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getNetworkListByDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        start_date: startDate,
        end_date: endDate,
        token: this.props.userDetails._id,
      };
      axios
        .post(
          global.config.main.baseUrl + "network/list",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
            this.setState({
              country: res.data.country,
            });
            this.createDataTable(res.data.data);
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };

  getSearchedData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    else this.state.dialogVisible = false;
    this.getTransactionDataSearchValue();
  };

  openAddForexDialog = () => {
    this.state.addNetworkDialog = true;
  };

  hideAddForexDialog = () => {
    this.setState({
      dropdownCountry: "",
      dropdownNetwork: "",
      providerAliasValue: "",
      description: "",
    });
    this.state.addNetworkDialog = false;
  };

  addNetwork = (event) => {
    event.preventDefault();
    console.log(this.state.dropdownNetwork);
    if ("" == this.state.dropdownCountry) {
      this.setState({
        countryError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        countryError: "",
      });
    }
    if ("" == this.state.dropdownNetwork) {
      this.setState({
        networkError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        networkError: "",
      });
    }
    if ("" == this.state.providerAliasValue) {
      this.setState({
        providerAliasValueError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        providerAliasValueError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      country: this.state.dropdownCountry,
      name: this.state.dropdownNetwork,
      description: this.state.description,
      alias: this.state.providerAliasValue,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    axios
      .post(
        global.config.main.baseUrl + "network/add",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getNetworkList();
          this.hideAddForexDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            dropdownCountry: "",
            dropdownNetwork: "",
          });
        } else {
          this.hideAddForexDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  render() {
    const addForex = "assets/layout/images/forex_add.png";
    const detailsStyle = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "right",
    };
    const detailsStyleNew = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "left",
    };
    const checkHistory = "assets/layout/images/refresh.png";
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };

    return (
      <div>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div
          className="layout-content"
          style={{ opacity: this.state.contentOpacity }}
        >
          <ProgressSpinner
            style={{
              display: this.state.showLoader,
              width: "40px",
              height: "40px",
              position: "fixed",
              top: "50%",
              left: "50%",
              bottom: "50%",
              right: "50%",
              zIndex: "1111111111",
            }}
            strokeWidth="3"
            animationDuration=".5s"
          />
          <div>
            <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
              <div className="p-col-12 p-md-12 p-xl-12">
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    {/*<div className="card card-w-title">*/}
                    <div>
                      <span className="p-tag custom-heading">Provider</span>
                    </div>
                  </div>
                </div>

                <div className="p-grid transaction">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "2px",
                        display: this.state.showFiltersMob,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          icon="pi pi-sliders-h"
                          label="Filters"
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            border: "1px solid #f0f0f0",
                            color: "#999",
                            float: "left",
                            width: "75%",
                          }}
                          onClick={() => this.setState({ dialogVisible: true })}
                        />
                      </div>
                      <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          icon="pi pi-download"
                          onClick={this.exportCSV}
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#999",
                            float: "right",
                          }}
                          data-tip="Download Provider List"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.openAddForexDialog()}
                            className="p-button-info"
                            style={{ float: "right" }}
                            data-tip="Add Provider"
                            data-for="toolTip1"
                            data-place="top"
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                    <div
                      className="p-grid p-fluid"
                      style={{
                        marginTop: "24px",
                        display: this.state.showFiltersWeb,
                      }}
                    >
                      <div className="p-field p-col-6 p-md-3 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            id="range"
                            value={this.state.rangeValue}
                            onChange={(e) =>
                              this.setState(
                                { rangeValue: e.value, dateChange: "Y" },
                                () => this.getNetworkListByDate()
                              )
                            }
                            selectionMode="range"
                            readOnlyInput
                            showIcon={true}
                          />
                          <label htmlFor="calendar">DATE RANGE</label>
                        </span>
                      </div>

                      <div className="p-col-12 p-md-4 p-xl-4 p-xl-offset-3">
                        <div className="p-grid">
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingRight: "2px" }}
                          >
                            <div className="p-inputgroup">
                              <span className="p-float-label">
                                <Dropdown
                                  style={{ width: "20%" }}
                                  options={this.state.searchFilter}
                                  value={this.state.dropdownSearchFilter}
                                  onChange={(event) =>
                                    this.setState({
                                      dropdownSearchFilter: event.value,
                                    })
                                  }
                                  autoWidth={false}
                                />
                                <label style={floatLabel}>Select Filter</label>
                              </span>
                            </div>
                          </div>
                          <div
                            className="p-col-6 p-xl-6"
                            style={{ paddingLeft: "0.4px" }}
                          >
                            <div className="p-inputgroup">
                              <InputText
                                value={this.state.searchValue}
                                onChange={this.handleSearchValueChange}
                                placeholder="Search Value"
                              />
                              <Button
                                onClick={() => this.getData()}
                                icon="pi pi-search"
                                className="p-button-info"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-4 p-md-2 p-xl-2">
                        <Button
                          icon="pi pi-download"
                          onClick={this.exportCSV}
                          className="p-button-info"
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#999",
                            border: "1px solid #f0f0f0",
                          }}
                          data-tip="Download Provider List"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="detail span-custom-success"
                          style={detailsStyleNew}
                        >
                          <Button
                            icon="pi pi-plus"
                            onClick={() => this.openAddForexDialog()}
                            className="p-button-info"
                            style={{
                              float: "right",
                              border: "1px solid rgb(92, 74, 181)",
                            }}
                            data-tip="Add Provider"
                            data-for="toolTip1"
                            data-place="top"
                          />
                          &nbsp;&nbsp;&nbsp;
                        </span>
                        <ReactTooltip id="toolTip1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ padding: "0" }}
                  >
                    <div className="datatable-responsive-demo">
                      <div className="card">
                        <DataTable
                          className="p-datatable-responsive-demo"
                          resizableColumns
                          columnResizeMode="expand"
                          value={this.state.dataTableValue}
                          paginator={true}
                          rows={10}
                          ref={(el) => {
                            this.dt = el;
                          }}
                        >
                          <Column field="id" style={{ display: "none" }} />
                          <Column
                            field="description"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="network_alias"
                            style={{ display: "none" }}
                          />
                          <Column
                            field="country"
                            header="Country"
                            body={this.countryColumnTemplate}
                          />
                          <Column
                            field="network"
                            header="Provider"
                            body={this.networkColumnTemplate}
                          />
                          <Column
                            field="modified_at"
                            header="Date"
                            body={this.dateColumnTemplate}
                          />
                          <Column
                            header="Action"
                            body={this.actionColumnTemplate}
                          />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            header="Filters"
            visible={this.state.dialogVisible}
            onHide={() => this.setState({ dialogVisible: false })}
          >
            <div className="p-grid">
              <div
                className="p-field p-col-12 p-md-8 p-md-offset-2"
                style={{ marginTop: "1.5em" }}
              >
                <span className="p-float-label">
                  <Calendar
                    id="range"
                    value={this.state.rangeValue}
                    onChange={(e) =>
                      this.setState(
                        { rangeValue: e.value, dateChange: "Y" },
                        () => this.getNetworkListByDate()
                      )
                    }
                    selectionMode="range"
                    readOnlyInput
                    showIcon={true}
                    style={{ width: "100%" }}
                  />
                  <label htmlFor="calendar">DATE RANGE</label>
                </span>
              </div>
              <div
                className="p-field p-col-12 p-md-8 p-md-offset-2"
                style={{ marginTop: "1em" }}
              >
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <Dropdown
                      style={{ width: "20%" }}
                      options={this.state.searchFilter}
                      value={this.state.dropdownSearchFilter}
                      onChange={(event) =>
                        this.setState({ dropdownSearchFilter: event.value })
                      }
                      autoWidth={false}
                    />
                    <label style={floatLabel}>Select Filter</label>
                  </span>
                </div>
              </div>
              <div className="p-field p-col-12 p-md-8 p-md-offset-2">
                <div className="p-inputgroup">
                  <InputText
                    value={this.state.searchValue}
                    onChange={this.handleSearchValueChange}
                    placeholder="Search Value"
                  />
                </div>
              </div>
              <div
                className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Apply"
                  onClick={() => this.getData()}
                  icon="pi pi-search-plus"
                  style={{ width: "50%" }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={this.state.addNetworkDialog}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Add Provider"
            modal
            onHide={this.hideAddForexDialog}
          >
            <form onSubmit={this.addNetwork}>
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <Dropdown
                          options={this.state.country}
                          value={this.state.dropdownCountry}
                          onChange={(event) =>
                            this.setState({
                              dropdownCountry: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{ border: this.state.countryError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Country
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        {/*<Dropdown options={this.state.network} value={this.state.dropdownNetwork} onChange={event => this.setState({ dropdownNetwork: event.target.value })} autoWidth={false} style={{ border: this.state.networkError }} />*/}
                        <AutoComplete
                          value={this.state.dropdownNetwork}
                          onChange={(e) =>
                            this.setState({ dropdownNetwork: e.value })
                          }
                          suggestions={this.state.networkSuggestion}
                          completeMethod={this.suggestNetwork.bind(this)}
                          style={{ border: this.state.networkError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Provider
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.providerAliasValue}
                          onChange={(e) =>
                            this.setState({
                              providerAliasValue: e.target.value,
                            })
                          }
                          style={{ border: this.state.providerAliasValueError }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Provider Alias
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputTextarea
                          value={this.state.description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          rows={5}
                          cols={30}
                          autoResize
                        />

                        <label htmlFor="dropdown1" style={labelStyle}>
                          Description
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Add"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>

          <Dialog
            visible={this.state.editCountryDialog}
            style={{ width: "450px" }}
            header="Edit Provider"
            modal
            onHide={this.hideEditCountryDialog}
          >
            {/*<span className={this.state.dialogStatus}>{this.state.dialogStatusText}</span>*/}
            <form onSubmit={this.updateNetwork}>
              <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        {/*<InputText value={this.state.dialogEditCountryName} style={{color:'rgb(140, 140, 140)',backgroundColor:'rgb(212, 212, 212)'}} readOnly/>*/}
                        <Dropdown
                          options={this.state.country}
                          value={this.state.dialogEditCountryName}
                          onChange={(event) =>
                            this.setState({
                              dialogEditCountryName: event.target.value,
                            })
                          }
                          autoWidth={false}
                          style={{
                            border: this.state.dialogEditCountryNameError,
                          }}
                        />
                        <label style={labelStyle}>Country</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dialogNetwork}
                          onChange={(e) =>
                            this.setState({ dialogNetwork: e.target.value })
                          }
                          style={{ border: this.state.editNetworkError }}
                        />
                        <label style={labelStyle}>Provider</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.dialogProviderAlias}
                          onChange={(e) =>
                            this.setState({
                              dialogProviderAlias: e.target.value,
                            })
                          }
                          style={{
                            border: this.state.dialogProviderAliasError,
                          }}
                        />
                        <label htmlFor="dropdown1" style={labelStyle}>
                          Provider Alias
                        </label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputTextarea
                          value={this.state.editDescription}
                          onChange={(e) =>
                            this.setState({ editDescription: e.target.value })
                          }
                          rows={5}
                          cols={30}
                          autoResize
                        />

                        <label htmlFor="dropdown1" style={labelStyle}>
                          Description
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Update"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1.5em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.showConfigureApi}
            style={{ width: "450px", overflowY: "scroll" }}
            header="Configure API"
            modal
            onHide={this.hideConfigureApi}
          >
            <form onSubmit={this.submitConfigureApi}>
              <div className="p-grid p-fluid" style={{ marginTop: "1em" }}>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <div className="p-grid p-fluid">
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "10px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.sandBoxUrl}
                          onChange={(e) =>
                            this.setState({ sandBoxUrl: e.target.value })
                          }
                          style={{ border: this.state.sandBoxUrlError }}
                        />
                        <label style={labelStyle}>Sandbox API URL</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.sandboxKey}
                          onChange={(e) =>
                            this.setState({ sandboxKey: e.target.value })
                          }
                          style={{ border: this.state.sandboxKeyError }}
                        />
                        <label style={labelStyle}>Sandbox API Key</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.liveUrl}
                          onChange={(e) =>
                            this.setState({ liveUrl: e.target.value })
                          }
                          style={{ border: this.state.liveUrlError }}
                        />
                        <label style={labelStyle}>Live API URL</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "20px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.liveKey}
                          onChange={(e) =>
                            this.setState({ liveKey: e.target.value })
                          }
                          style={{ border: this.state.liveKeyError }}
                        />
                        <label style={labelStyle}>Live API Key</label>
                      </span>
                    </div>
                    <div
                      className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "10px" }}
                    >
                      <span className="p-float-label">
                        <InputText
                          value={this.state.shortName}
                          onChange={(e) =>
                            this.setState({ shortName: e.target.value })
                          }
                        />
                        <label style={labelStyle}>Short Name</label>
                      </span>
                    </div>
                    <div
                      className="p-col-8 p-md-8 p-lg-8 p-xl-8 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ marginTop: "10px" }}
                    >
                      <label style={labelStyle}>Enable Live API</label>
                    </div>
                    <div
                      className="p-col-2 p-md-2 p-lg-2 p-xl-2"
                      style={{ marginTop: "10px" }}
                    >
                      {/*<InputSwitch checked={this.state.liveApiEnable} onChange={this.enableLive}/>*/}
                      <InputSwitch
                        checked={this.state.liveApiEnable}
                        onChange={(event) =>
                          this.setState({ liveApiEnable: event.value })
                        }
                      />
                    </div>
                    <div
                      className="p-col-10 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                      style={{ fontStyle: "italic" }}
                    >
                      <label style={labelStyle}>
                        <b>Note: </b>Sandbox API is enabled if Live API is
                        disabled
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid p-fluid">
                <div
                  className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    label="Configure"
                    className="p-button-info"
                    type="submit"
                    style={{ width: "50%", marginTop: "1em" }}
                  />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            visible={this.state.providerDialog}
            style={{ width: "450px", overflowY: "auto" }}
            header="Provider Detail"
            modal
            onHide={this.hideProviderDetails}
          >
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div style={cardStyle}>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Country</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogCountry}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Provider</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogNetwork}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Provider Alias</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogProviderAlias}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Date</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogDate}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleLeft}>Description</label>
                    </div>
                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.viewDialogDescription}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(Provider);
