import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import "../../config";
const qs = require("query-string");

const checkHistory = "assets/layout/images/refresh.png";
const topUp = "assets/layout/images/topup.png";
const cardStyle = { borderRadius: "20px" };
const detailsStyle = {
  fontSize: "8px",
  paddingTop: "0.8em",
  color: "rgb(189,220,254)",
};
const buttonDetailsStyle = { fontSize: "10px" };
const topupButtonStyle = {
  padding: "5px",
  background: "rgb(92, 74, 181)",
  border: "0",
  color: "#fff",
  paddingRight: "10px",
  float: "right",
};
const cardHeadingStyle = { color: "#5c4ab5", fontWeight: "600" };

class VirtualAccounts extends Component {
  constructor() {
    super();
    document.title = "Virtual Accounts - Instant Credit";
    this.state = {
      data: [],
      items: [],
      balanceAmountTooltip: "",
    };
  }

  componentDidMount() {
    this.GetVirtualAccountData();
  }

  navigate2Topup = (id) => {
    let payload = { vaid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/top-up");
      } else {
        alert("Failed");
      }
    });
  };
  navigate2TopupHistory = (id) => {
    let payload = { vaid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/top-up-history");
      } else {
        alert("Failed");
      }
    });
  };

  GetVirtualAccountData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
    };
    axios
      .post(global.config.main.baseUrl + "list-va", qs.stringify(data), config)
      .then((res) => {
        if (res.data.status === 200) {
          const va_id = res.data.data[0].va_id;

          // Saving va_id to session storage
          sessionStorage.setItem("VA_ID", va_id);
          console.log(res.data.data);
          this.state.data = res.data.data;
          this.state.data.forEach((d) => {
            let str = d["balance"];
            this.setState({
              balanceAmountTooltip: d["balance"],
            });

            let tipAmount =
              d["currency"] +
              " " +
              this.state.balanceAmountTooltip.toLocaleString();
            this.state.items.push(
              <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                  <div className="highlight-details ">
                    <span style={cardHeadingStyle}>{d["account_name"]}</span>
                    <span
                      className="count"
                      data-tip={tipAmount}
                      data-for="toolTip1"
                      data-place="top"
                      style={{
                        paddingTop: "1em",
                        wordWrap: "break-word",
                        display: "inline-block",
                        maxWidth: "100%",
                      }}
                    >
                      {d["currency"]}&nbsp;{str.toLocaleString()}
                    </span>
                    <ReactTooltip id="toolTip1" />
                    <div className="p-grid cardFooter">
                      <div className="p-col-7 p-md-8 p-lg- p-xl-6">
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <img
                            src={checkHistory}
                            style={{ height: "15px", width: "15px" }}
                          />
                          <Link
                            to="/top-up-history"
                            onClick={() =>
                              this.navigate2TopupHistory(d["va_id"])
                            }
                            style={{ color: "rgb(189, 220, 254)" }}
                          >
                            Check History
                          </Link>
                        </span>
                      </div>
                      <div className="p-col-5 p-md-4 p-lg-4 p-xl-6">
                        <button
                          style={topupButtonStyle}
                          onClick={() => this.navigate2Topup(d["va_id"])}
                        >
                          <span>
                            <img
                              src={topUp}
                              alt="my image"
                              style={{ width: "10px" }}
                            />
                          </span>
                          <span>Top up</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          this.setState({ items2: this.state.item });
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">Virtual Accounts</span>
              </div>
            </div>
            <div className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
              <div
                className="p-grid p-fluid virtualAccount card"
                style={cardStyle}
              >
                {this.state.items}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

const mapDispatchToProps = {
  vaAction: VAAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualAccounts);
