export const LoginAction = (payload) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let result = { ...payload };
    let date = new Date();
    result.userName = payload.username;
    result.date = date.toDateString();
    result._id = payload.userId;
    result.isLogin = "Y";
    result.isLoggedOut = "N";
    result.allCountries = payload.allCountries;
    result.currency = payload.currency;
    result.type = payload.type;
    result.reqcount = payload.reqcount;
    result.noOfUser = payload.noOfUser;
    result.noOfUserReq = payload.noOfUserReq;
    result.noOfInstUser = payload.noOfInstUser;
    result.network = payload.network;
    result.rp = payload.rp;

    dispatch({ type: "LOGIN_ACTION_KEY", payload: result });
    resolve({ success: true });
    // setTimeout(() => {
    //     alert("LoginAction");
    //     let result = { ...payload }
    //     let date = new Date();
    //     result.date = date.toDateString();
    //     result._id = Math.random();
    //     dispatch({ type: "LOGIN_ACTION_KEY", payload: result });
    //     resolve({ success: true })
    // }, 3000);
  });
};
