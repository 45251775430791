import React, { Component } from "react";
import classNames from "classnames";
import AppTopbar from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";
import { Route, Redirect } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./layout/layout.scss";
import "./App.scss";
import { LoginAction } from "./store/actions/LoginAction";
import { connect } from "react-redux";

import { IdleTimeoutManager } from "idle-timer-manager";

class PrivateRoute extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      dummyValue: "",
      reqcount: "",
      manager: "",
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  createAdminMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/global2.png",
          to: "/countries",
          label: "Countries",
        },
        {
          icon: "assets/layout/images/network.png",
          to: "/provider",
          label: "Provider",
        },
        {
          icon: "assets/layout/images/building2.png",
          to: "/institution",
          label: "Institution",
        },
        {
          icon: "assets/layout/images/bank.png",
          to: "/remittance-partner",
          label: "Remittance Partner",
        },
        {
          icon: "assets/layout/images/taraju.png",
          to: "/forex",
          label: "Forex",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/mUser.png",
          to: "/users",
          label: "Users",
        },
        {
          icon: "assets/layout/images/userQuest.png",
          to: "/requests",
          label: "Request",
          badge: this.props.userDetails.reqcount,
        },
        {
          icon: "assets/layout/images/report1.png",
          to: "/audit-trail",
          label: "Audit Trail",
        },
        {
          icon: "assets/layout/images/admin.png",
          to: "/settings",
          label: "Settings",
        },
      ];
    }
  }
  createAdminUserMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/global2.png",
          to: "/countries",
          label: "Countries",
        },
        {
          icon: "assets/layout/images/network.png",
          to: "/provider",
          label: "Provider",
        },
        {
          icon: "assets/layout/images/building2.png",
          to: "/institution-list",
          label: "Institution",
        },
        {
          icon: "assets/layout/images/bank.png",
          to: "/remittance-partner",
          label: "Remittance Partner",
        },
        {
          icon: "assets/layout/images/taraju.png",
          to: "/forex",
          label: "Forex",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/mUser.png",
          to: "/users",
          label: "Users",
        },
        {
          icon: "assets/layout/images/admin.png",
          to: "/settings",
          label: "Settings",
        },
      ];
    }
  }
  createFinanceAdminMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/building2.png",
          to: "/institutions",
          label: "Institution List",
        },
        {
          icon: "assets/layout/images/taraju.png",
          to: "/forex",
          label: "Forex",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/userQuest.png",
          to: "/requests",
          label: "Request",
        },
      ];
    }
  }
  createFinanceUserMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/building2.png",
          to: "/institutions",
          label: "Institution List",
        },
        {
          icon: "assets/layout/images/taraju.png",
          to: "/forex",
          label: "Forex",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/userQuest.png",
          to: "/requests",
          label: "Request",
          badge: this.props.userDetails.reqcount,
        },
      ];
    }
  }
  createHelpDeskAdminMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/building2.png",
          to: "/institutions",
          label: "Institution List",
        },
        {
          icon: "assets/layout/images/taraju.png",
          to: "/forex",
          label: "Forex",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/userQuest.png",
          to: "/request",
          label: "Request",
        },
      ];
    }
  }
  createHelpDeskUserMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/building2.png",
          to: "/institutions",
          label: "Institution List",
        },
        {
          icon: "assets/layout/images/taraju.png",
          to: "/forex",
          label: "Forex",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/userQuest.png",
          to: "/request",
          label: "Request",
        },
      ];
    }
  }
  createInstAdminMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/account.png",
          to: "/va",
          label: "Virtual Account",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/mUser.png",
          to: "/users",
          label: "Admin",
        },
      ];
    }
  }
  createInstAdminAdminMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/account.png",
          to: "/va",
          label: "Virtual Account",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        {
          icon: "assets/layout/images/mUser.png",
          to: "/users",
          label: "Admin",
        },
      ];
    }
  }
  createInstUserMenu() {
    {
      this.menu = [
        {
          icon: "assets/layout/images/dashboard.png",
          to: "/dashboard",
          label: "Dashboard",
        },
        {
          icon: "assets/layout/images/trans.png",
          to: "/transactions",
          label: "Transactions",
        },
        {
          icon: "assets/layout/images/account.png",
          to: "/va",
          label: "Virtual Account",
        },
        {
          icon: "assets/layout/images/report.png",
          to: "/report",
          label: "Reports",
        },
        //{ icon: 'assets/layout/images/mUser.png', to: '/users', label: "Admin" },
      ];
    }
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }
  showMenu() {
    if ("7" == this.props.userDetails.type) {
      return (
        <AppMenu model={this.menu1} onMenuItemClick={this.onMenuItemClick} />
      );
    } else {
      return (
        <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
      );
    }
  }

  componentDidMount = () => {
    if ("0" == this.props.userDetails.type) {
      this.createAdminMenu();
    } else if ("1" == this.props.userDetails.type) {
      this.createAdminUserMenu();
    } else if ("101" == this.props.userDetails.type) {
      //{this.props.userDetails.reqcount != 0 ? (this.state.reqcount = this.props.userDetails.reqcount) : (this.state.reqcount = '')};
      this.createFinanceAdminMenu();
    } else if ("102" == this.props.userDetails.type) {
      this.createFinanceUserMenu();
    } else if ("201" == this.props.userDetails.type) {
      this.createHelpDeskAdminMenu();
    } else if ("202" == this.props.userDetails.type) {
      this.createHelpDeskUserMenu();
    } else if ("301" == this.props.userDetails.type) {
      this.createInstAdminMenu();
    } else if ("302" == this.props.userDetails.type) {
      this.createInstAdminAdminMenu();
    } else if ("303" == this.props.userDetails.type) {
      this.createInstUserMenu();
    }

    this.setState({ dummyValue: "dummyValue" });

    console.log("setting timer");
    this.setState({
      manager: new IdleTimeoutManager({
        timeout: 300, //expired after 300 secs
        onExpired: (time) => {
          this.setState({ timeout: true }, () => {
            if (this.state.manager) {
              this.state.manager.clear();
            }
            window.location = "/logout";
          });
        },
      }),
    });
  };

  componentWillUnmount() {
    if (this.state.manager) {
      this.state.manager.clear();
    }
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  isAuthenticated() {
    if (this.props.userDetails.isLogin == "Y") {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    //const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg' : 'assets/layout/images/logo.svg';
    const logo = "assets/layout/images/instantCreditLogo.png";
    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
      "layout-sidebar-light": this.state.layoutColorMode === "light",
    });

    const renderRoute = (props) => {
      if (this.isAuthenticated()) {
        return (
          <div className={wrapperClass} onClick={this.onWrapperClick}>
            <AppTopbar onToggleMenu={this.onToggleMenu} />
            <div
              ref={(el) => (this.sidebar = el)}
              className={sidebarClassName}
              onClick={this.onSidebarClick}
            >
              <div className="layout-logo">
                <img
                  alt="Logo"
                  src={logo}
                  style={{ width: "115px", height: "4.0em" }}
                />
              </div>
              {/*<AppProfile />*/}
              {this.showMenu()}
            </div>
            <div className="layout-main">
              <Component {...props} />
            </div>
          </div>
        );
      }

      const to = {
        pathname: "/login",
        state: { from: props.location },
      };

      return <Redirect to={to} />;
    };

    return <Route {...rest} render={renderRoute} />;
  }
}

const mapStateToProps = (state) => {
  //console.log('Private Router state', state);
  return {
    userDetails: state.login.userDetails,
  };
};
const mapDispatchToProps = {
  loginAction: LoginAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
