import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { connect } from "react-redux";
import { format } from "date-fns";
import "../../config";
import axios from "axios";
import ReportModal from "./ReportModal";
import { jwtDecode } from "jwt-decode";

const qs = require("query-string");

const DEFAULT_PAGE_SIZE = 50;

class AdminReportV2 extends Component {
  constructor() {
    super();
    document.title = "Report - Instant Credit";
    this.state = {
      searchFilter: [
        { label: "Destination", value: "phone" },
        { label: "Transaction Id", value: "trans_id" },
        { label: "Institution Id", value: "inst_id" },
      ],
      firstCol: "none",
      secondCol: "none",
      thirdCol: "none",
      fourthCol: "none",
      fifthCol: "none",
      sixthCol: "none",
      seventhCol: "none",
      eightthCol: "none",
      paginator: false,
      totalRecords: 0,
      lazyParams: {
        first: 0,
        rows: DEFAULT_PAGE_SIZE,
        page: 1,
      },
      rangeValue: [],
      actionIcon: "",
      dateChange: "N",
      dialogStatus: "",
      dialogStatusText: "",
      searchValue: "",
      dataTableDummyValue: "",
      previousFunction: null,
      dialogVisible: false,
      transactionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      submitted: false,
      dropdownSearchFilter: "",
      searchValueText: "",
      status: null,
      dataTableValue: [],
      dataTableValueNew: [],
      selectedFile: null,
      documents: [],
      layout: "list",
      showLoader: "none",
      contentOpacity: "1",
      rangeValue: [],
      mobileView: "",
      webView: "",
      statusFilter: [
        { label: "All", value: "ALL" },
        { label: "Initiated", value: "INITIATED" },
        { label: "Queued", value: "QUEUED" },
        { label: "Failed", value: "FAILED" },
        { label: "Processing", value: "PROCESSING" },
        { label: "Ready", value: "READY" },
      ],
      selectedStatus: "",
      isDialogOpen: false,
      reportData: [],
    };

    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = today;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);

    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    this.filterByJobStatus();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  filterByJobStatus = async (page = 0) => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const token = this.props.userDetails._id;
    const decodedToken = jwtDecode(token);

    const { user_id } = decodedToken;

    const { selectedStatus } = this.state;

    let queryParams = `?page=${page}&platformUserId=${user_id}&size=${DEFAULT_PAGE_SIZE}`;

    if (selectedStatus && selectedStatus !== "ALL") {
      queryParams += `&status=${encodeURIComponent(selectedStatus)}`;
    }

    const url = global.config.reportservice.baseUrl + queryParams;

    axios
      .get(url, config)
      .then((response) => {
        console.log("response from get :: ", response);
        if (response.status === 200 || response.status === 201) {
          const responseData = response.data.data;
          console.log("response from get :: ", responseData);

          const totalPages = responseData.totalPages;
          const totalElements = responseData.totalElements;
          const currentPage = responseData.page;

          this.setState({
            reportData: responseData.data,
            totalRecords: totalElements,
            totalReportPages: totalPages,
            currentPage: currentPage,
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
        console.error("Error occurred while fetching report data:", error);
      });
  };

  filterDataByDate = async (page = 0) => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const token = this.props.userDetails._id;
    const decodedToken = jwtDecode(token);

    const { user_id } = decodedToken;
    const startDate = format(this.state.rangeValue[0], "yyyy-MM-dd");
    const endDate = format(this.state.rangeValue[1], "yyyy-MM-dd");

    const url =
      global.config.reportservice.baseUrl +
      `?page=${page}&platformUserId=${user_id}&size=${DEFAULT_PAGE_SIZE}&startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;

    axios
      .get(url, config)
      .then((response) => {
        console.log("response from get :: ", response);
        if (response.status === 200 || response.status === 201) {
          const responseData = response.data.data;
          console.log("response from get :: ", responseData);

          const totalPages = responseData.totalPages;
          const currentPage = responseData.page;

          console.log("Total Pages:", totalPages);
          console.log("Current Page:", currentPage);

          this.setState({
            reportData: responseData.data,
            totalReportPages: totalPages,
            totalRecords: totalPages || DEFAULT_PAGE_SIZE,
            currentPage: currentPage,
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
        console.error("Error occurred while fetching report data:", error);
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "info",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  downloadReport = async (rowData) => {
    console.log("row data ", rowData);

    const id = rowData.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const url = global.config.reportservice.baseUrl + "/download/" + id;

    axios
      .get(url, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.toastSuccess(`Download in progress`);
          const link = response.data.data;
          const anchor = document.createElement("a");
          anchor.href = link;
          anchor.download = "";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          this.toastError(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
        if (error.response.status === 500) {
          this.toastError(error.response.data.error.errorMessage);
          console.error(
            "Error occurred while making this request:",
            error.response
          );
        } else {
          this.toastError("An unexpected error occured");
          console.error("Error :: ", error);
        }
      });
  };

  reProcessReport = async (rowData) => {
    console.log("row data ", rowData);

    const id = rowData.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const url = global.config.reportservice.baseUrl + "/process/" + id;

    axios
      .post(url, config)
      .then((response) => {
        console.log(
          "response from reprocessing the request :: ",
          response.data
        );
        if (response.status === 200 || response.status === 201) {
          this.toastSuccess("Download in progress");
          console.log(response.data);
          window.location.reload();
        } else {
          this.toastError(response.data.error.errorMessage);
          console.log(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          this.toastError(error.response.data.error.errorMessage);
          console.error(
            "Error occurred while making this request:",
            error.response
          );
        } else {
          this.toastError("An unexpected error occured");
          console.error("Error :: ", error);
        }
      });
  };

  statusColumnTemplate(rowData) {
    const statusMap = {
      INITIATED: { label: "INITIATED", className: "p-tag p-tag-pending" },
      QUEUED: { label: "QUEUED", className: "p-tag p-tag-warning" },
      FAILED: { label: "FAILED", className: "p-tag p-tag-danger" },
      PROCESSING: { label: "PROCESSING", className: "p-tag p-tag-pending" },
      READY: { label: "READY", className: "p-tag p-tag-success" },
    };

    const statusData = statusMap[rowData.status] || {
      label: "",
      className: "",
    };

    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={statusData.className}>{statusData.label}</span>
      </React.Fragment>
    );
  }

  formatedDate(rowData) {
    const date = new Date(rowData.createdOn);
    return date.toLocaleString();
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString();
  }
  openViewDialog = (rowData) => {
    const { filterDetails } = rowData;

    const parsedFilterDetails = JSON.parse(filterDetails);

    const { created_at_start, created_at_end, op_type } = parsedFilterDetails;

    this.setState({
      dialogModel: rowData.model,
      dialogUserId: rowData.platformUserId,
      dialogEmail: rowData.email,
      dialogDataStatus: rowData.status,
      dialogShortName: rowData.short_name,
      dialogId: rowData.id,
      requestStartDate: created_at_start ?? "N/A",
      requestEndDate: created_at_end ?? "N/A",
      requestOpType: op_type ?? "N/A",
      dialogCreatedOn: this.formatDate(rowData.createdOn),
      dialogUpdatedOn: this.formatDate(rowData.updatedOn),
      viewDetailDialog: true,
    });
  };

  actionColumnTemplate(rowData) {
    const isReady = rowData.status === "READY";
    const tooltipMessage = isReady ? "Download File" : "Download not ready";

    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          icon="pi pi-download"
          className="p-button p-button-text p-button-lightblue p-mr-2"
          onClick={() => this.downloadReport(rowData)}
          style={{
            fontSize: "15px",
            padding: "7px",
            backgroundColor: "lightblue",
            color: "white",
          }}
          disabled={!isReady}
          data-tip={tooltipMessage}
          data-for="toolTipDownload"
          data-place="top"
        />

        <Button
          icon="pi pi-refresh"
          className="p-button p-mr-2"
          onClick={() => this.reProcessReport(rowData)}
          style={{
            fontSize: "15px",
            padding: "7px",
            backgroundColor: "orange",
            borderColor: "orange",
          }}
          data-tip="Retry Export"
          data-for="toolTipRefresh"
          data-place="top"
        />

        <Button
          icon="pi pi-file"
          className="p-button p-button-text p-mr-2"
          onClick={() => this.openViewDialog(rowData)}
          style={{
            fontSize: "15px",
            padding: "7px",
            border: "1px solid lightBlue",
          }}
          data-tip="View More Details"
          data-for="toolTipViewMore"
          data-place="top"
        />
        <ReactTooltip id="toolTipDownload" />
        <ReactTooltip id="toolTipViewMore" />
        <ReactTooltip id="toolTipRefresh" />
      </React.Fragment>
    );
  }

  HideViewDetailDialog = () => {
    this.setState({ viewDetailDialog: false });
  };

  updateDimensions() {
    if (window.innerWidth < 500) {
      this.setState({
        mobileView: "block",
        webView: "none",
      });
    } else {
      this.setState({
        mobileView: "none",
        webView: "block",
      });
    }
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
      this.state.dialogVisible = false;
      this.filterDataByDate();
    }
  };

  openDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  changePage(event) {
    console.log(event);
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams });

    const selectedPage = event.page;
    this.setState({
      previousFunction: selectedPage,
    });

    this.filterByJobStatus(selectedPage);
  }

  render() {
    const filterInputBoxStyle = { borderRadius: "4px", width: "100%" };
    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };
    const cardStyle = { borderRadius: "40px" };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };

    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  <div>
                    <span className="p-tag custom-heading">Report</span>
                  </div>
                </div>
              </div>

              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <div style={{ width: "100%", display: "flex" }}>
                      <div className="p-field p-col-6 p-md-4 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            id="range"
                            value={this.state.rangeValue}
                            onChange={(e) =>
                              this.setState(
                                { rangeValue: e.value, dateChange: "Y" },
                                () => this.getFilteredDataDate()
                              )
                            }
                            selectionMode="range"
                            readOnlyInput
                            showIcon={true}
                          />
                          <label style={floatLabel}>DATE RANGE</label>
                        </span>
                      </div>
                      <div className=" p-md-4 p-xl-2">
                        <span className="p-float-label">
                          <Dropdown
                            style={filterInputBoxStyle}
                            id="dropdown1"
                            options={this.state.statusFilter}
                            value={this.state.selectedStatus}
                            onChange={(event) =>
                              this.setState(
                                { selectedStatus: event.value },
                                () => this.filterByJobStatus()
                              )
                            }
                            autoWidth={false}
                          />
                          <label style={floatLabel}>REPORT STATUS</label>
                        </span>
                      </div>
                    </div>

                    <div>
                      <Button
                        onClick={this.openDialog}
                        label="Generate Report"
                        icon="pi pi-file-o"
                        className="p-button-info"
                        style={{ width: "200px" }}
                      />
                      <Dialog
                        style={{ width: "900px" }}
                        visible={this.state.isDialogOpen}
                        header={
                          <div style={{ fontWeight: "lighter" }}>
                            Generate Report
                          </div>
                        }
                        modal
                        onHide={this.closeDialog}
                        breakpoint="md"
                      >
                        <ReportModal close={this.setState} />
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        totalRecords={this.state.totalRecords}
                        value={this.state.reportData}
                        paginator={true}
                        rows={DEFAULT_PAGE_SIZE}
                        ref={(el) => {
                          this.dt = el;
                        }}
                        lazy
                        first={this.state.lazyParams.first}
                        onPage={this.changePage}
                      >
                        {/* Table columns */}
                        <Column
                          field="createdOn"
                          header="Date / Time"
                          body={this.formatedDate}
                        />
                        <Column
                          field="model"
                          header="Type"
                          body={(rowData) => (
                            <span
                              style={{
                                fontWeight:
                                  rowData.model === "TRANSACTION" ||
                                  rowData.model === "TOP_UP_HISTORY"
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {rowData.model}
                            </span>
                          )}
                        />

                        <Column field="email" header="Email" />
                        <Column
                          field="status"
                          header="Status"
                          body={this.statusColumnTemplate}
                        />

                        <Column
                          header="Action"
                          body={this.actionColumnTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
                <Dialog
                  visible={this.state.viewDetailDialog}
                  style={{ width: "450px" }}
                  header="Request Details"
                  modal
                  onHide={this.HideViewDetailDialog}
                >
                  <span className={this.state.dialogStatus}>
                    {this.state.dialogStatusText}
                  </span>
                  <div className="p-grid p-fluid transaction">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                      <div style={cardStyle}>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>Job ID</label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.dialogId}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>User ID</label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.dialogUserId}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>Type</label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.dialogModel}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>Status</label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.dialogDataStatus}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>Email</label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.dialogEmail}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>
                              Report Filter Start Date
                            </label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.requestStartDate}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>
                              Report Filter End Date
                            </label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.requestEndDate}
                            </label>
                          </div>
                        </div>

                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>
                              Report Filter Operation Type
                            </label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.requestOpType}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>Created On</label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.dialogCreatedOn}
                            </label>
                          </div>
                        </div>
                        <div className="p-grid">
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleLeft}>Updated On</label>
                          </div>
                          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                            <label style={labelStyleRight}>
                              {this.state.dialogUpdatedOn}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(AdminReportV2);
