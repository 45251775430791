const initial_state = {
  userDetails: {},
  vaData: {},
};
const LoginReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "LOGIN_ACTION_KEY":
      return { ...state, userDetails: { ...action.payload } };
    case "VA_ACTION_KEY":
      return { ...state, vaData: { ...action.payload } };
    default:
      return state;
  }
};
export default LoginReducer;
