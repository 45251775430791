import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import { format } from "date-fns";
import "../../config";
const qs = require("query-string");

const checkHistory = "assets/layout/images/refresh.png";
const viewAll = "assets/layout/images/eye.png";
const topUp = "assets/layout/images/topup.png";
const deduct = "assets/layout/images/topdown.png";
const cardStyle = { borderRadius: "20px" };
const detailsStyle = {
  fontSize: "12px",
  paddingTop: "0.8em",
  color: "rgb(189,220,254)",
  float: "right",
};
const buttonDetailsStyle = { fontSize: "10px" };
const topupButtonStyle = {
  padding: "5px",
  background: "rgb(92, 74, 181)",
  border: "0",
  color: "#fff",
  paddingRight: "10px",
  float: "right",
};
const cardHeadingStyle = { color: "#5c4ab5", fontWeight: "600" };
const filterInputBoxStyle = { borderRadius: "4px" };
const labelStyle = {
  paddingLeft: "10px",
  fontSize: "12px",
  color: "#999",
  fontWeight: "500",
};
const amountBorder = {
  borderBottomLeftRadius: "10px",
  borderTopLeftRadius: "10px",
};

class Requests extends Component {
  constructor() {
    super();
    document.title = "Institution - Instant Credit";
    this.state = {
      data: [],
      items: [],
      topUpDialog: false,
      deducationDialog: false,
      institutionName: "",
      amount: "",
      vaId: "",
      purpose: "",
      accountName: "",
      topUpAmountError: "",
      topUpPurposeError: "",
      deductAmountError: "",
      deductPurposeError: "",
      deductAmount: "",
      deductPurpose: "",
      rangeValue: [],
      instCount: "",
      instReqCount: "",
      noOfInstUser: "",
      showLoader: "none",
      contentOpacity: "1",
    };

    let today = new Date();
    this.state.rangeValue[0] = today;
    this.state.rangeValue[1] = today;
  }

  componentDidMount() {
    this.getRequestCount();
  }

  getRequestCount = async () => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    console.log("GETTING DATA");
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const startDate = format(this.state.rangeValue[0], "yyyy-MM-dd");
    const endDate = format(this.state.rangeValue[1], "yyyy-MM-dd");

    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "request-count",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.setState({
            instCount: res.data.instCount,
            instReqCount: res.data.instReqCount,
            noOfInstUser: res.data.noOfInstUser,
          });
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          console.log(res.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">Institution</span>
              </div>
            </div>
            <div className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
              <div
                className="p-grid p-fluid virtualAccount card"
                style={cardStyle}
              >
                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>Institution List</span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        Total {this.state.instCount}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/institution-list"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>Institution Request</span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.instReqCount}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/institution-request"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-xl-3">
                  <div className="highlight-box">
                    <div className="highlight-details">
                      <span style={cardHeadingStyle}>
                        Institution User Request
                      </span>
                      <span className="count" style={{ paddingTop: "1.3em" }}>
                        New {this.state.noOfInstUser}
                      </span>
                      <div className="p-grid" style={{ marginTop: "1.3em" }}>
                        <div className="p-col-11 p-md-11 p-lg-11 p-xl-11">
                          <span
                            className="detail span-custom-success"
                            style={detailsStyle}
                          >
                            <img
                              src={viewAll}
                              style={{ height: "20px", width: "15px" }}
                            />
                            &nbsp;
                            <Link
                              to="/institution-user-request"
                              style={{ color: "rgb(189, 220, 254)" }}
                            >
                              View All
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    userId: state.login.vaData.userid,
  };
};

const mapDispatchToProps = {
  vaAction: VAAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
