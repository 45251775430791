import React, { Component } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Menu } from "primereact/menu";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import "../../config";
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");

const dialogHeaderTextStyle = { fontSize: "1.4rem", color: "#5c4ab5" };
class InstitutionUsers extends Component {
  constructor() {
    super();
    document.title = "Institution Users- Instant Credit";
    let today1 = new Date();
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      billingType: [
        { label: "Active", value: "1" },
        { label: "Passive", value: "0" },
      ],
      institutionUserType: [
        { label: "Admin", value: "0" },
        { label: "User", value: "1" },
      ],
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      phoneNumber: "",
      dropdownuserType: "",
      userType: [
        { label: "Admin User", value: "1" },
        { label: "Finance Admin", value: "101" },
        { label: "Finance User", value: "102" },
        { label: "Help Desk Admin", value: "201" },
        { label: "Help Desk User", value: "202" },
        { label: "Institution Admin", value: "301" },
        { label: "Institution Admin Admin", value: "302" },
        { label: "Institution User", value: "303" },
      ],
      accountType: [
        { label: "Bank", value: "0" },
        { label: "Wallet", value: "1" },
      ],
      userId: "",
      vaName: "",
      dropdownAccountType: "",
      vaThreshold: "",
      dropdownCurrency: "",

      editInstitutionName: "",
      dropdownEditBillingType: "",
      dropdownEditBillingTypeError: "",
      editBilling: "",
      editBillingError: "",
      dropdownEditStatus: "",
      dropdownEditStatusError: "",
      institutionName: "",
      userName: "",
      shortName: "",
      email: "",
      phone: "",
      dropdownBillingType: "",
      billing: "",
      dropdownUserType: "",
      dropdownCurrency: "",
      currencyError: "",
      rateError: "",
      addInstDialog: false,
      currency: "",
      rate: "",
      dateChange: "N",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      institutionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      reversalTransactionButton: "none",
      closeTransactionButton: "none",
      submitted: false,
      dropdownCity: "All",
      dropdownSearchFilter: "",
      filterCountry: "",
      filterDate: "",
      searchValueText: "",
      today: today1,
      status: [
        { label: "All", value: "All" },
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ],
      dropdownStatus: "All",
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      cities: [],
      dialogEditUserName: "",
      dialogEditUserNumber: "",
      dialogEditUserNameError: "",
      dialogEditUserNumberError: "",
      dialogEditUserId: "",
      showEdit: "",
      rangeValue: [],
      showLoader: "none",
      contentOpacity: "1",
      dialogEditusername: "",
      dialogEditusernameError: "",
      searchFilter: [
        { label: "Name", value: "name" },
        { label: "Username", value: "username" },
        { label: "Phone", value: "phone" },
      ],
      dropdownSearchFilter: "",
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.billingTypeColumnTemplate = this.billingTypeColumnTemplate.bind(this);
    this.viewInstitutionDetails = this.viewInstitutionDetails.bind(this);
    this.editInstitutionDetails = this.editInstitutionDetails.bind(this);
    this.hideInstitutionDetailDialog =
      this.hideInstitutionDetailDialog.bind(this);
    this.institutionColumnTemplate = this.institutionColumnTemplate.bind(this);
    this.usernameColumnTemplate = this.usernameColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.noOfCountriesColumnTemplate =
      this.noOfCountriesColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  navigate2VA = (id) => {
    let payload = { userid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/va");
      } else {
        alert("Failed");
      }
    });
  };
  navigate2InstitutionUsers = (id) => {
    let payload = { userid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/institution-users");
      } else {
        alert("Failed");
      }
    });
  };

  viewVirtualAccountDialog = (rowData) => {
    console.log(rowData);
    let billingType = "";
    if ("Active" == rowData.billing_type) {
      billingType = 1;
    } else {
      billingType = 0;
    }
    console.log(billingType);
    this.setState(
      {
        dropdownBillingType: billingType,
        userId: rowData.user_id,
        viewVirtualAccountDialog: true,
      },
      () => console.log(this.state.userId)
    );
  };
  handleAddAdminSubmit = async (event) => {
    event.preventDefault();
    let first_name = this.state.firstName;
    let last_name = this.state.lastName;
    let full_name = first_name + " " + last_name;
    this.setState({
      fullName: full_name,
    });
    if ("" == this.state.firstName) {
      this.setState({
        firstNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        firstNameError: "",
      });
    }
    if ("" == this.state.lastName) {
      this.setState({
        lastNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        lastNameError: "",
      });
    }
    if ("" == this.state.userName) {
      this.setState({
        userNameError: "1.5px solid red",
      });
      return false;
    } else {
      if (this.state.userName.indexOf(" ") >= 0) {
        this.toastError("Space is not allowed in Username");
        this.setState({
          userNameError: "1.5px solid red",
        });
        return false;
      } else {
        this.setState({
          userNameError: "",
        });
      }
    }
    if ("" == this.state.phoneNumber) {
      this.setState({
        phoneNumberError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        phoneNumberError: "",
      });
    }
    if ("" == this.state.email) {
      this.setState({
        emailError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        emailError: "",
      });
    }
    if ("" == this.state.dropdownuserType) {
      this.setState({
        dropdownError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      u_id: this.props.userId,
      va_id: this.props.vaId,
      token: this.props.userDetails._id,
      name: full_name,
      username: this.state.userName,
      phone: this.state.phoneNumber,
      email: this.state.email,
      usertype: this.state.dropdownuserType,
    };

    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(global.config.main.baseUrl + "add-user", qs.stringify(data), config)
      .then((res) => {
        if (res.data.status === 200) {
          this.state.dataTableValue = [];
          this.getInstitutionUsers();
          this.hideAddInstDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            firstName: "",
            lastName: "",
            fullName: "",
            userName: "",
            phoneNumber: "",
            email: "",
            dropdownuserType: "",
          });
        } else {
          this.hideAddInstDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  createVirtualAccount = (event) => {
    event.preventDefault();
    console.log(this.state.userId);
    if ("" == this.state.vaName) {
      this.setState({
        vaNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        vaNameError: "",
      });
    }
    if ("" == this.state.dropdownAccountType) {
      this.setState({
        accountTypeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        accountTypeError: "",
      });
    }
    if ("" == this.state.vaThreshold) {
      this.setState({
        vaThresholdError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        vaThresholdError: "",
      });
    }
    if ("" == this.state.dropdownCurrency) {
      this.setState({
        currencyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        currencyError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      userId: this.state.userId,
      account_name: this.state.vaName,
      account_type: this.state.dropdownAccountType,
      threshold_amount: this.state.vaThreshold,
      currency: this.state.dropdownCurrency,
      token: this.props.userDetails._id,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    axios
      .post(
        global.config.main.baseUrl + "create-va",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideVirtualAccountDialog();
          this.toastSuccess(res.data.msg);
        } else {
          this.hideVirtualAccountDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  hideVirtualAccountDialog = () => {
    this.state.viewVirtualAccountDialog = false;
    this.setState({
      vaName: "",
      dropdownAccountType: "",
      vaThreshold: "",
      dropdownCurrency: "",
    });
  };

  billingTypeColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Billing Type</span>
        <span>{rowData.billing_type}</span>
      </React.Fragment>
    );
  }
  institutionColumnTemplate(rowData) {
    let pTag = "p-tag p-tag-info";
    return (
      <React.Fragment>
        <span className="p-column-title">Institution</span>
        {/*<span className={pTag}>{rowData.name}</span>*/}
        <span>{rowData.name}</span>
      </React.Fragment>
    );
  }

  usernameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Username</span>
        {rowData.user_name}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        <span>{rowData.created_at}</span>
      </React.Fragment>
    );
  }

  noOfCountriesColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">No. of Countries</span>
        <span>{rowData.countries}</span>
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let pTag = "";
    let status = "";
    if ("Active" == rowData.status) {
      status = "Active";
      pTag = "p-tag p-tag-success";
    } else {
      status = "Inactive";
      pTag = "p-tag p-tag-danger";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-user-edit"
          className="p-button-primary p-mr-2"
          onClick={() => this.editInstitutionDetails(rowData)}
          style={{
            display: this.state.showEdit,
            fontSize: "15px",
            padding: "7px",
          }}
          data-tip="Edit Institution User"
          data-for="toolTip1"
          data-place="top"
        />
        &nbsp;
        {/*<Link to='/va' onClick={() => this.navigate2VA(rowData['user_id'])} >
						<Button label="" icon="pi pi-eye" className="p-button-success p-mr-2" onClick={() => this.viewInstitutionDetails(rowData)} style={{ fontSize: '15px', padding: '7px' }} data-tip="View Virtual Account" data-for='toolTip1' data-place='top' />
					</Link>*/}
        <Button
          label=""
          icon="pi pi-eye"
          className="p-button-success p-mr-2"
          onClick={() => this.viewInstitutionDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="View Institution Users Details"
          data-for="toolTip1"
          data-place="top"
        />
        {/*<div className="p-grid p-fluid">
					<div className="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                		<Button label="" icon="pi pi-eye" className="p-button-success p-mr-2" onClick={() => this.viewInstitutionDetails(rowData)} style={{ fontSize: '15px', padding: '7px' }} data-tip="View Institution Users Details" data-for='toolTip1' data-place='top'/>
					</div>
					<div className="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                		<Link to='/va' onClick={() => this.navigate2VA(rowData['user_id'])} >
							<Button label="" icon="pi pi-eye" className="p-button-warning p-mr-2" onClick={() => this.viewInstitutionDetails(rowData)} style={{ fontSize: '15px', padding: '7px' }} data-tip="View Virtual Account" data-for='toolTip1' data-place='top' />
						</Link>
					</div>
				</div>
				<div className="p-grid p-fluid">
					<div className="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                		<Button label="" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={() => this.viewVirtualAccountDialog(rowData)} style={{ fontSize: '15px', padding: '7px' }} data-tip="Add Virtual Account" data-for='toolTip1' data-place='top' />
					</div>
					<div className="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                		<Button label="" icon="pi pi-user-edit" className="p-button-primary p-mr-2" onClick={() => this.editInstitutionDetails(rowData)} style={{ fontSize: '15px', padding: '7px' }} data-tip="Edit Institution" data-for='toolTip1' data-place='top' />
					</div>
				</div>*/}
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }

  viewInstitutionDetails(rowData) {
    console.log(rowData);
    if ("Inactive" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "Inactive",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
      });
    }
    if ("Passive" == rowData.billing_type) {
      this.setState({
        dialogBillingType: "Passive",
      });
    } else {
      this.setState({
        dialogBillingType: "Active",
      });
    }
    this.setState({
      userId: rowData.user_id,
      dialogInstitutionName: rowData.name,
      dialogInstitutionShortName: rowData.short_name,
      dialogEmail: rowData.email,
      dialogPhone: rowData.phone,
      dialogJoinedOn: rowData.created_at,
      dialogBilling: rowData.billing,
      institutionDialog: true,
    });
  }

  hideInstitutionDetailDialog() {
    this.setState({ institutionDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };
  editInstitutionDetails(rowData) {
    console.log(rowData);
    let billingType = "";
    if ("Active" == rowData.billing_type) {
      billingType = "1";
    } else {
      billingType = "0";
    }
    if ("Inactive" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "Inactive",
        dropdownEditStatus: "0",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "Active",
        dropdownEditStatus: "1",
      });
    }
    this.setState({
      dialogEditUserName: rowData.name,
      dialogEditUserNumber: rowData.phone,
      dialogEditUserId: rowData.user_id,
      dialogEditusername: rowData.user_name,
      editInstitutionDialog: true,
    });
  }

  editInstitutionUser = (event) => {
    event.preventDefault();
    console.log(this.state.dialogEditUserName);
    console.log(this.state.dialogEditUserNumber);
    console.log(this.state.dialogEditUserId);
    console.log(this.state.dropdownEditStatus);
    if ("" == this.state.dialogEditUserName) {
      this.setState({
        dialogEditUserNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogEditUserNameError: "",
      });
    }
    if ("" == this.state.dialogEditusername) {
      this.setState({
        dialogEditusernameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogEditusernameError: "",
      });
    }
    if ("" == this.state.dialogEditUserNumber) {
      this.setState({
        dialogEditUserNumberError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dialogEditUserNumberError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      entry_id: this.state.dialogEditUserId,
      name: this.state.dialogEditUserName,
      phone: this.state.dialogEditUserNumber,
      status: this.state.dropdownEditStatus,
      token: this.props.userDetails._id,
      username: this.state.dialogEditusername,
    };
    axios
      .post(
        global.config.main.baseUrl + "edit-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideEditInstDialog();
          this.toastSuccess(res.data.msg);
          this.state.dataTableValue = [];
          this.getInstitutionUsers();
        } else {
          this.hideEditInstDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  componentDidMount() {
    if ("0" == this.props.userDetails.type) {
      this.setState({
        showEdit: "inherit",
      });
    } else {
      this.setState({
        showEdit: "none",
      });
    }
    console.log("PROPS");
    console.log(this.props.userId);
    this.setState({
      currency: this.props.userDetails.currency,
    });
    this.updateDimensions();
    this.getInstitutionUsers();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      this.filterDataByDate();
    }
  };

  filterDataByDate = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-all-inst-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
          console.log(res.data);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getSearchedData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      u_id: this.props.userId,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-all-inst-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DATA");
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  openAddForexDialog = () => {
    this.state.addInstDialog = true;
  };
  hideAddInstDialog = () => {
    this.state.addInstDialog = false;
  };
  hideEditInstDialog = () => {
    this.state.editInstitutionDialog = false;
  };
  editInstitution = (event) => {
    event.preventDefault();
    if ("" == this.state.dropdownEditBillingType) {
      this.setState({
        dropdownEditBillingTypeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownEditBillingTypeError: "",
      });
    }
    if ("" == this.state.editBilling) {
      this.setState({
        editBillingError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        editBillingError: "",
      });
    }
    if ("" == this.state.dropdownEditStatus) {
      this.setState({
        dropdownEditStatusError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownEditStatusError: "",
      });
    }
    console.log(this.state.userId);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      entry_id: this.state.userId,
      billing_type: this.state.dropdownEditBillingType,
      billing: this.state.editBilling,
      status: this.state.dropdownEditStatus,
    };
    axios
      .post(
        global.config.main.baseUrl + "edit-users-details",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getInstitutionUsers();
          this.hideEditInstDialog();
          this.toastSuccess(res.data.msg);
        } else {
          this.hideEditInstDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  addInstitution = (event) => {
    event.preventDefault();
    console.log(this.props.userId);
    if ("" == this.state.institutionName) {
      this.setState({
        institutionNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        institutionNameError: "",
      });
    }
    {
      /*if ('' == this.state.userName) {
            this.setState({
                userNameError: "1.5px solid red",
            });
            return false;
        }
        else {
            this.setState({
                userNameError: "",
            });
        }*/
    }
    if ("" == this.state.shortName) {
      this.setState({
        shortNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        shortNameError: "",
      });
    }
    if ("" == this.state.email) {
      this.setState({
        emailError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        emailError: "",
      });
    }
    if ("" == this.state.phone) {
      this.setState({
        phoneError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        phoneError: "",
      });
    }
    if ("" == this.state.dropdownBillingType) {
      this.setState({
        dropdownBillingTypeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        dropdownBillingTypeError: "",
      });
    }
    if ("" == this.state.billing) {
      this.setState({
        billingError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        billingError: "",
      });
    }
    {
      /*if ('' == this.state.dropdownUserType) {
            this.setState({
                dropdownUserTypeError: "1.5px solid red",
            });
            return false;
        }
        else {
            this.setState({
                dropdownUserTypeError: "",
            });
        }*/
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
      u_id: this.props.userId,
      email: this.state.email,
      phone: this.state.phone,
      name: this.state.institutionName,
      user_name: this.state.shortName,
      short_name: this.state.shortName,
      user_type: "301", //this.state.dropdownUserType,
      billing_type: this.state.dropdownBillingType,
      billing: this.state.billing,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "register-users",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getInstitutionUsers();
          this.hideAddInstDialog();
          this.toastSuccess(res.data.msg);
          this.setState({
            email: "",
            institutionName: "",
            userName: "",
            shortName: "",
            dropdownUserType: "",
            dropdownBillingType: "",
            billing: "",
          });
        } else {
          this.hideAddInstDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  GetFilteredStatus = async () => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      status: this.state.dropdownStatus,
      u_id: this.props.userId,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-all-inst-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DATA");
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };
  getInstitutionUsers = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      //start_date: startDate,
      //end_date: endDate,
      u_id: this.props.userId,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-all-inst-user",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DATA");
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    console.log(data);
    let user_status = "";
    let user_billing_type = "";
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      if ("0" == d["status"]) {
        user_status = "Inactive";
      } else {
        user_status = "Active";
      }
      if ("0" == d["billing_type"]) {
        user_billing_type = "Passive";
      } else {
        user_billing_type = "Active";
      }

      this.state.dataTableValue.push({
        created_at: d["created_at"],
        email: d["email"],
        name: d["name"],
        phone: d["phone"],
        short_name: d["short_name"],
        countries: d["no_va"],
        status: user_status,
        user_id: d["user_id"],
        billing_type: user_billing_type,
        billing: d["billing"],
        user_name: d["user_name"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  render() {
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const institutionDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideInstitutionDetailDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.retryTransactionButton,
          }}
        />
        <Button
          label="Reverse Transaction"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideInstitutionDetailDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.reversalTransactionButton,
          }}
        />
        <Button
          label="Close"
          className="p-button-success"
          onClick={this.hideInstitutionDetailDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.closeTransactionButton,
          }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const addForex = "assets/layout/images/forex_add.png";
    const detailsStyle = {
      fontSize: "14px",
      color: "rgb(92, 74, 181)",
      float: "right",
    };
    const checkHistory = "assets/layout/images/refresh.png";
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };
    const vaNameborder = { border: this.state.vaNameError };
    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };
    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  {/*<div className="card card-w-title">*/}
                  <div>
                    <span className="p-tag custom-heading">
                      Institution Users
                    </span>
                  </div>
                </div>
              </div>

              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      {/*<span className="detail span-custom-success" style={detailsStyle}>
                                                Add Institution&nbsp;
                                                <img src={addForex} style={{ height: '25px', width: '25px' }} onClick={() => this.openAddForexDialog()} />
                                            </span>*/}
                      <Button
                        icon="pi pi-download"
                        onClick={this.exportCSV}
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          color: "#999",
                          float: "right",
                        }}
                        data-tip="Download Institution List"
                        data-for="toolTip1"
                        data-place="top"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <span
                        className="detail span-custom-success"
                        style={detailsStyle}
                      >
                        <Button
                          icon="pi pi-plus"
                          onClick={() => this.openAddForexDialog()}
                          className="p-button-info"
                          style={{ float: "right" }}
                          data-tip="Add Institution User"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        User &nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                  </div>
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "24px",
                      display: this.state.showFiltersWeb,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-3 p-xl-3">
                      <span className="p-float-label">
                        <Calendar
                          id="range"
                          value={this.state.rangeValue}
                          onChange={(e) =>
                            this.setState(
                              { rangeValue: e.value, dateChange: "Y" },
                              () => this.getFilteredDataDate()
                            )
                          }
                          selectionMode="range"
                          readOnlyInput
                          showIcon={true}
                        />
                        <label htmlFor="calendar">DATE RANGE</label>
                      </span>
                    </div>

                    <div className="p-field p-col-6 p-md-3 p-xl-3">
                      <Dropdown
                        style={filterInputBoxStyle}
                        id="dropdown1"
                        options={this.state.status}
                        value={this.state.dropdownStatus}
                        onChange={(event) =>
                          this.setState(
                            { dropdownStatus: event.value, statusChanged: "Y" },
                            () => this.GetFilteredStatus()
                          )
                        }
                        autoWidth={false}
                      />
                    </div>
                    <div className="p-col-12 p-md-4 p-xl-4">
                      <div className="p-grid">
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingRight: "2px" }}
                        >
                          <div className="p-inputgroup">
                            <span className="p-float-label">
                              <Dropdown
                                style={{ width: "20%" }}
                                options={this.state.searchFilter}
                                value={this.state.dropdownSearchFilter}
                                onChange={(event) =>
                                  this.setState({
                                    dropdownSearchFilter: event.value,
                                  })
                                }
                                autoWidth={false}
                              />
                              <label style={floatLabel}>Select Filter</label>
                            </span>
                          </div>
                        </div>
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingLeft: "0.4px" }}
                        >
                          <div className="p-inputgroup">
                            <InputText
                              value={this.state.searchValue}
                              onChange={(event) =>
                                this.setState({
                                  searchValue: event.target.value,
                                })
                              }
                              placeholder="Search Value"
                            />
                            <Button
                              onClick={() => this.getSearchedData()}
                              icon="pi pi-search"
                              className="p-button-info"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-col-4 p-md-2 p-xl-2">
                      <Button
                        icon="pi pi-download"
                        onClick={this.exportCSV}
                        className="p-button-info"
                        style={{ backgroundColor: "#f0f0f0", color: "#999" }}
                        data-tip="Download Institution List"
                        data-for="toolTip1"
                        data-place="top"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <span
                        className="detail span-custom-success"
                        style={detailsStyle}
                      >
                        <Button
                          icon="pi pi-plus"
                          onClick={() => this.openAddForexDialog()}
                          className="p-button-info"
                          style={{ float: "left" }}
                          data-tip="Add Institution User"
                          data-for="toolTip1"
                          data-place="top"
                        />
                        &nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        value={this.state.dataTableValue}
                        paginator={true}
                        rows={10}
                        ref={(el) => {
                          this.dt = el;
                        }}
                      >
                        <Column field="billing" style={{ display: "none" }} />
                        <Column
                          field="countries"
                          header="No. of Countries"
                          body={this.noOfCountriesColumnTemplate}
                          style={{ display: "none" }}
                        />
                        <Column
                          field="billing_type"
                          header="Billing type"
                          body={this.billingTypeColumnTemplate}
                          style={{ display: "none" }}
                        />
                        <Column
                          field="name"
                          header="Name"
                          body={this.institutionColumnTemplate}
                        />
                        <Column
                          field="user_name"
                          header="Username"
                          body={this.usernameColumnTemplate}
                        />
                        <Column
                          field="status"
                          header="Status"
                          body={this.statusColumnTemplate}
                        />
                        <Column
                          field="created_at"
                          header="Date"
                          body={this.dateColumnTemplate}
                        />
                        <Column
                          header="Action"
                          body={this.actionColumnTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.getFilteredDataDate()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                />
                <label htmlFor="calendar">DATE RANGE</label>
              </span>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.addInstDialog}
          style={{ width: "600px", overflowY: "scroll" }}
          header=""
          modal
          onHide={this.hideAddInstDialog}
        >
          <form onSubmit={this.handleAddAdminSubmit} id="addAdminForm">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2"
                style={{ textAlign: "center" }}
              >
                <label style={dialogHeaderTextStyle}>
                  Add Institution User
                </label>
              </div>
            </div>
            <div className="p-grid p-fluid profile">
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
                <span className="p-float-label">
                  <InputText
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    style={{ border: this.state.firstNameError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    First Name
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
                <span className="p-float-label">
                  <InputText
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    style={{ border: this.state.lastNameError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Last Name
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
                <span className="p-float-label">
                  <InputText
                    value={this.state.userName}
                    onChange={(e) =>
                      this.setState({ userName: e.target.value })
                    }
                    style={{ border: this.state.userNameError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Username
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
                <span className="p-float-label">
                  <InputText
                    type="number"
                    value={this.state.phoneNumber}
                    onChange={(e) =>
                      this.setState({ phoneNumber: e.target.value })
                    }
                    style={{ border: this.state.phoneNumberError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Phone Number
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
                <span className="p-float-label">
                  <InputText
                    type="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    style={{ border: this.state.emailError }}
                  />
                  <label htmlFor="inputtext" style={labelStyle}>
                    Email
                  </label>
                </span>
              </div>
              <div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
                <span className="p-float-label">
                  <Dropdown
                    style={filterInputBoxStyle}
                    id="dropdown1"
                    options={this.state.institutionUserType}
                    value={this.state.dropdownuserType}
                    onChange={(event) =>
                      this.setState({ dropdownuserType: event.value })
                    }
                    autoWidth={false}
                    style={{ border: this.state.dropdownError }}
                  />
                  <label htmlFor="dropdown1" style={labelStyle}>
                    Select User Type
                  </label>
                </span>
              </div>
            </div>
            <div className="p-grid p-fluid" style={{ paddingBottom: "3em" }}>
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                <Button
                  type="submit"
                  label="Submit"
                  style={{ height: "36px" }}
                  className="p-button-info"
                  style={{ marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.addInstDialoggg}
          style={{ width: "450px", overflowY: "scroll" }}
          header="Add Institution User"
          modal
          onHide={this.hideAddInstDialog}
        >
          <form onSubmit={this.addInstitution}>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        onChange={(e) =>
                          this.setState({ institutionName: e.target.value })
                        }
                        value={this.state.institutionName}
                        style={{ border: this.state.institutionNameError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Enter Institution User Name
                      </label>
                    </span>
                  </div>
                  {/*<div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1" style={{ marginTop: '20px' }}>
                                        <span className="p-float-label">
                                            <InputText onChange={(e) => this.setState({ userName: e.target.value })} value={this.state.userName} style={{ border: this.state.userNameError }} />
                                            <label htmlFor="dropdown1" style={labelStyle}>User Name</label>
                                        </span>
                                    </div>*/}
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        onChange={(e) =>
                          this.setState({ shortName: e.target.value })
                        }
                        value={this.state.shortName}
                        style={{ border: this.state.shortNameError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Short Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        value={this.state.email}
                        style={{ border: this.state.emailError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Email
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        type="number"
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        value={this.state.phone}
                        style={{ border: this.state.phoneError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Phone
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        options={this.state.billingType}
                        value={this.state.dropdownBillingType}
                        onChange={(event) =>
                          this.setState({
                            dropdownBillingType: event.target.value,
                          })
                        }
                        autoWidth={false}
                        style={{ border: this.state.dropdownBillingTypeError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Billing Type
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        onChange={(e) =>
                          this.setState({ billing: e.target.value })
                        }
                        value={this.state.billing}
                        style={{ border: this.state.billingError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Billing
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px", display: "none" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        options={this.state.userType}
                        value={this.state.dropdownUserType}
                        onChange={(event) =>
                          this.setState({
                            dropdownUserType: event.target.value,
                          })
                        }
                        autoWidth={false}
                        style={{ border: this.state.dropdownUserTypeError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        User Type
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Add"
                  className="p-button-info"
                  type="submit"
                  style={{ width: "50%", marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.institutionDialog}
          style={{ width: "450px", overflowY: "scroll" }}
          header="Institution Users Detail"
          modal
          onHide={this.hideInstitutionDetailDialog}
        >
          <span className={this.state.dialogStatus}>
            {this.state.dialogStatusText}
          </span>
          <div className="p-grid p-fluid transaction">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <div style={cardStyle}>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Institution User Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogInstitutionName}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Institution Short Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogInstitutionShortName}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Email</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogEmail}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Phone</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogPhone}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Joined On</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogJoinedOn}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.editInstitutionDialog}
          style={{ width: "450px", overflowY: "scroll" }}
          header="Edit Institution User"
          modal
          onHide={this.hideEditInstDialog}
        >
          <form onSubmit={this.editInstitutionUser}>
            <span className={this.state.dialogStatus}>
              {this.state.dialogStatusText}
            </span>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "10px" }}
                  >
                    <Dropdown
                      style={filterInputBoxStyle}
                      id="dropdown1"
                      options={this.state.status}
                      value={this.state.dropdownEditStatus}
                      onChange={(event) =>
                        this.setState({ dropdownEditStatus: event.value })
                      }
                      autoWidth={false}
                    />
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        onChange={(event) =>
                          this.setState({
                            dialogEditUserName: event.target.value,
                          })
                        }
                        value={this.state.dialogEditUserName}
                        style={{ border: this.state.dialogEditUserNameError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        onChange={(event) =>
                          this.setState({
                            dialogEditusername: event.target.value,
                          })
                        }
                        value={this.state.dialogEditusername}
                        style={{ border: this.state.dialogEditusernameError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Username
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        name="dialogPhoneNumber"
                        type="number"
                        onChange={(event) =>
                          this.setState({
                            dialogEditUserNumber: event.target.value,
                          })
                        }
                        value={this.state.dialogEditUserNumber}
                        style={{ border: this.state.dialogEditUserNumberError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Phone Number
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-7 p-lg-offset-7 p-xl-offset-7">
                <Button
                  label="Update"
                  icon="pi pi-pencil"
                  className="p-button-info"
                  type="submit"
                  style={{ marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.viewVirtualAccountDialog}
          style={{ width: "450px" }}
          header="Create Virtual Account"
          modal
          onHide={this.hideVirtualAccountDialog}
        >
          <form onSubmit={this.createVirtualAccount}>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        name="dialogName"
                        onChange={(event) =>
                          this.setState({ vaName: event.target.value })
                        }
                        value={this.state.vaName}
                        style={vaNameborder}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Virtual Account Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "10px" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        style={filterInputBoxStyle}
                        id="dropdown1"
                        options={this.state.accountType}
                        value={this.state.dropdownAccountType}
                        onChange={(event) =>
                          this.setState({
                            dropdownAccountType: event.target.value,
                          })
                        }
                        autoWidth={false}
                        style={{ border: this.state.accountTypeError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Select Account Type
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        type="number"
                        name="dialogName"
                        onChange={(event) =>
                          this.setState({ vaThreshold: event.target.value })
                        }
                        value={this.state.vaThreshold}
                        style={{ border: this.state.vaThresholdError }}
                        min="1"
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Threshold Amount
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-5 p-lg-5 p-xl-5"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        style={filterInputBoxStyle}
                        id="dropdown1"
                        options={this.state.currency}
                        value={this.state.dropdownCurrency}
                        onChange={(event) =>
                          this.setState({
                            dropdownCurrency: event.target.value,
                          })
                        }
                        autoWidth={false}
                        style={{ border: this.state.currencyError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Select Currency
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-7 p-lg-offset-7 p-xl-offset-7">
                <Button
                  label="Confirm"
                  className="p-button-info"
                  type="submit"
                  style={{ marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    userId: state.login.vaData.userid,
  };
};
const mapDispatchToProps = {
  vaAction: VAAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(InstitutionUsers);
