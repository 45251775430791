import React, { Component } from "react";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { addDays, differenceInDays } from "date-fns";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import "../../config";
const qs = require("query-string");

class ReportModal extends Component {
  constructor() {
    super();
    document.title = "Reports - Instant Credit";
    this.state = {
      dateError: "",
      start_date: "",
      end_date: "",
      dropdownCountry: "",
      dropdownInstitutionSelected: "",
      allInstitution: [],
      cities: [],
      dropDownCountryError: "",
      dropdownInstitutionSelectedError: "",
      email: "",
      selectedStatus: "",
      selectedFilterTag: "",
      showLoader: "none",
      contentOpacity: "1",
      filterTags: [
        { label: "Virtual Account", value: "TOP_UP_HISTORY" },
        { label: "Transaction", value: "TRANSACTION" },
      ],
      topUpStatusOptions: [
        { label: "All", value: "NA" },
        { label: "Top-up", value: "TOP_UP" },
        { label: "Adjustment", value: "ADJUSTMENT" },
        { label: "Transfer", value: "TRANSFER" },
        { label: "Reversal", value: "REVERSAL" },
      ],
      reportStatusOptions: [
        { label: "All", value: "NA" },
        { label: "Pending", value: "0" },
        { label: "Success", value: "1" },
        { label: "Failed", value: "2" },
        { label: "Processing", value: "3" },
        { label: "Low_Bal", value: "4" },
        { label: "Requeued", value: "5" },
        { label: "Cancelled", value: "6" },
      ],
    };
  }

  componentDidMount() {
    this.getInstitutionList();
    this.setState({
      cities: this.props.userDetails.allCountries,
    });
  }

  getInstitutionList = () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "report-helper",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            allInstitution: res.data.inst_list,
          });
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  handleStartDateChange = (e) => {
    const startDate = e.value;
    const endDate = this.state.end_date;

    if (!endDate || differenceInDays(endDate, startDate) <= 30) {
      this.setState({ start_date: startDate });
    } else {
      this.setState({
        start_date: startDate,
        end_date: addDays(startDate, 30),
      });
    }
  };

  handleEndDateChange = (e) => {
    const endDate = e.value;
    const startDate = this.state.start_date;

    if (!startDate || differenceInDays(endDate, startDate) <= 30) {
      this.setState({ end_date: endDate });
    } else {
      this.setState({ end_date: endDate, start_date: addDays(endDate, -30) });
    }
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "info",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }

  handleReportExportSubmit = async (event) => {
    event.preventDefault();

    const {
      start_date,
      end_date,
      dropdownCountry,
      dropdownInstitutionSelected,
      selectedStatus,
      selectedFilterTag,
    } = this.state;

    if (!start_date || !end_date) {
      this.setState({
        dateError: "1.5px solid red",
      });
      return;
    }

    if (selectedFilterTag === "TRANSACTION") {
      // if (!dropdownInstitutionSelected) {
      //   this.setState({
      //     dropdownInstitutionSelectedError: "1.5px solid red",
      //   });
      //   return;
      // }
      // if (!dropdownCountry) {
      //   this.setState({
      //     dropDownCountryError: "1.5px solid red",
      //   });
      //   return;
      // }
    }

    this.submitReport();
  };

  removeEmptyValues = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    });

    return newObj;
  };

  submitReport = () => {
    const {
      start_date,
      end_date,
      dropdownCountry,
      dropdownInstitutionSelected,
      selectedStatus,
      selectedFilterTag,
    } = this.state;

    const token = this.props.userDetails._id;

    const decodedToken = jwtDecode(token);
    console.log("decoded token :: ", jwtDecode);

    const { user_id, email } = decodedToken;

    const filterDetails = {
      created_at_start: dateFormat(start_date, "yyyy-mm-dd"),
      created_at_end: dateFormat(end_date, "yyyy-mm-dd"),
    };

    const requestData = {
      platformUserId: user_id,
      institutionId: user_id,
      model: selectedFilterTag,
      email: email,
      filterDetails: "",
    };

    if (selectedFilterTag === "TOP_UP_HISTORY") {
      if (selectedStatus != "NA") {
        filterDetails.op_type = selectedStatus;
      }
      if (dropdownInstitutionSelected != "All") {
        filterDetails.institution_id = dropdownInstitutionSelected;
      }
    } else if (selectedFilterTag === "TRANSACTION") {
      if (selectedStatus != "NA") {
        filterDetails.status = selectedStatus;
      }
      if (dropdownCountry != "All") {
        filterDetails.recipient_country = dropdownCountry;
      }
      if (dropdownInstitutionSelected != "All") {
        filterDetails.user_id = dropdownInstitutionSelected;
      }
    }

    const finalFilteredDetails = this.removeEmptyValues(filterDetails);

    requestData.filterDetails = JSON.stringify(finalFilteredDetails);

    this.setState({
      contentOpacity: 1,
      showLoader: "block",
    });
    axios
      .post(global.config.reportservice.baseUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.toastSuccess(`Download request submitted successfully`);

          setTimeout(() => {
            window.location.reload();
          }, "3000");
        } else {
          this.toastError(response.data.error.errorMessage);
          console.log(response.data);

          setTimeout(() => {
            window.location.reload();
          }, "3000");
        }
      })
      .catch((error) => {
        console.error("Error response :: ", error);
        if (error.response.status === 500) {
          this.toastError(error.response.data.error.errorMessage);
          console.error(
            "Error occurred while making this request:",
            error.response
          );
          setTimeout(() => {
            window.location.reload();
          }, "3000");
        } else {
          this.toastError("An unexpected error occured");
          console.error("Error :: ", error);
          setTimeout(() => {
            window.location.reload();
          }, "3000");
        }
      });
  };

  render() {
    const { cardStyle, submitButtonStyle, pgridCustomStyle, labelStyle } =
      this.styles;
    const {
      dateError,
      dropDownCountryError,
      dropdownInstitutionSelectedError,
      selectedFilterTag,
      topUpStatusOptions,
      reportStatusOptions,
      cities,
      allInstitution,
    } = this.state;

    const statusOptions =
      selectedFilterTag === "TOP_UP_HISTORY"
        ? topUpStatusOptions
        : reportStatusOptions;
    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <div className="p-grid p-fluid" style={{ marginTop: "1px" }}>
          <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
          <ProgressSpinner
            style={{
              display: this.state.showLoader,
              width: "40px",
              height: "40px",
              position: "fixed",
              top: "50%",
              left: "50%",
              bottom: "50%",
              right: "50%",
              zIndex: "1111111111",
            }}
            strokeWidth="3"
            animationDuration=".5s"
          />
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid report" style={pgridCustomStyle}>
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
                <form onSubmit={this.handleReportExportSubmit}>
                  <div className="card card-w-title" style={cardStyle}>
                    <div className="p-grid">
                      {/* FilterTag Dropdown */}
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Dropdown
                            options={this.state.filterTags}
                            value={this.state.selectedFilterTag}
                            onChange={(event) =>
                              this.setState({
                                selectedFilterTag: event.value,
                              })
                            }
                            autoWidth={false}
                          />
                          <label htmlFor="dropdownFilter" style={labelStyle}>
                            Report Type
                          </label>
                        </span>
                      </div>

                      {/* Start Date */}

                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Calendar
                            id="calendar"
                            value={this.state.start_date}
                            onChange={(e) =>
                              this.setState({ start_date: e.value })
                            }
                            showIcon={true}
                          />
                          <label htmlFor="calendar" style={labelStyle}>
                            Start Date
                          </label>
                        </span>
                      </div>

                      {/* End Date */}
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Calendar
                            id="calendar"
                            value={this.state.end_date}
                            onChange={(e) =>
                              this.setState({ end_date: e.value })
                            }
                            showIcon={true}
                          />
                          <label htmlFor="calendar" style={labelStyle}>
                            End Date
                          </label>
                        </span>
                      </div>
                      {selectedFilterTag === "TRANSACTION" && (
                        <div
                          className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                          style={{ marginTop: "10px" }}
                        >
                          <span className="p-float-label">
                            <Dropdown
                              options={cities}
                              value={this.state.dropdownCountry}
                              onChange={(event) =>
                                this.setState({ dropdownCountry: event.value })
                              }
                              autoWidth={false}
                              style={{
                                border: dropDownCountryError,
                              }}
                            />
                            <label htmlFor="dropdownCountry" style={labelStyle}>
                              Country
                            </label>
                          </span>
                        </div>
                      )}
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Dropdown
                            options={allInstitution}
                            value={this.state.dropdownInstitutionSelected}
                            onChange={(event) =>
                              this.setState({
                                dropdownInstitutionSelected: event.value,
                              })
                            }
                            autoWidth={false}
                            style={{
                              border: dropdownInstitutionSelectedError,
                            }}
                          />
                          <label
                            htmlFor="dropdownInstitution"
                            style={labelStyle}
                          >
                            Institution
                          </label>
                        </span>
                      </div>
                      {/* Status Dropdown */}
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="p-float-label">
                          <Dropdown
                            options={statusOptions}
                            value={this.state.selectedStatus}
                            onChange={(event) =>
                              this.setState({ selectedStatus: event.value })
                            }
                            autoWidth={false}
                            style={{
                              border: this.state.dropDownStatusError,
                            }}
                          />
                          <label htmlFor="dropdownStatus" style={labelStyle}>
                            Status
                          </label>
                        </span>
                      </div>
                      {/* Export Button */}
                      <div
                        className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-6 p-xl-offset-4"
                        style={{ marginTop: "10px" }}
                      >
                        <Button
                          type="submit"
                          label="Export"
                          style={submitButtonStyle}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  styles = {
    cardStyle: { borderRadius: "20px" },
    inputBoxStyle: {
      borderRadius: "10px",
      color: "rgb(180,180,180)",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    },
    submitButtonStyle: {
      backgroundColor: "rgb(84,67,163)",
      marginBottom: "10px",
      fontWeight: "600",
      fontFamily: "unset",
      fontSize: "16px",
      borderRadius: "10px",
      marginTop: "1em",
      height: "40px",
      width: "230px",
    },
    pgridCustomStyle: { marginRight: "0", marginLeft: "0" },
    h1Style: {
      textAlign: "center",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
      textDecoration: "underline",
    },
    h3Style: {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    },
    labelStyle: {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    },
  };
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(ReportModal);
