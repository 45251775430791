import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import "../../config";
import { Button } from "primereact/button";
import axios from "axios";
import { Message } from "primereact/message";
//import Cookies from 'universal-cookie';
import { LoginAction } from "../../store/actions/LoginAction";
import { connect } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
//const cookies = new Cookies();
const qs = require("query-string");

class ForgotPassword extends Component {
  constructor() {
    super();
    document.title = "Forgot Password - Instant Credit";
    this.state = {
      username: "",
      msg: "",
      severityDisplay: "none",
      usernameError: "",
      contentOpacity: 1,
      showLoader: "none",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    document.body.style.backgroundColor = "rgb(247,247,247)";
  }

  handleForgotPassword = async (event) => {
    event.preventDefault();
    if ("" == this.state.username) {
      this.setState({
        usernameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        usernameError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      username: this.state.username,
    };
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });

    axios
      .post(
        global.config.main.baseUrl + "forgot-password",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          //console.log(res.data);
          this.setState({
            severityDisplay: "block",
            severity: "success",
            msg: res.data.msg,
          });
        } else {
          //console.log(res);
          this.setState({
            severityDisplay: "block",
            severity: "error",
            msg: res.data.msg,
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({
          contentOpacity: 1,
          showLoader: "none",
        });
      });
  };

  render() {
    const cardStyle = {
      borderRadius: "40px",
      padding: "4em",
      margin: "15em",
      width: "800px",
    };
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const submitButtonStyle = {
      backgroundColor: "rgb(84,67,163)",
      marginBottom: "10px",
      fontWeight: "600",
      fontFamily: "unset",
      fontSize: "16px",
      borderRadius: "10px",
      marginTop: "1em",
      height: "40px",
    };
    const pgridCustomStyle = {
      marginRight: "0",
      marginLeft: "0",
      marginTop: "0",
    };
    const logo = "assets/layout/images/instantCreditLogo.png";
    const dialogHeaderTextStyle = { fontSize: "1.4rem" };
    const labelStyle = { fontSize: "14px" };
    return (
      <div style={{ opacity: this.state.contentOpacity }}>
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <form onSubmit={this.handleForgotPassword}>
          <div className="p-grid p-fluid" style={pgridCustomStyle}>
            <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-8 p-md-offset-1 p-lg-offset-2 p-xl-offset-2">
              <div className="card card-w-title" style={cardStyle}>
                <div className="p-grid">
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                    style={{ textAlign: "center", paddingBottom: "2em" }}
                  >
                    <img src={logo} style={{ width: "190px" }} />
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3"
                    style={{
                      textAlign: "center",
                      display: this.state.severityDisplay,
                    }}
                  >
                    <Message
                      severity={this.state.severity}
                      text={this.state.msg}
                      style={{ fontSize: "13px" }}
                    />
                  </div>
                  <div className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-md-offset-3 p-lg-offset-3 p-xl-offset-3">
                    <InputText
                      id="username"
                      name="username"
                      type="text"
                      value={this.state.username}
                      onChange={this.handleChange}
                      placeholder="Username"
                      style={
                        (inputBoxStyle, { border: this.state.usernameError })
                      }
                    />
                  </div>
                  <div className="p-col-10 p-md-4 p-lg-4 p-xl-4 p-offset-1 p-md-offset-4 p-lg-offset-4 p-xl-offset-4">
                    <Button
                      type="submit"
                      label="Reset Password"
                      style={submitButtonStyle}
                    />
                  </div>
                  <div
                    className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-4 p-lg-offset-4 p-xl-offset-4"
                    style={{ textAlign: "center" }}
                  >
                    <Link to="/login" style={{ color: "rgb(84, 67, 163)" }}>
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loginAction: LoginAction,
};

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));
