module.exports = global.config = {
    main: {
        //baseUrl: 'http://35.154.55.54:8080/'
	    //baseUrl: 'http://52.152.236.182:8080/'
        // baseUrl: 'https://remittance.nsano.com:5000/preprod/'
	    baseUrl: 'https://streamp.instantcredit.services/',
        exportUrl: "https://streamp.instantcredit.services/api/report/"
    },
  //report service
  reportservice:{
    baseUrl:"https://streamp.instantcredit.services/api/report",
    toasterLife : 5000,
    windowTimeOut : "6000"
  },
  //Pagination params
  pagination: {
    default_size: 50
  },
	//USER TYPES OF INSTA CREDIT
	IC_ADMIN : '0',
	IC_ADMIN_USER : '1',
	IC_FINANCE_ADMIN : '101',
	IC_FINANCE_USER : '102',
	IC_HELPDESK_ADMIN : '201',
	IC_HELPDESK_USER : '202',
	IC_INST_ADMIN : '301',
	IC_INST_ADMIN_ADMIN : '302',
	IC_INST_USER : '303',
};